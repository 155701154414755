import { AgentRow } from "../../../api/agent.type";
import { useScheduleContext } from "../../schedule/ScheduleContext";
import ScheduleTable from "../../schedule/ScheduleTable";
import SubmitButton from "../../shared/buttons/SubmitButton";

function AgentScheduleContainer({
  activeAgent,
}: {
  activeAgent: AgentRow | undefined;
}) {
  const { enable, schedules } = useScheduleContext();

  return (
    <div>
      {!schedules || schedules.length === 0 ? (
        <div className="flex h-96 flex-col items-center justify-center gap-4">
          <p className="text-skin-muted">No schedules found.</p>
          <div>
            <SubmitButton onClick={() => enable(activeAgent?.id as string)}>
              {" "}
              Enable Agent to Schedule Messages{" "}
            </SubmitButton>
          </div>
        </div>
      ) : null}
      <ScheduleTable schedules={schedules} />
    </div>
  );
}

export default AgentScheduleContainer;
