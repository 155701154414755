import { UserInvite, UserRow } from "../api/user.type";
import { ApiEndpoints } from "./apiEndpoints";
import { fetchData, setHeaderOptions } from "./apiHelpers";
import { CompanyRow } from "./company.type";

export function handleSignupUser({
  userId,
}: {
  userId: string;
}): Promise<CompanyRow> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.user + "/signup";

  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      userId,
    }),
  };

  return fetchData<CompanyRow>(url, request);
}

export function getUserById({ id }: { id: string }): Promise<UserRow> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.user + "/" + id;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<UserRow>(url, request);
}

export function inviteNewUser(userInvite: UserInvite): Promise<UserRow> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.user + "/invite";

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify(userInvite),
  });

  return fetchData<UserRow>(url, request);
}
