import { useEffect, useRef } from "react";
import { AgentType, AgentTypes, mapTypeToLabel } from "../../api/agent.type";
import IconButton from "../shared/buttons/IconButton";
import { EllipsisVertical } from "../shared/icons/icons";

function ThreadListFilter({
  setSelectedAgentType,
  selectedAgentType,
  selectTypeOpen,
  setSelectTypeOpen,
}: {
  setSelectedAgentType: (type: AgentType) => void;
  selectedAgentType: AgentType | "all";
  selectTypeOpen: boolean;
  setSelectTypeOpen: (open: boolean) => void;
}) {
  const selectRef = useRef<HTMLSelectElement>(null);

  useEffect(() => {
    if (selectRef.current) {
      selectRef.current.focus();
      selectRef.current.size = AgentTypes.length + 1;
    }
  }, [selectTypeOpen]);

  return (
    <div>
      {selectTypeOpen && (
        <div className="absolute -right-44 z-10 w-48 rounded-md bg-skin-fill shadow-lg">
          <select
            ref={selectRef}
            value={selectedAgentType}
            onChange={(e) => {
              setSelectedAgentType(e.target.value as AgentType);
              setSelectTypeOpen(false);
            }}
            className="block w-full overflow-y-auto rounded-md border border-skin-muted bg-skin-fill px-4 py-2 text-sm text-skin-base/80 shadow-sm focus:border-none focus:outline-none"
          >
            <option className="text-sm" value="all">
              All Agents
            </option>
            {AgentTypes.map((type) => (
              <option className="text-sm" key={type.value} value={type.value}>
                {mapTypeToLabel(type.value)}
              </option>
            ))}
          </select>
        </div>
      )}
      <IconButton onClick={() => setSelectTypeOpen(!selectTypeOpen)}>
        <EllipsisVertical className="h-4 w-4" />
      </IconButton>
    </div>
  );
}

export default ThreadListFilter;
