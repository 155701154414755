/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToolRow } from "../../api/tool.type";
import TableWithSearch, {
  TableHeadItem,
} from "../shared/table/TableWithSearch";
import { handlePageNav } from "../shared/table/handlePagination";
import {
  handleFilterValue,
  setHeadFilters,
} from "../shared/table/setTableHeadWithFilters";
import ToolTableList from "./ToolTableList";

const TABLE_HEAD = [
  { label: "Icon" },
  { label: "Name" },
  { label: "Created at" },
  { label: "Type" },
  { label: "Edit" },
  { label: "Actions" },
];

function ToolTable({ tools }: { tools: ToolRow[] }) {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage] = useState(10);

  const [filteredTools, setFilteredTools] = useState<Partial<ToolRow>[]>();

  const [tableHead, setTableHead] = useState<TableHeadItem[]>(TABLE_HEAD);

  function handleToolClick(toolId: string) {
    navigate(`/tool/${toolId}`);
  }
  function baseFilter() {
    return (tool: Partial<ToolRow>, index: number) => index < 10;
  }

  const [toolFilterFunction, setToolFilterFunction] =
    useState<(tool: Partial<ToolRow>, index: number) => boolean>(baseFilter);

  useEffect(() => {
    setHeadFilters(
      tools,
      filteredTools,
      setFilteredTools,
      setTableHead,
      TABLE_HEAD,
    );
  }, [tools]);

  useEffect(() => {
    if (tools) {
      setFilteredTools(tools.filter(toolFilterFunction));
    }
  }, [tools]);

  const rowCount =
    filteredTools && filteredTools.length > 0 ? filteredTools.length : null;

  return (
    <div className="max-w-full gap-4">
      {filteredTools && filteredTools.length > 0 && (
        <TableWithSearch
          tableHead={tableHead}
          rowsPerPage={10}
          rowCount={rowCount}
          currentPage={currentPage}
          handleFilterValue={(value) =>
            handleFilterValue(
              tableHead,
              value,
              tools,
              setFilteredTools,
              setToolFilterFunction,
              baseFilter,
            )
          }
          handleNextPage={() =>
            handlePageNav({
              currentPage,
              pageSetFunction: setCurrentPage,
              rowsPerPage,
              setFilterFunction: setToolFilterFunction,
              advancePage: true,
            })
          }
          handlePreviousPage={() =>
            handlePageNav({
              currentPage,
              pageSetFunction: setCurrentPage,
              rowsPerPage,
              setFilterFunction: setToolFilterFunction,
              advancePage: false,
            })
          }
        >
          <ToolTableList
            tool={filteredTools}
            handleContentClick={handleToolClick}
            filterFunction={toolFilterFunction}
          />
        </TableWithSearch>
      )}
    </div>
  );
}

export default ToolTable;
