import { useSession } from "@supabase/auth-helpers-react";
import LoginSignupContainer, {
  AuthProps,
} from "../../components/auth/LoginContainer";

function Login({ viewType }: AuthProps) {
  const session = useSession();

  // Get current URL search parameters
  const currentParams = new URLSearchParams(window.location.search);

  if (session) {
    // Construct a new URL including all search parameters for the redirect
    const baseUrl = "/";
    const redirectUrl = currentParams.get("redirect") || baseUrl;
    const paramsString = currentParams.toString();
    const finalRedirectUrl = paramsString
      ? `${redirectUrl}?${paramsString}`
      : redirectUrl;

    window.location.href = finalRedirectUrl;
  }

  return (
    <div className="flex min-h-screen items-center justify-center bg-skin-fill">
      <div className="w-full max-w-md">
        <LoginSignupContainer viewType={viewType} />
      </div>
    </div>
  );
}

export default Login;
