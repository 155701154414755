import { ApiEndpoints } from "./apiEndpoints";
import { fetchData } from "./apiHelpers";
import { EvaluationRow } from "./evaluation.type";

export function enableEvalsForAgent(agentId: string): Promise<EvaluationRow> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.evaluation + "/" + agentId;

  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetchData<EvaluationRow>(url, request);
}

export function getEvaluations(agentId: string): Promise<EvaluationRow[]> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.evaluation + "/" + agentId;

  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetchData<EvaluationRow[]>(url, request);
}

export function runEvalsForThread(threadId: string): Promise<EvaluationRow> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.evaluation +
    "/thread/" +
    threadId;

  const request = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetchData<EvaluationRow>(url, request);
}
