/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AgentType } from "../../../api/agent.type";
import { useClientAgentContext } from "../../fabrk/hooks/ClientAgentContext";
import VisibilitySettingsDialog from "../../fabrk/layout/threadMenu/ShareAgentsForm";
import CloseButton from "../../menu/CloseButton";
import GenericDialog from "../../shared/dialog/GenericDialog";
import { useAgentContext } from "../hooks/AgentContext";
import { useLoadCompanyAgents } from "../hooks/useCompanyAgents";
import AgentSettingsHeaderButtons from "./AgentSettingsHeaderButtons";
import RunPersonaList from "./RunPersonaList";
import { LinkIcon } from "@heroicons/react/24/outline";
import { VisibilityContextWrapper } from "../hooks/VisibilityContext";

function AgentSettingsHeader({
  setOpen,
}: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const navigate = useNavigate();

  const { companyAgents } = useLoadCompanyAgents();

  const personaAgents = companyAgents?.filter(
    (agent) => agent.type === AgentType.personas,
  );

  const { activeAgent } = useAgentContext();

  const { newClientAgent } = useClientAgentContext();

  const [dialogOpen, setDialogOpen] = useState(false);

  const [dialogTitle, setDialogTitle] = useState("");

  useEffect(() => {
    if (newClientAgent) {
      navigate(`/a/${newClientAgent?.agent_id}/t/${newClientAgent.id}`);
    }
  }, [newClientAgent]);

  return (
    <div>
      <div className="pb-1 sm:pb-6">
        <div className="mt-6 px-4 sm:mt-8 sm:flex sm:items-end sm:px-6">
          <div className="sm:flex-1">
            <div className="flex flex-row items-center justify-between">
              <div className="flex items-center">
                <Link to={`/agent/${activeAgent?.id}`}>
                  <h3 className="mb-2 flex flex-row items-center gap-2 text-xl font-bold capitalize text-skin-base hover:underline sm:text-2xl">
                    {activeAgent?.name} <LinkIcon className="h-5 w-5" />
                  </h3>
                </Link>
              </div>
              <CloseButton setOpen={setOpen} />
            </div>

            <AgentSettingsHeaderButtons
              setDialogOpen={setDialogOpen}
              setDialogTitle={setDialogTitle}
              dialogOpen={dialogOpen}
              activeAgent={activeAgent}
            />
          </div>
        </div>
      </div>
      <GenericDialog
        title={dialogTitle}
        open={dialogOpen}
        setOpen={setDialogOpen}
      >
        {dialogTitle === "Visibility Settings" && (
          <VisibilityContextWrapper>
            <VisibilitySettingsDialog setDialogOpen={setDialogOpen} />
          </VisibilityContextWrapper>
        )}
        {dialogTitle === "Run Persona" && personaAgents && (
          <RunPersonaList
            personas={personaAgents}
            setDialogOpen={setDialogOpen}
          />
        )}
      </GenericDialog>
    </div>
  );
}

export default AgentSettingsHeader;
