/* eslint-disable react-hooks/exhaustive-deps */
import ChatLayout from "../../components/layout/ChatLayout";
import DefaultTitleForChat from "../../components/layout/DefaultTitleForChat";
import LayoutContainer from "../../components/layout/LayoutContainer";

function HomeContainer() {
  return (
    <LayoutContainer>
      <ChatLayout initialMenuType="settings" isOpen={true}>
        <DefaultTitleForChat />
      </ChatLayout>
    </LayoutContainer>
  );
}

export default HomeContainer;
