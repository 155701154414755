import { AgentRow, AgentType } from "../../../api/agent.type";
import { useClientAgentContext } from "../../fabrk/hooks/ClientAgentContext";
import { useMessageContext } from "../../fabrk/hooks/MessageContext";
import { useEventStreaming } from "../../message/StreamMessageContext";
import SubmitButton from "../../shared/buttons/SubmitButton";
import BaseChip from "../../shared/chip/BaseChip";
import { NumberInputBase } from "../../shared/forms/input/InputBase";
import { LabelBase } from "../../shared/typography/TypographyBase";
import { useAgentContext } from "../hooks/AgentContext";

function RunPersonaList({
  personas,
  setDialogOpen,
}: {
  personas: AgentRow[];
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { setCounter, handleCreateMessage, setPersona, messages } =
    useMessageContext();

  const { activeAgent } = useAgentContext();

  const { activeClientAgent } = useClientAgentContext();
  const { handleCreateNewAgentWithDefaults } = useAgentContext();

  const { startStreamingPersona, cycles, setCycles } = useEventStreaming();

  function handleStartCycle(persona: AgentRow) {
    setPersona(persona);
    setCounter(Number(cycles));

    if (messages && messages?.length > 0) {
      startStreamingPersona(
        activeClientAgent?.id as string,
        persona?.id as string,
      );
    } else {
      handleCreateMessage({
        content: `Hi my name is ${persona.name}.`,
        agentId: activeAgent?.id as string,
      });
    }

    setDialogOpen(false);
  }

  return (
    <div className="flex flex-col gap-4">
      {personas && personas.length > 0 && (
        <div>
          <LabelBase>Cycles: </LabelBase>
          <NumberInputBase value={cycles} setValue={setCycles} />
        </div>
      )}

      <div className="grid grid-cols-2 gap-4">
        {personas?.map((persona) => {
          return (
            <button key={persona.id} onClick={() => handleStartCycle(persona)}>
              <BaseChip>{persona.name}</BaseChip>
            </button>
          );
        })}
      </div>
      {(!personas || personas.length === 0) && (
        <div className="flex flex-col gap-4">
          <p>You don't have any Persona Agents</p>
          <SubmitButton
            onClick={() => {
              handleCreateNewAgentWithDefaults(AgentType.personas);
              setDialogOpen(false);
            }}
          >
            Create Persona Agent
          </SubmitButton>
        </div>
      )}
    </div>
  );
}

export default RunPersonaList;
