/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { AgentRow, mapTypeToLabel } from "../../api/agent.type";
import { useAgentContext } from "../agent/hooks/AgentContext";
import { useLoadCompanyAgents } from "../agent/hooks/useCompanyAgents";
import { useClientAgentContext } from "../fabrk/hooks/ClientAgentContext";
import { useClientContext } from "../fabrk/hooks/ClientContext";
import ThreadListFilter from "../layout/ThreadListFilter";
import AgentThreadList from "../layout/UserLeadCampaigns";

function ThreadList() {
  const { agentId } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const agentType = searchParams.get("agentType");

  const { client } = useClientContext();

  const {
    clientAgents,
    companyClientAgents,
    setSelectedAgentType,
    selectedAgentType,
  } = useClientAgentContext();

  const { companyAgents } = useLoadCompanyAgents();

  const [nonCompanyAgents, setNonCompanyAgents] = useState<AgentRow[]>([]);

  const [selectTypeOpen, setSelectTypeOpen] = useState(false);

  const { getAgent, activeAgent } = useAgentContext();

  useEffect(() => {
    if (agentId && client) {
      getAgent({
        id: agentId,
        clientId: client?.id,
      });
    }
  }, [agentId, companyAgents, client]);

  // This handles getting the clientAgents that are not part of the company and mapping them to an agent so they show in the thread list. These are clientAgents that were likely created by an action.
  useEffect(() => {
    if (
      companyAgents &&
      clientAgents &&
      clientAgents.length > 0 &&
      companyAgents.length > 0 &&
      clientAgents.length > companyAgents.length
    ) {
      const allNonDuplicateClientAgents = clientAgents?.filter(
        (clientAgent) => {
          return !companyClientAgents?.some((companyClientAgent) => {
            return companyClientAgent.id === clientAgent.id;
          });
        },
      );

      const clientAgentToAgentMap = allNonDuplicateClientAgents.map(
        (clientAgent) => {
          return {
            ...clientAgent.agent,
            client_agent: [clientAgent],
          };
        },
      ) as AgentRow[];
      setNonCompanyAgents(clientAgentToAgentMap);
    }
  }, [clientAgents, companyAgents]);

  useEffect(() => {
    // Update searchParams when selectedAgentType changes
    if (selectedAgentType === "all") {
      if (searchParams.has("agentType")) {
        searchParams.delete("agentType");
        setSearchParams(searchParams);
      }
    } else if (searchParams.get("agentType") !== selectedAgentType) {
      searchParams.set("agentType", selectedAgentType);
      setSearchParams(searchParams);
    }
  }, [selectedAgentType, searchParams, setSearchParams]);

  useEffect(() => {
    if (agentType && agentType !== selectedAgentType) {
      setSelectedAgentType(agentType as any);
    }
  }, [agentType, setSelectedAgentType]);

  // Filter the agents by the selected agent type
  const filterAgentsByType = (agents: AgentRow[] | undefined) => {
    if (selectedAgentType === "all") {
      return agents;
    }
    return agents?.filter((agent) => agent.type === selectedAgentType);
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex w-full flex-row justify-between">
        <p className="text-xs font-semibold leading-6 text-skin-base">Agents</p>
        <ThreadListFilter
          setSelectTypeOpen={setSelectTypeOpen}
          selectedAgentType={selectedAgentType}
          selectTypeOpen={selectTypeOpen}
          setSelectedAgentType={setSelectedAgentType}
        />
      </div>

      <div className="h-[320px] w-full overflow-y-auto overflow-x-hidden rounded-lg bg-skin-hover/50 p-2">
        <div>
          {selectedAgentType !== "all" && (
            <div className="mr-2 flex flex-row justify-between">
              <p className="text-xs font-semibold capitalize leading-6 text-skin-base">
                {mapTypeToLabel(selectedAgentType)}
              </p>
              <p className="text-xs capitalize leading-6 text-skin-base">
                Count: {filterAgentsByType(companyAgents)?.length}
              </p>
            </div>
          )}
        </div>
        <div>
          <ul className="-mx-2 mt-2 space-y-1">
            {companyAgents && (
              <AgentThreadList
                activeAgent={activeAgent}
                agents={filterAgentsByType(companyAgents)}
              />
            )}
            {nonCompanyAgents && nonCompanyAgents.length > 0 && (
              <AgentThreadList
                activeAgent={activeAgent}
                agents={filterAgentsByType(nonCompanyAgents)}
              />
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ThreadList;
