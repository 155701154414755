import { CopyToClipboardIconButton } from "../../shared/forms/CopyToClipboard";
import { useAgentContext } from "../hooks/AgentContext";

const baseUrl = process.env.REACT_APP_API_URL;

export default function ApiRoutesTable() {
  const { activeAgent } = useAgentContext();

  const routes = [
    {
      url: `${baseUrl}/agent/${activeAgent?.id}`,
      description: "Get agent details by id",
      method: "GET",
    },
    {
      url: `${baseUrl}/extractions?agentId=${activeAgent?.id}`,
      description: "Get extractions by agent id",
      method: "GET",
    },
    {
      url: `${baseUrl}/threads?agentId=${activeAgent?.id}`,
      description: "Get threads by agent id",
      method: "GET",
    },
  ];

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-skin-muted/40">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="w-1/2 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-skin-base/80 sm:pl-0"
                  >
                    URL
                  </th>
                  <th
                    scope="col"
                    className="w-1/4 px-3 py-3.5 text-left text-sm font-semibold text-skin-base/80"
                  >
                    Description
                  </th>
                  <th
                    scope="col"
                    className="w-1/4 px-3 py-3.5 text-left text-sm font-semibold text-skin-base/80"
                  >
                    Method
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-skin-muted/20">
                {routes.map((route) => {
                  return (
                    <tr key={route.url}>
                      <td className="flex w-1/2 flex-row items-center gap-2 whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-skin-base sm:pl-0">
                        {route.url}
                        <CopyToClipboardIconButton text={route.url} />
                      </td>
                      <td className="w-1/4 whitespace-nowrap px-3 py-4 text-sm text-skin-base/60">
                        {route.description}
                      </td>
                      <td className="w-1/4 whitespace-nowrap px-3 py-4 text-sm text-skin-accentSecondary">
                        {route.method}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
