/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { ToolRow } from "../../api/tool.type";
import { useAgentContext } from "../agent/hooks/AgentContext";
import { useEvalsToolContext } from "../agent/hooks/EvalsContext";
import TableWithSearch, {
  TableHeadItem,
} from "../shared/table/TableWithSearch";
import { handlePageNav } from "../shared/table/handlePagination";
import { handleFilterValue } from "../shared/table/setTableHeadWithFilters";
import EvaluationTableList from "./EvaluationTableList";

const baseTableHead = [
  { label: "Created At" },
  { label: "Context Version" },
  { label: "Thread Link" },
  // { label: "Actions" },
];

function EvaluationTable() {
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage] = useState(10);
  const { activeAgent } = useAgentContext();

  const { evaluations } = useEvalsToolContext();

  const tools = activeAgent?.agent_tool?.map((at) => at.tool);

  const [filteredTools, setFilteredTools] = useState<Partial<ToolRow>[]>();

  const [tableHead, setTableHead] = useState<TableHeadItem[]>();

  function baseFilter() {
    return (tool: Partial<ToolRow>, index: number) => index < 50;
  }

  const [toolFilterFunction, setToolFilterFunction] =
    useState<(tool: Partial<ToolRow>, index: number) => boolean>(baseFilter);

  useEffect(() => {
    if (evaluations && evaluations.length > 0) {
      const first = evaluations[0];
      const tableSchema = first.extraction_schema?.schema.map((s) => {
        return { label: s.id };
      });

      if (tableSchema) {
        setTableHead(baseTableHead.concat(tableSchema));
      } else {
        setTableHead(baseTableHead);
      }
    }
  }, [evaluations]);

  const rowCount =
    filteredTools && filteredTools.length > 0 ? filteredTools.length : null;

  return (
    <div className="max-w-full gap-4">
      {evaluations && evaluations.length > 0 && tableHead && (
        <TableWithSearch
          tableHead={tableHead}
          rowsPerPage={50}
          rowCount={rowCount}
          currentPage={currentPage}
          handleFilterValue={(value) =>
            handleFilterValue(
              tableHead,
              value,
              tools,
              setFilteredTools,
              setToolFilterFunction,
              baseFilter,
            )
          }
          handleNextPage={() =>
            handlePageNav({
              currentPage,
              pageSetFunction: setCurrentPage,
              rowsPerPage,
              setFilterFunction: setToolFilterFunction,
              advancePage: true,
            })
          }
          handlePreviousPage={() =>
            handlePageNav({
              currentPage,
              pageSetFunction: setCurrentPage,
              rowsPerPage,
              setFilterFunction: setToolFilterFunction,
              advancePage: false,
            })
          }
        >
          <EvaluationTableList
            evaluations={evaluations}
            filterFunction={toolFilterFunction}
            schema={evaluations[0].extraction_schema}
          />
        </TableWithSearch>
      )}
    </div>
  );
}

export default EvaluationTable;
