import { useMemo, useState } from "react";
import { yearMonthDayToMonthDayYear } from "../../utils/parseTimeStamp";
import { useCompanyContext } from "../fabrk/hooks/CompanyContext";
import IconButton from "../shared/buttons/IconButton";
import SubmitButton from "../shared/buttons/SubmitButton";
import { InputBase } from "../shared/forms/input/InputBase";
import { PencilIcon } from "../shared/icons/icons";
import {
  DataValueBase,
  HeadingBase,
  LabelBase,
} from "../shared/typography/TypographyBase";

function ProjectOverview() {
  const { company, updateCompany, companyConfig, updateConfig } =
    useCompanyContext();
  const [isEditing, setIsEditing] = useState(false);
  const [companyName, setCompanyName] = useState(company?.name || "");

  const [newOpenAiKey, setNewOpenAiKey] = useState(
    companyConfig?.openai_api_key_added ? "***************************" : "",
  );

  useMemo(() => {
    if (company) {
      setCompanyName(company.name);
    }
  }, [company]);

  useMemo(() => {
    if (companyConfig && companyConfig.openai_api_key_added) {
      setNewOpenAiKey("***************************");
    }
  }, [companyConfig]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Update company

    if (companyName !== company?.name) {
      updateCompany({ name: companyName, id: company?.id as string });
    }

    if (newOpenAiKey && !newOpenAiKey.includes("*")) {
      updateConfig({
        id: companyConfig?.id as string,
        values: { openai_api_key_added: true, openai_api_key: newOpenAiKey },
      });
    }

    setIsEditing(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex min-h-64 max-w-lg flex-col gap-8 rounded-lg border-2 p-2">
        <div className="flex flex-row justify-between border-b-2 py-2">
          <HeadingBase>Project Overview</HeadingBase>
          <IconButton
            onClick={(e?: React.MouseEvent<HTMLButtonElement>) => {
              e?.preventDefault();

              setIsEditing(!isEditing);
            }}
          >
            <PencilIcon className="h-4 w-4" />
          </IconButton>
        </div>

        <div className="flex flex-col gap-4">
          <div>
            <LabelBase>Date Created</LabelBase>
            <DataValueBase>
              {yearMonthDayToMonthDayYear(company?.created_at as string)}
            </DataValueBase>
          </div>
          <div>
            <LabelBase>Name</LabelBase>
            {isEditing ? (
              <InputBase value={companyName} setValue={setCompanyName} />
            ) : (
              <DataValueBase>{company?.name}</DataValueBase>
            )}
          </div>
          <div>
            <LabelBase>Project Slug</LabelBase>
            <DataValueBase>{company?.slug}</DataValueBase>
          </div>

          <div>
            <LabelBase>Open AI API key</LabelBase>
            {isEditing ? (
              <InputBase value={newOpenAiKey} setValue={setNewOpenAiKey} />
            ) : (
              <DataValueBase>
                {companyConfig?.openai_api_key_added
                  ? "***************************"
                  : "None"}
              </DataValueBase>
            )}
          </div>
        </div>
        {isEditing && (
          <div className="flex justify-end">
            <div>
              <SubmitButton
                type="submit"
                onClick={handleSubmit}
                disabled={
                  companyName === "" &&
                  companyName === company?.name &&
                  newOpenAiKey === ""
                }
              />
            </div>
          </div>
        )}
      </div>
    </form>
  );
}

export default ProjectOverview;
