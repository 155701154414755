import { useCredentialsContext } from "../hooks/CredentialsContext";
import CredentialsTable from "./CredentialsTable";

function AgentCredentialsContainer() {
  const { agentCredentials } = useCredentialsContext();

  return <CredentialsTable credentials={agentCredentials} />;
}

export default AgentCredentialsContainer;
