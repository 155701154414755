/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from "@material-tailwind/react";
import { useAgentContext } from "../agent/hooks/AgentContext";
import SubmitButton from "../shared/buttons/SubmitButton";

function AddEvaluationsForm({
  setDialogOpen,
}: {
  setDialogOpen: (open: boolean) => void;
}) {
  const { activeAgent } = useAgentContext();

  const handleSubmit = () => {
    console.log("Add auto evaluations for agent", activeAgent?.name);
    setDialogOpen(false);
  };

  return (
    <div className="flex flex-col gap-4 p-5">
      <Typography variant="paragraph">
        Add auto evaluations to the {activeAgent?.name} agent.
      </Typography>

      <Typography variant="paragraph">
        This will generate auto evaluations for the agent based on the context.
        You can then configure the evaluation questions and how often they are
        run against the output messages.
      </Typography>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <SubmitButton fullWidth={true} type="submit">
          Add auto evaluations
        </SubmitButton>
      </form>
    </div>
  );
}

export default AddEvaluationsForm;
