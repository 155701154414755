/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { Typography } from "@material-tailwind/react";
import { Fragment, useEffect, useState } from "react";

import { ToolRow } from "../../api/tool.type";
import { formatTimeStampToHumanReadableShortDateTime } from "../../utils/parseTimeStamp";

import { LinkIcon } from "@heroicons/react/24/outline";
import { EvaluationRow } from "../../api/evaluation.type";
import { ExtractionResponseRow } from "../../api/extractionResponse.type";
import { ExtractionSchemaRow } from "../../api/extractionSchema.type";
import { SpeedDialForChat } from "../shared/buttons/SpeedDialForChat";
import {
  TableListContainer,
  TableRowBase,
} from "../shared/table/TableComponents";
import { Link } from "react-router-dom";

function calculateAverageForNumberQuestions(rows: ExtractionResponseRow[]) {
  const numberQuestionData: Record<string, { total: number; count: number }> =
    {};

  if (!rows) {
    return {};
  }

  rows.forEach((row) => {
    if (row.question_type === "number") {
      const answer = parseFloat(row?.question_answer); // Convert the answer to a number
      if (!isNaN(answer)) {
        if (!numberQuestionData[row.question_id]) {
          numberQuestionData[row.question_id] = { total: 0, count: 0 };
        }
        numberQuestionData[row.question_id].total += answer;
        numberQuestionData[row.question_id].count += 1;
      }
    }
  });

  // Calculate the averages
  const averages: Record<string, number> = {};
  for (const question_id in numberQuestionData) {
    if (!numberQuestionData.hasOwnProperty(question_id)) {
      continue;
    }

    if (numberQuestionData[question_id].count === 0) {
      continue;
    }

    const data = numberQuestionData[question_id];
    averages[question_id] = data.total / data.count;
  }

  return averages;
}

function EvaluationTableList({
  evaluations,
  filterFunction,
  schema,
}: {
  evaluations: Partial<EvaluationRow>[] | undefined;
  schema?: ExtractionSchemaRow;
  filterFunction?: (tool: Partial<ToolRow>, index: number) => boolean;
}) {
  const [averages, setAverages] = useState<Record<string, number>>({});

  function mapToolToRow(evaluation: Partial<EvaluationRow>): {
    id: string;
    version: string;
    threadId: string;
    createdAt: string;
    responses: ExtractionResponseRow | undefined;
    agentId: string;
  } {
    const values = {
      id: evaluation.id as string,
      createdAt: formatTimeStampToHumanReadableShortDateTime(
        evaluation.created_at,
      ),
      version: evaluation.system_prompt?.version.toString() || "",
      threadId: evaluation.sms_message?.client_agent_id || "",
      responses: evaluation.extraction_responses,
      agentId: evaluation.agent_id || "",
    };

    return values;
  }

  useEffect(() => {
    if (evaluations && schema) {
      const rows = evaluations?.flatMap((e) => e?.extraction_responses || []);

      const averages = calculateAverageForNumberQuestions(rows);
      setAverages(averages);
    }
  }, [evaluations]);

  return (
    <Fragment>
      {averages && Object.keys(averages).length > 0 && (
        <tr>
          <TableRowBase>
            <Typography variant="small" className="font-normal text-skin-base">
              Average
            </Typography>
          </TableRowBase>
          <td className="p-4"></td>

          <td className="p-4"></td>
          {schema &&
            schema.schema.map((s) => {
              if (averages[s.id]) {
                return (
                  <td className="p-4">
                    <div className="flex flex-col">
                      <SpeedDialForChat
                        handler={<div>{averages[s.id].toFixed(0)} </div>}
                      >
                        <div className="max-w-md rounded-md bg-skin-hover p-2">
                          {s.question}
                        </div>
                      </SpeedDialForChat>
                    </div>
                  </td>
                );
              }

              return <td className="p-4">-</td>;
            })}
        </tr>
      )}
      {evaluations &&
        evaluations.length > 0 &&
        evaluations
          .filter((item, index) => filterFunction?.(item, index) ?? true)
          .map((item) => mapToolToRow(item))
          .map(
            (
              { id, version, threadId, createdAt, responses, agentId },
              index,
            ) => {
              const isLast = index === evaluations.length - 1;
              const classes = isLast
                ? "p-4"
                : "p-4 border-b border-skin-hover/50";

              return (
                <TableListContainer key={id}>
                  <TableRowBase>
                    <Typography
                      variant="small"
                      className="font-normal text-skin-base"
                    >
                      {createdAt}
                    </Typography>
                  </TableRowBase>
                  <TableRowBase>
                    <Typography
                      variant="small"
                      className="font-normal text-skin-base"
                    >
                      {version}
                    </Typography>
                  </TableRowBase>

                  <td className={classes}>
                    <Link to={`/a/${agentId}/t/${threadId}`}>
                      <LinkIcon className="h-4 w-4" />
                    </Link>
                  </td>
                  {/* <td className={classes}>
                    <Menu placement="top">
                      <MenuHandler>
                        <IconButton variant="text" className="text-skin-base">
                          <EllipsisVertical className="h-4 w-4" />
                        </IconButton>
                      </MenuHandler>
                      <MenuList>
                        <MenuItem
                          onClick={() => {
                            navigate(`/tool/${id}/demo`);
                          }}
                        >
                          Page
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            console.log("Duplicate");
                          }}
                        >
                          Duplicate
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            console.log("Pause/Activate");
                          }}
                        >
                          Pause
                        </MenuItem>
                        <MenuItem disabled={true}>Delete</MenuItem>
                      </MenuList>
                    </Menu>
                  </td> */}
                  {schema &&
                    schema.schema.map((s) => {
                      if (responses?.question_id == s.id) {
                        return (
                          <td className={classes}>
                            <SpeedDialForChat
                              handler={<div> {responses.question_answer}</div>}
                            >
                              <div className="max-w-md rounded-md bg-skin-hover p-2">
                                {responses.question_name}
                              </div>
                            </SpeedDialForChat>
                          </td>
                        );
                      }

                      return <td className={classes}>-</td>;
                    })}
                </TableListContainer>
              );
            },
          )}
    </Fragment>
  );
}

export default EvaluationTableList;
