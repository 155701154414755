import { useState } from "react";
import { useCompanyContext } from "../fabrk/hooks/CompanyContext";
import SubmitButton from "../shared/buttons/SubmitButton";
import GenericDialog from "../shared/dialog/GenericDialog";
import { LabelBase } from "../shared/typography/TypographyBase";
import { CurrencyInputBase } from "../shared/forms/input/InputBase";
import { useNavigate } from "react-router-dom";

function BalanceAmountCard() {
  const navigate = useNavigate();
  const { balance } = useCompanyContext();

  const [actionMenuOpen, setActionMenuOpen] = useState(false);

  const [fundingAmount, setFundingAmount] = useState("25");

  return (
    <div className="flex max-w-xs flex-col gap-y-4 rounded-lg border-2 border-skin-hover bg-skin-fill p-8 shadow">
      <dt className="text-base leading-7 text-skin-base/80">
        Fabrk AI Balance
      </dt>
      <dd className="order-first text-2xl font-semibold tracking-tight text-green-700 sm:text-3xl">
        ${balance}
      </dd>
      <SubmitButton onClick={() => setActionMenuOpen(!actionMenuOpen)}>
        Add Funds
      </SubmitButton>
      <GenericDialog
        open={actionMenuOpen}
        setOpen={setActionMenuOpen}
        title="Add funds"
      >
        <div className="flex flex-col gap-4">
          <div>
            Add funds to your balance. There is a 5% fee for adding funds.
          </div>
          <div>
            Enter the amount you would like to add to your balance between $20
            and $1000.
          </div>
          <div>
            <LabelBase>Funding amount</LabelBase>
            <CurrencyInputBase
              value={fundingAmount}
              setValue={setFundingAmount}
            />
          </div>
          <SubmitButton
            onClick={() => {
              navigate(`/checkout?amount=${fundingAmount}`);
            }}
          >
            Add Funds
          </SubmitButton>
        </div>
      </GenericDialog>
    </div>
  );
}

export default BalanceAmountCard;
