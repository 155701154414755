/* eslint-disable eqeqeq */
import { Fragment } from "react";
import { ScheduleRow } from "../../api/schedule.type";
import { useScheduleContext } from "./ScheduleContext";
import { ScheduleEditRow } from "./ScheduleEditRow";
import { ScheduleViewRow } from "./ScheduleViewRow";

function ScheduleList({
  schedules,
  filterFunction,
}: {
  schedules: ScheduleRow[] | undefined;
  filterFunction?: (tool: Partial<ScheduleRow>, index: number) => boolean;
}) {
  const { fields, isEdit } = useScheduleContext();

  return (
    <Fragment>
      {!isEdit &&
        schedules &&
        schedules
          .filter((er, index) => {
            if (filterFunction) {
              return filterFunction(er, index);
            } else {
              return true;
            }
          })
          .map((item, index) => {
            return (
              <ScheduleViewRow key={index} schedule={item} index={index} />
            );
          })}

      {isEdit &&
        fields.map((item, index) => {
          return <ScheduleEditRow key={index} schedule={item} index={index} />;
        })}
    </Fragment>
  );
}

export default ScheduleList;
