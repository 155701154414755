/* eslint-disable react-hooks/exhaustive-deps */
import { useSession } from "@supabase/auth-helpers-react";
import { createContext, useContext, useEffect } from "react";
import { UseMutateFunction, useMutation, useQueryClient } from "react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  checkSlugUniqueness,
  createNewAgent,
  forkAgent,
  getAgentById,
  updateAgentApiCall,
} from "../../../api/agent.api";
import {
  AgentInsert,
  AgentRow,
  AgentType,
  AgentUpdate,
  SlugSuggestions,
} from "../../../api/agent.type";
import { ClientAgentRow } from "../../../api/clientAgent.type";
import { updateSystemPrompt } from "../../../api/prompt.api";
import { PromptRow, PromptUpdate } from "../../../api/prompt.type";

import { useAuthContext } from "../../fabrk/hooks/AuthContext";
import { useClientContext } from "../../fabrk/hooks/ClientContext";
import { useAlertContext } from "../../shared/alert/alertContext";

export const AgentContextWrapper = (props: any) => {
  const { setAlertProps, setGlobalLoading } = useAlertContext();

  const navigate = useNavigate();

  const { companyId } = useAuthContext();

  const queryClient = useQueryClient();

  const session = useSession();

  const { agentId } = useParams();

  const [searchParams] = useSearchParams();

  const forkAgentId = searchParams.get("fork_agent_id");

  const { client } = useClientContext();

  const {
    mutate: getAgent,
    data: activeAgent,
    isLoading,
  } = useMutation(getAgentById);

  const { mutate: updateAgent, isLoading: updateAgentLoading } = useMutation(
    updateAgentApiCall,
    {
      onSuccess: async (res) => {
        getAgent({
          id: res.id,
        });

        setAlertProps({
          message: "Agent updated",
          color: "green",
        });
      },
      onError(error: Error) {
        setAlertProps({
          message: error.message,
          color: "red",
        });
      },
    },
  );

  const { mutate: checkSlug, data: slugSuggestions } =
    useMutation(checkSlugUniqueness);

  const { mutate: updatePrompt } = useMutation(updateSystemPrompt, {
    onSuccess: async (res) => {
      queryClient.invalidateQueries("clientAgents");
      getAgent({
        id: agentId as string,
      });

      setAlertProps({
        message: "Instructions updated",
        color: "green",
      });
    },
    onError(error: Error) {
      setAlertProps({
        message: error.message,
        color: "red",
      });
    },
  });

  const { mutate: createAgent, isLoading: createAgentLoading } = useMutation(
    createNewAgent,
    {
      onSuccess: async (res) => {
        queryClient.invalidateQueries("clientAgents");
        queryClient.invalidateQueries("companyAgents");

        navigate(`/a/${res.agent_id}/t/${res.id}`);
      },
      onError(error: Error) {
        setAlertProps({
          message: error.message,
          color: "red",
        });
      },
    },
  );

  const { mutate: fork, isLoading: forkLoading } = useMutation(forkAgent, {
    onSuccess: async (res) => {
      setAlertProps({
        message: "Agent forked",
        color: "green",
      });
      queryClient.invalidateQueries("clientAgents");
      navigate(`/a/${res.id}`);
    },
    onError(error: Error) {
      setAlertProps({
        message: error.message,
        color: "red",
      });
    },
  });

  function handleFork() {
    fork({
      agentId: activeAgent?.id as string,
      companyId,
      clientId: client?.id as string,
    });
  }

  function handleCreateNewAgentWithDefaults(type?: AgentType) {
    createAgent({
      company_id: companyId,
      client_id: client?.id,
      ...(type && { type }),
    });
  }

  // This checks if the active agent belongs to the company/user. If it doesn't the UI will not allow for agent updates/etc.
  const isCompanyAgent = activeAgent?.company_id === companyId;

  // If there is a session (user is logged in), you don't need to pass the clientId.
  useEffect(() => {
    if (agentId) {
      if (session) {
        getAgent({
          id: agentId,
        });
      }
    }
  }, [agentId, session, client]);

  useEffect(() => {
    if (forkAgentId && companyId) {
      fork({
        agentId: forkAgentId,
        companyId,
        clientId: client?.id as string,
      });
    }
  }, [session, forkAgentId, client, companyId]);

  useEffect(() => {
    setGlobalLoading(
      isLoading || forkLoading || updateAgentLoading || createAgentLoading,
    );
  }, [isLoading, forkLoading, updateAgentLoading, createAgentLoading]);

  const value = {
    updateAgent,
    getAgent,
    activeAgent,
    loading: isLoading || forkLoading || updateAgentLoading,
    updatePrompt,
    handleCreateNewAgentWithDefaults,
    createAgent,
    isCompanyAgent,
    fork,
    handleFork,
    checkSlug,
    slugSuggestions,
  };

  return (
    <AgentContext.Provider value={value}>
      {props.children}
    </AgentContext.Provider>
  );
};

export const AgentContext = createContext({
  updatePrompt: {} as UseMutateFunction<
    PromptRow,
    Error,
    Partial<PromptUpdate>,
    unknown
  >,
  updateAgent: {} as UseMutateFunction<
    AgentRow,
    Error,
    Partial<AgentUpdate>,
    unknown
  >,
  getAgent: {} as UseMutateFunction<
    AgentRow,
    unknown,
    {
      id: string;
      clientId?: string;
    },
    unknown
  >,
  activeAgent: {} as AgentRow | undefined,
  handleCreateNewAgentWithDefaults: (type?: AgentType) => {},
  loading: false,
  createAgent: {} as UseMutateFunction<
    ClientAgentRow,
    Error,
    Partial<AgentInsert> | null,
    unknown
  >,
  isCompanyAgent: false,
  fork: {} as UseMutateFunction<
    AgentRow,
    Error,
    {
      agentId: string;
      companyId: string;
      clientId: string;
    },
    unknown
  >,
  handleFork: () => {},
  checkSlug: {} as UseMutateFunction<SlugSuggestions, unknown, string, unknown>,
  slugSuggestions: {} as SlugSuggestions | undefined,
});

export const useAgentContext = () => useContext(AgentContext);
