import { AgentToolRow } from "./agentTool.type";
import { ApiEndpoints } from "./apiEndpoints";
import { fetchData } from "./apiHelpers";

export function getCampaignToolByCampaign(
  agentId: string,
): Promise<AgentToolRow[]> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.agentTool +
    "/fabrk/agent/" +
    agentId;

  const request = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetchData<AgentToolRow[]>(url, request);
}

export function copyAndAddAgentTool({
  agentId,
  toolId,
}: {
  agentId: string;
  toolId: string;
}): Promise<AgentToolRow[]> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.agentTool +
    "/copy/agent/" +
    agentId +
    "/tool/" +
    toolId;

  const request = { method: "PUT" };

  return fetchData<AgentToolRow[]>(url, request);
}

export function addAgentTool({
  agentId,
  toolId,
}: {
  agentId: string;
  toolId: string;
}): Promise<AgentToolRow[]> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.agentTool +
    "/agent/" +
    agentId +
    "/tool/" +
    toolId;

  const request = { method: "PUT" };

  return fetchData<AgentToolRow[]>(url, request);
}

export function deleteAiCampaignFunction({
  agentId,
  toolId,
}: {
  agentId: string;
  toolId: string;
}): Promise<AgentToolRow[]> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.agentTool +
    "/agent/" +
    agentId +
    "/tool/" +
    toolId;

  const request = { method: "DELETE" };

  return fetchData<AgentToolRow[]>(url, request);
}
