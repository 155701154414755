import { Return } from "../../components/checkout/StripeCheckout";
import LayoutContainer from "../../components/layout/LayoutContainer";

function CheckoutReturnContainer() {
  return (
    <LayoutContainer>
      <div className="mx-auto mt-40 flex h-screen w-full max-w-md flex-col items-center p-8">
        <Return />
      </div>
    </LayoutContainer>
  );
}

export default CheckoutReturnContainer;
