/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect } from "react";
import { useMutation } from "react-query";
import { getExtractionResponsesByAgent } from "../../../api/extractionResponse.api";
import { useAgentContext } from "../../agent/hooks/AgentContext";
import { ExtractionResponseRow } from "../../../api/extractionResponse.type";

export const ExtractionResponsesContextWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { activeAgent } = useAgentContext();

  const { mutate: getResponsesByAgent, data: extractionResponses } =
    useMutation(getExtractionResponsesByAgent);

  useEffect(() => {
    if (activeAgent) {
      getResponsesByAgent(activeAgent.id);
    }
  }, [activeAgent]);

  const value = { extractionResponses };

  return (
    <ExtractionResponsesContext.Provider value={value}>
      {children}
    </ExtractionResponsesContext.Provider>
  );
};

export const ExtractionResponsesContext = createContext({
  extractionResponses: [] as ExtractionResponseRow[] | undefined,
});

export const useExtractionResponsesContext = () =>
  useContext(ExtractionResponsesContext);
