import {
  ForkedToolResponse,
  PublicToolRow,
  ToolRow,
  ToolUpdate,
} from "./tool.type";
import { ApiEndpoints } from "./apiEndpoints";
import { fetchData } from "./apiHelpers";

export function getPublicTools(): Promise<PublicToolRow[]> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.tool;

  const request = { method: "GET" };

  return fetchData<PublicToolRow[]>(url, request);
}

export function getCreateToolsList(): Promise<ToolRow[]> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.tool + "/create-list";

  const request = { method: "GET" };

  return fetchData<ToolRow[]>(url, request);
}

export function getToolById(id: string): Promise<ToolRow> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.tool + `/${id}`;

  const request = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetchData<ToolRow>(url, request);
}

export function updateToolById(tool: ToolUpdate): Promise<ToolRow> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.tool + `/${tool.id}`;

  const request = {
    method: "PUT",
    body: JSON.stringify(tool),
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetchData<ToolRow>(url, request);
}

export function forkToolById({
  toolId,
  companyId,
}: {
  toolId: string;
  companyId: string;
}): Promise<ForkedToolResponse> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.tool +
    `/fork/${toolId}/${companyId}`;

  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetchData<ForkedToolResponse>(url, request);
}
