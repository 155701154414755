import { AssistantStreamEvent } from "openai/resources/beta/assistants";

type ArgumentsType = Record<string, any> | null;

export function requiredActionParser(
  data: AssistantStreamEvent,
): string | undefined {
  if (data.event === "thread.run.requires_action") {
    const toolCall =
      data.data.required_action?.submit_tool_outputs?.tool_calls?.[0];

    if (!toolCall || !toolCall.function) {
      console.warn("Tool call or function details are missing.");
      return;
    }

    const args: ArgumentsType = toolCall.function.arguments
      ? JSON.parse(toolCall.function.arguments)
      : null;

    // Recursively format arguments into a string
    const formatArgs = (obj: any): string => {
      if (!obj) {
        return "";
      }
      return Object.entries(obj)
        .map(([key, value]) =>
          typeof value === "object" && value !== null
            ? `${key}: { ${formatArgs(value)} }`
            : `${key}: ${value}`,
        )
        .join(", ");
    };

    let argsMap = args && formatArgs(args);

    if (data.data.required_action?.submit_tool_outputs) {
      const value = `Calling tool ${toolCall.function.name} ${
        argsMap ? `with arguments ${argsMap}` : ""
      } ...`;

      return value.substring(0, 350);
    }
  }
}
