import { useState } from "react";
import Markdown from "react-markdown";
import rehypeHighlight from "rehype-highlight";
import remarkGfm from "remark-gfm";

const ShowMoreParagraph = ({
  text,
  maxLength = 150,
}: {
  text: string;
  maxLength?: number;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  // If the text is longer than maxLength and is not expanded, truncate it
  const truncatedText =
    text?.length > maxLength && !isExpanded
      ? `${text.slice(0, maxLength)}...`
      : text;

  return (
    <div className="max-w-xl">
      <Markdown rehypePlugins={[rehypeHighlight, remarkGfm]}>
        {truncatedText}
      </Markdown>
      {text?.length > maxLength && (
        <button
          onClick={toggleText}
          className="mt-2 text-skin-accentSecondary underline"
        >
          {isExpanded ? "Show less" : "Show more"}
        </button>
      )}
    </div>
  );
};

export default ShowMoreParagraph;
