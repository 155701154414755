/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect } from "react";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { getClientById } from "../../../api/client.api";
import { ClientRow } from "../../../api/client.type";

export const ViewClientContextWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { clientId } = useParams();

  const { mutate: getClients, data: activeClient } = useMutation(getClientById);

  useEffect(() => {
    if (clientId) {
      getClients(clientId);
    }
  }, [clientId]);

  const value = { activeClient };

  return (
    <ViewClientContext.Provider value={value}>
      {children}
    </ViewClientContext.Provider>
  );
};

export const ViewClientContext = createContext({
  activeClient: {} as ClientRow | undefined,
});

export const useViewClientContext = () => useContext(ViewClientContext);
