import { ApiEndpoints } from "./apiEndpoints";
import { fetchData, setFileUploadOptions } from "./apiHelpers";
import { CreateMessage, MessageRow, SendFabrkRequest } from "./message.type";
import { VoiceUploadResponse } from "./storage.type";

export function getSmsMessagesByClientAgent({
  clientAgentId,
}: {
  clientAgentId: string;
}): Promise<MessageRow[]> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.message +
    "/clientAgent/" +
    clientAgentId;

  const request = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetchData(url, request);
}

export function getMessageById(messageId: string): Promise<MessageRow> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.message + "/" + messageId;

  const request = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetchData(url, request);
}

export function createFabrkMessage(values: CreateMessage): Promise<any> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.message + "/app/create";

  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    url,
    body: JSON.stringify({ ...values }),
  };

  return fetchData(request.url, request);
}

export function sendFabrkSmsOrEmail(values: SendFabrkRequest): Promise<any> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.message + "/fabrk/send";

  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    url,
    body: JSON.stringify({ ...values }),
  };

  return fetch(request.url, request);
}

export function audioFileToMessage({
  fileName,
  audioBlob,
  additionalData,
}: {
  fileName: string;
  audioBlob: Blob;
  additionalData?: any;
}) {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.message + "/audio";

  const formData = new FormData();
  formData.append("file", audioBlob, fileName);

  if (additionalData) {
    for (const key in additionalData) {
      formData.append(key, additionalData[key]);
    }
  }
  const request = setFileUploadOptions({
    method: "POST",
    body: formData,
  });

  return fetchData<VoiceUploadResponse>(url, request);
}
