/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useState } from "react";
import { UseMutateFunction, useMutation, useQueryClient } from "react-query";

import { updateLeadById } from "../../../api/client.api";
import {
  ClientInsert,
  ClientRow,
  ClientWithRelations,
} from "../../../api/client.type";
import { useAlertContext } from "../../shared/alert/alertContext";

export const ClientContextWrapper = (props: any) => {
  const { setAlertProps } = useAlertContext();
  const queryClient = useQueryClient();

  const [client, setClient] = useState<ClientRow>();

  const { mutate: update } = useMutation(updateLeadById, {
    onSuccess: async (res) => {
      setClient(res);
      setAlertProps({
        message: "Client updated successfully",
        color: "green",
      });
      queryClient.invalidateQueries("clientAgents");
    },
    mutationKey: "updateLead",
  });

  const value = { client, update };

  return (
    <ClientContext.Provider value={value}>
      {props.children}
    </ClientContext.Provider>
  );
};

export const ClientContext = createContext({
  client: {} as ClientRow | undefined,
  update: {} as UseMutateFunction<
    ClientWithRelations,
    unknown,
    {
      id: string;
      values: ClientInsert;
    },
    unknown
  >,
});

export const useClientContext = () => useContext(ClientContext);
