import { Button } from "@material-tailwind/react";
import { useForm } from "react-hook-form";
import { useAuthContext } from "../fabrk/hooks/AuthContext";
import { ErrorHelperText } from "../shared/forms/ErrorHelperText";
import { FabrkInput } from "../shared/forms/input/InputBase";

export interface AuthProps {
  viewType: "Log In" | "Sign Up" | "Reset Password";
}

function LoginSignupModal({ viewType }: AuthProps) {
  const { signIn, loginError, signUp } = useAuthContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  function onSubmit(data: any) {
    if (viewType === "Log In") {
      signIn(data);
      return;
    } else {
      signUp(data);
    }
  }

  return (
    <div className="mt-5 h-fit rounded-lg bg-skin-fill">
      {/* {viewType !== "Reset Password" && (
        <div className="mx-auto">
          <GoogleSignIn viewType={viewType} />
        </div>
      )} */}
      {/* <Divider className="p-2" /> */}
      {loginError && (
        <div className="mb-5">
          <ErrorHelperText text={loginError} />
        </div>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-5">
          <FabrkInput
            register={register}
            errors={errors}
            label="Email"
            name="email"
          />

          <FabrkInput
            register={register}
            errors={errors}
            label="Password"
            type="password"
            name="password"
          />

          {viewType === "Sign Up" && (
            <FabrkInput
              register={register}
              errors={errors}
              label="Confirm Password"
              type="password"
              name="repeatPassword"
            />
          )}

          <Button className="bg-skin-accent" type="submit">
            {viewType}
          </Button>

          {/* <Typography variant="small" color="gray">
            Forgot password?{" "}
            <span
              style={{
                color: "orange",
                cursor: "pointer",
              }}
              onClick={() => navigate("/send-password-reset-email")}
            >
              Reset
            </span>
          </Typography> */}

          {/* {viewType === "Sign Up" ? (
            <LoginFooterLink
              setSignup={() => {
                console.log("setSignup");
                setDialogTitle("Sign Up");
              }}
            />
          ) : (
            <SignupFooterLink
              setLogin={() => {
                console.log("setLogin");
                setDialogTitle("Log In");
              }}
            />
          )} */}
        </div>
      </form>
    </div>
  );
}

export default LoginSignupModal;
