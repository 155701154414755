import SecondaryButton from "../buttons/SecondaryButton";
import SubmitButton from "../buttons/SubmitButton";
import AddButton from "../forms/AddButton";

function AddSaveButtons({
  addButtonLabel,
  handleAppend,
  isEdit,
  handleReset,
  handleSave,
}: {
  addButtonLabel: string;
  handleAppend: () => void;
  isEdit: boolean;
  handleReset: () => void;
  handleSave: () => void;
}) {
  return (
    <div className="flex flex-col gap-2 p-2">
      <div>
        <AddButton iconClass="h-4 w-4" onClick={handleAppend}>
          {addButtonLabel}
        </AddButton>
      </div>
      <div>
        {isEdit && (
          <div className="flex flex-row gap-2">
            <div className="flex min-w-20">
              <SecondaryButton onClick={handleReset}>Cancel</SecondaryButton>
            </div>
            <div className="flex min-w-20">
              <SubmitButton type="submit" onClick={handleSave}>
                Save
              </SubmitButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AddSaveButtons;
