import { ApiEndpoints } from "./apiEndpoints";
import { fetchData, setHeaderOptions } from "./apiHelpers";
import { ClientAgentRow } from "./clientAgent.type";

export function getClientAgentsByClientId(
  clientId: string,
): Promise<ClientAgentRow[]> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.clientAgent +
    "/client/" +
    clientId;

  const request = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetchData<ClientAgentRow[]>(url, request);
}

export function getClientAgentById(
  clientAgentId: string,
): Promise<ClientAgentRow> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.clientAgent +
    "/thread/" +
    clientAgentId;

  const request = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetchData<ClientAgentRow>(url, request);
}

export function getClientAgentsByCompany(companyId: string) {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.clientAgent +
    "/company/" +
    companyId;

  const request = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetchData<ClientAgentRow[]>(url, request);
}

export function getClientAgentsByAgent(agentId: string) {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.clientAgent +
    "/agent/" +
    agentId;

  const request = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetchData<ClientAgentRow[]>(url, request);
}

export function createClientAgentWithExistingLead({
  agentId,
  clientId,
}: {
  agentId: string;
  clientId: string;
}): Promise<ClientAgentRow> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.clientAgent + "/add-client";

  const request = {
    method: "POST",
    body: JSON.stringify({ agentId, clientId }),
    headers: { "Content-Type": "application/json" },
  };

  return fetchData<ClientAgentRow>(url, request);
}

export function createClientAgentWithNewLead({
  agentId,
}: {
  agentId: string;
}): Promise<ClientAgentRow> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.clientAgent + "/create";

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify({ agentId }),
  });

  return fetchData<ClientAgentRow>(url, request);
}
