/* eslint-disable react-hooks/exhaustive-deps */
import { UsersIcon } from "@heroicons/react/24/outline";
import { Card, Checkbox, Tooltip } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { VisibilityRow } from "../../../../api/visibility.type";
import { FormDivider } from "../../../shared/dividers/FormDivider";
import { useVisibilityContext } from "../../hooks/VisibilityContext";

export function ProjectVisibility({ values }: { values?: VisibilityRow }) {
  const { setUpdatedValues } = useVisibilityContext();
  const [allChecked, setAllChecked] = useState(false);

  const [checkedValue, setCheckedValue] = useState({
    view: false,
    fork: false,
    admin: false,
  });

  useEffect(() => {
    if (values) {
      setCheckedValue({
        view: values.project_view,
        fork: values.project_fork,
        admin: values.project_admin,
      });
    }
  }, [values?.project_view, values?.project_fork, values?.project_admin]);

  useEffect(() => {
    setUpdatedValues({
      project_admin: checkedValue.admin,
      project_fork: checkedValue.fork,
      project_view: checkedValue.view,
    });
  }, [checkedValue]);

  return (
    <Card className="max-w-md border-2 border-skin-hover bg-skin-fill p-2">
      <div className="flex flex-col gap-2">
        <div className="ml-3 flex flex-row gap-2 text-skin-base">
          <UsersIcon className="h-6 w-6 text-skin-base" />
          <p>Team</p>
        </div>
        <FormDivider />

        <Checkbox
          label="Select all"
          color="orange"
          crossOrigin={"true"}
          labelProps={{ className: "text-skin-base" }}
          onChange={(e) => {
            setAllChecked(e.target.checked);

            if (e.target.checked) {
              setCheckedValue({
                view: true,
                fork: true,
                admin: true,
              });
            } else {
              setCheckedValue({
                view: false,
                fork: false,
                admin: false,
              });
            }
          }}
          checked={allChecked}
          value={allChecked ? "true" : "false"}
        />
        <div>
          <Tooltip
            placement="bottom"
            content="Others in the project will be able to view this agent."
            className="bg-skin-hover text-skin-base"
          >
            <Checkbox
              label="View"
              color="green"
              crossOrigin={"true"}
              labelProps={{ className: "text-skin-base" }}
              onChange={(e) =>
                setCheckedValue({ ...checkedValue, view: e.target.checked })
              }
              checked={checkedValue.view}
              value={checkedValue.view ? "true" : "false"}
            />
          </Tooltip>
          <Tooltip
            placement="bottom"
            content="Others in the project will be able to fork this agent."
            className="bg-skin-hover text-skin-base"
          >
            <Checkbox
              label="Fork"
              color="green"
              crossOrigin={"true"}
              labelProps={{ className: "text-skin-base" }}
              onChange={(e) =>
                setCheckedValue({ ...checkedValue, fork: e.target.checked })
              }
              checked={checkedValue.fork}
              value={checkedValue.fork ? "true" : "false"}
            />
          </Tooltip>
          <Tooltip
            placement="bottom"
            content="Others in the project can make edits to this agent."
            className="bg-skin-hover text-skin-base"
          >
            <Checkbox
              label="Admin"
              color="green"
              crossOrigin={"true"}
              labelProps={{ className: "text-skin-base" }}
              onChange={(e) =>
                setCheckedValue({ ...checkedValue, admin: e.target.checked })
              }
              checked={checkedValue.admin}
              value={checkedValue.admin ? "true" : "false"}
            />
          </Tooltip>
        </div>
      </div>
    </Card>
  );
}
