import { ApiEndpoints } from "./apiEndpoints";
import { fetchData, setHeaderOptions } from "./apiHelpers";
import { UpdateVisibilityRow, VisibilityRow } from "./visibility.type";

export function updateVisibilityById(
  visibility: Partial<UpdateVisibilityRow>,
): Promise<VisibilityRow> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.visibility +
    "/" +
    visibility.id;

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify(visibility),
  });

  return fetchData<VisibilityRow>(url, request);
}

export function getVisibilityByAgent(agentId: string): Promise<VisibilityRow> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.visibility +
    "/agent/" +
    agentId;

  const request = setHeaderOptions({
    method: "GET",
  });
  return fetchData<VisibilityRow>(url, request);
}
