import { EnvelopeIcon, GlobeAltIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { formatPhoneNumberToDbFormat } from "../../../utils/parsePhoneNumbers";
import { useClientContext } from "../../fabrk/hooks/ClientContext";
import { useMessageContext } from "../../fabrk/hooks/MessageContext";
import FabrkMenuDivider from "../../shared/dividers/FabrkMenuDivider";
import EmbedLinks from "../../layout/EmbedLinks";
import IconButton from "../../shared/buttons/IconButton";
import SubmitButton from "../../shared/buttons/SubmitButton";
import { CopyToClipboardIconButton } from "../../shared/forms/CopyToClipboard";
import PhoneNumberInput from "../../shared/forms/PhoneInput";
import { InputBaseWithIcon } from "../../shared/forms/input/InputBase";
import { MailIcon, PhoneIcon } from "../../shared/icons/icons";
import { HeadingBase } from "../../shared/typography/TypographyBase";
import { useAgentContext } from "../hooks/AgentContext";

function AgentMediumButtons() {
  const [value, setValue] = useState("");
  const [active, setActive] = useState("browser");
  const { sendMessage } = useMessageContext();
  const { activeAgent } = useAgentContext();
  const { client } = useClientContext();

  function handleClick(type: string) {
    setActive(type);
  }

  function handleSubmit() {
    if (active === "phone") {
      sendMessage({
        phone: formatPhoneNumberToDbFormat(value),
        agentId: activeAgent?.id as string,
        clientId: client?.id as string,
      });
    } else {
      sendMessage({
        email: value,
        agentId: activeAgent?.id as string,
        clientId: client?.id as string,
      });
    }
    setValue("");
  }

  useEffect(() => {
    setValue("");
  }, [active]);

  const browserInstructions =
    "You can make this agent publicly available or embedded in a website by setting the visibility to public.";
  const phoneInstructions =
    "You can interact with this agent via phone. Enter your phone number to get receive a text message from this agent to start the conversation.";
  const mailInstructions =
    "You can interact with this agent via email. Enter your email address to get receive a message from this agent to start the conversation.";

  return (
    <div className="flex w-full flex-col">
      <div className="flex flex-row justify-between">
        <HeadingBase>Deploy</HeadingBase>
      </div>
      <FabrkMenuDivider />
      <div className="flex flex-col gap-4">
        <div className="mt-4 flex gap-4">
          <IconButton
            active={active === "browser"}
            onClick={() => handleClick("browser")}
          >
            <GlobeAltIcon className="h-6 w-6" />
          </IconButton>
          <IconButton
            active={active === "phone"}
            onClick={() => handleClick("phone")}
          >
            <PhoneIcon className="h-6 w-6" />
          </IconButton>
          <IconButton
            active={active === "email"}
            onClick={() => handleClick("email")}
          >
            <MailIcon className="h-6 w-6" />
          </IconButton>
        </div>
        <div className="flex flex-col gap-4">
          <p>
            {active === "browser"
              ? browserInstructions
              : active === "phone"
                ? phoneInstructions
                : mailInstructions}
          </p>
          {active === "browser" && <EmbedLinks />}
          {active === "email" && (
            <div>
              <p>Communicate with this Agent by sending an email to: </p>
              <div className="flex flex-row gap-2">
                <p>{`${activeAgent?.id}@in.fabrk.ai`}</p>
                <CopyToClipboardIconButton
                  text={`${activeAgent?.id}@in.fabrk.ai`}
                />
              </div>
            </div>
          )}
        </div>
        {active === "email" && (
          <InputBaseWithIcon
            id="email"
            name="email"
            type="email"
            placeholder="you@example.com"
            value={value}
            setValue={setValue}
          >
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <EnvelopeIcon
                aria-hidden="true"
                className="h-5 w-5 text-skin-muted"
              />
            </div>
          </InputBaseWithIcon>
        )}
        {active === "phone" && (
          <PhoneNumberInput value={value} setValue={setValue} />
        )}
        {value && (
          <SubmitButton onClick={handleSubmit}>
            Receive {active === "phone" ? "text message" : "email"}
          </SubmitButton>
        )}
      </div>
    </div>
  );
}

export default AgentMediumButtons;
