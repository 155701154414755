/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useMemo } from "react";
import {
  Control,
  FieldArrayWithId,
  SubmitHandler,
  useFieldArray,
  UseFieldArrayRemove,
  useForm,
  UseFormRegister,
  UseFormReset,
} from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { QuestionTypes, SchemaBase } from "../../../api/extractionSchema.type";
import { useCompanyContext } from "../../fabrk/hooks/CompanyContext";
import { useExtractionSchemaContext } from "../../fabrk/hooks/ExtractionSchemaContext";
import { useToolContext } from "../../tools/hooks/ToolContext";
import { ParameterNameWithQuestions } from "./ExtractionParametersList";

export const ExtractionParametersTableContextWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { updateSchema, createSchema, activeSchema, extractionSchemaAgents } =
    useExtractionSchemaContext();

  const extractionSchema =
    extractionSchemaAgents &&
    extractionSchemaAgents[0] &&
    extractionSchemaAgents[0].extraction_schema;

  const [searchParams] = useSearchParams();

  const agentId = searchParams.get("agentId");

  const { company } = useCompanyContext();
  const { tool } = useToolContext();

  const s = useMemo(() => {
    return activeSchema;
  }, [activeSchema, extractionSchema]);

  const defaultValues = s?.map((item) => {
    return {
      questionId: item.id,
      type: item.type === QuestionTypes.SELECT ? "text" : item.type,
      question: item.question,
      required: item.required,
    };
  });

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      name: extractionSchema?.name,
      id: extractionSchema?.id,
      questions: defaultValues,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "questions",
  });

  // UseEffect to update the form when activeSchema changes
  useEffect(() => {
    if (activeSchema) {
      const updatedValues = {
        name: extractionSchema?.name,
        id: extractionSchema?.id,
        questions: defaultValues,
      };
      reset(updatedValues); // Reset form with new values when activeSchema changes
    }
  }, [activeSchema, extractionSchema]);

  const onSubmit: SubmitHandler<any> = (data: any) => {
    const values = {
      name: data.name || extractionSchema?.name || tool?.name + " Schema",
      schema: data.questions.map((item: SchemaBase, index: number) => {
        return {
          id: item.id ? item.id : index + 1,
          type: item.type,
          question: item.question,
          required: item.required,
        };
      }),
      companyId: company?.id,
      agentId: agentId as string,
      ...(extractionSchema?.id && {
        id: extractionSchema.id,
      }),
      toolId: tool?.id as string,
    };

    if (extractionSchema?.id) {
      updateSchema(values);
    } else {
      createSchema(values);
    }

    reset();
  };

  const itemCount = fields.length;

  function handleSetSelectedType(id: number, value: string) {
    setValue(`questions.${id}.type`, value);
  }
  const value = {
    onSubmit,
    handleSubmit,
    isDirty,
    append,
    fields,
    reset,
    remove,
    register,
    itemCount,
    control,
    handleSetSelectedType,
  };

  return (
    <ExtractionParametersTableContext.Provider value={value}>
      {children}
    </ExtractionParametersTableContext.Provider>
  );
};

export const ExtractionParametersTableContext = createContext({
  isDirty: false,
  append: {} as any,
  fields: [] as FieldArrayWithId<
    ParameterNameWithQuestions,
    "questions",
    "id"
  >[],
  reset: {} as UseFormReset<ParameterNameWithQuestions>,
  remove: {} as UseFieldArrayRemove,
  register: {} as UseFormRegister<ParameterNameWithQuestions>,
  itemCount: 0,
  control: {} as Control<ParameterNameWithQuestions, any>,
  handleSetSelectedType: (id: number, value: string) => {},
  onSubmit: {} as SubmitHandler<any>,
  handleSubmit: {} as any,
});

export const useExtractionParametersTableContext = () =>
  useContext(ExtractionParametersTableContext);
