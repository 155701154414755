import { ApiEndpoints } from "./apiEndpoints";
import { fetchData, setHeaderOptions } from "./apiHelpers";
import { CreateCredentialRow, CredentialsRow } from "./credentials.type";

export function createOrUpdateCredentialsForAgent({
  agentId,
  credentials,
}: {
  agentId: string;
  credentials: CreateCredentialRow[];
}): Promise<CredentialsRow> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.credential +
    "/agent/" +
    agentId;

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify({ credentials }),
  });

  return fetchData<CredentialsRow>(url, request);
}

export function getCredentialsByAgent(
  agentId: string,
): Promise<CredentialsRow[]> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.credential +
    "/agent/" +
    agentId;

  const request = setHeaderOptions({
    method: "GET",
  });
  return fetchData<CredentialsRow[]>(url, request);
}
