/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { CredentialsRow } from "../../../api/credentials.type";
import AddSaveButtons from "../../shared/table/AddSaveButtons";
import TableWithSearch, {
  TableHeadItem,
} from "../../shared/table/TableWithSearch";
import { handlePageNav } from "../../shared/table/handlePagination";
import {
  handleFilterValue,
  setHeadFilters,
} from "../../shared/table/setTableHeadWithFilters";
import { useCredentialsContext } from "../hooks/CredentialsContext";
import CredentialsList from "./CredentialsList";

const baseTableHead = [
  { label: "Created" },
  { label: "Key" },
  { label: "Value" },
  { label: "Edit" },
  { label: "Remove" },
];

function CredentialsTable({
  credentials,
}: {
  credentials: CredentialsRow[] | undefined;
}) {
  const [currentPage, setCurrentPage] = useState(0);

  const { append, fields, isEdit, reset, handleCreateCredentials, setIsEdit } =
    useCredentialsContext();

  const [filteredResponses, setFilteredResponses] = useState<CredentialsRow[]>(
    credentials ? credentials : [],
  );

  const rowsPerPage = 20;

  const [tableHead, setTableHead] = useState<TableHeadItem[]>(baseTableHead);

  function baseFilter() {
    return (er: Partial<CredentialsRow>, index: number) => index < rowsPerPage;
  }

  const [extractionFilterFunction, setExtractionFilterFunction] =
    useState<(tool: Partial<CredentialsRow>, index: number) => boolean>(
      baseFilter,
    );

  const rowCount =
    credentials && credentials.length > 0 ? credentials.length : null;

  function handleAppend() {
    setIsEdit(true);
    const newCred = {
      id: (fields.length + 1).toString(),
      key_value: { key: "key_example", value: "" },
    };

    append(newCred);
  }

  function handleReset() {
    reset({
      credentials: credentials,
    });
    setIsEdit(false);
  }

  useEffect(() => {
    if (credentials) {
      setHeadFilters(
        credentials,
        filteredResponses,
        setFilteredResponses,
        setTableHead,
        baseTableHead,
      );
    }
  }, [credentials]);

  return (
    <form onSubmit={handleCreateCredentials}>
      <div className="max-w-full gap-4">
        <TableWithSearch
          tableHead={tableHead}
          rowsPerPage={rowsPerPage}
          rowCount={rowCount}
          currentPage={currentPage}
          handleFilterValue={(value) =>
            handleFilterValue(
              tableHead,
              value,
              credentials,
              setFilteredResponses,
              setExtractionFilterFunction,
              baseFilter,
            )
          }
          handleNextPage={() =>
            handlePageNav({
              currentPage,
              pageSetFunction: setCurrentPage,
              rowsPerPage,
              setFilterFunction: setExtractionFilterFunction,
              advancePage: true,
            })
          }
          handlePreviousPage={() =>
            handlePageNav({
              currentPage,
              pageSetFunction: setCurrentPage,
              rowsPerPage,
              setFilterFunction: setExtractionFilterFunction,
              advancePage: false,
            })
          }
          headerComponent={
            <AddSaveButtons
              addButtonLabel="Add Credential"
              handleAppend={handleAppend}
              isEdit={isEdit}
              handleReset={handleReset}
              handleSave={handleCreateCredentials}
            />
          }
        >
          <CredentialsList
            credentials={credentials}
            filterFunction={extractionFilterFunction}
          />
        </TableWithSearch>
      </div>
    </form>
  );
}

export default CredentialsTable;
