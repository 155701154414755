import { TrashIcon } from "@heroicons/react/24/outline";
import { Typography } from "@material-tailwind/react";
import { snakeCaseToTitleCase } from "../../../utils/stringManipulation";
import { EditIcon } from "../../shared/icons/icons";
import { ParametersRowPropsWithEdit } from "./ExtractionParametersRowContainer";
import { useExtractionParametersTableContext } from "./ExtractionParametersTableContext";
import {
  TableListContainer,
  TableRowBase,
} from "../../shared/table/TableComponents";

export function ExtractionParametersViewRow({
  item,
  index,
  isEdit,
  setIsEdit,
}: ParametersRowPropsWithEdit) {
  const { remove } = useExtractionParametersTableContext();

  const { id, question, type, required, questionId } = item;

  return (
    <TableListContainer key={id}>
      <TableRowBase>
        <Typography variant="small" className="font-normal">
          {questionId}
        </Typography>
      </TableRowBase>

      <TableRowBase>
        <Typography variant="small" className="font-normal capitalize">
          {snakeCaseToTitleCase(type)}
        </Typography>
      </TableRowBase>

      <TableRowBase>
        <Typography
          variant="small"
          className="capitaliz font-normal text-skin-base"
        >
          {question}
        </Typography>
      </TableRowBase>

      <TableRowBase>
        <Typography
          variant="small"
          className="font-normal capitalize text-skin-base"
        >
          {required ? "Yes" : "No"}
        </Typography>
      </TableRowBase>
      <TableRowBase>
        <button
          onClick={(e) => {
            e.preventDefault();
            setIsEdit(!isEdit);
          }}
        >
          <div className="flex flex-row items-center gap-2">
            <EditIcon className="h-4 w-4" />
          </div>
        </button>
      </TableRowBase>
      <TableRowBase>
        <button
          onClick={(e) => {
            e.preventDefault();
            remove(index);
          }}
        >
          <div className="flex flex-row items-center gap-2">
            <TrashIcon className="h-4 w-4 text-red-400" />
          </div>
        </button>
      </TableRowBase>
    </TableListContainer>
  );
}
