import { Typography } from "@material-tailwind/react";

function SignupFooterLink({ setLogin }: { setLogin: () => void }) {
  return (
    <Typography variant="small" className="text-skin-base/80">
      Don't have an account?{" "}
      <span
        style={{
          cursor: "pointer",
        }}
        onClick={() => {
          setLogin();
        }}
        className="text-skin-accent underline"
      >
        Sign up
      </span>
    </Typography>
  );
}

export default SignupFooterLink;
