"use client";

import { Bars3Icon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";

import { useSession } from "@supabase/auth-helpers-react";
import { useLocation } from "react-router-dom";
import { AgentAgentToolContextWrapper } from "../agent/hooks/AgentAgentToolContext";
import { AgentContextWrapper } from "../agent/hooks/AgentContext";
import { AgentToolContextWrapper } from "../fabrk/hooks/AgentToolContext";
import { ClientAgentContextWrapper } from "../fabrk/hooks/ClientAgentContext";
import { MessageContextWrapper } from "../fabrk/hooks/MessageContext";
import NewAgentButton from "../shared/buttons/NewAgentButton";
import FloatingAlert from "../shared/forms/FloatingAlert";
import LoadingSpinnerWithBackdrop from "../shared/forms/LoadingWithBackdrop";
import { ToolContextWrapper } from "../tools/hooks/ToolContext";
import LayoutNavContainer from "./LayoutNavContainer";
import LogoWithTitle from "./LogoWithTitle";
import NotificationBannerContainer from "./NotificationBanner";
import ProfileMenu from "./ProfileMenu";
import SidebarDialogContainer from "./SidebarDialogContainer";

export default function LayoutContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  const session = useSession();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [current, setCurrent] = useState<string>("");

  const location = useLocation();

  useEffect(() => {
    setCurrent(location.pathname);
  }, [location.pathname]);

  return (
    <ToolContextWrapper>
      <AgentContextWrapper>
        <AgentToolContextWrapper>
          <ClientAgentContextWrapper>
            <MessageContextWrapper>
              <AgentAgentToolContextWrapper>
                <div className="relative z-10 bg-skin-fill">
                  {!session && <NotificationBannerContainer />}
                  {/* Mobile sidebar */}
                  <SidebarDialogContainer
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                    current={current}
                  />
                  {/* Static sidebar for desktop */}
                  <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-80 lg:flex-col">
                    <div className="flex grow flex-col gap-y-5 border-r border-skin-hover bg-skin-fill px-6 pb-4">
                      <LogoWithTitle />
                      <LayoutNavContainer current={current} />
                    </div>
                  </div>

                  <div className="lg:pl-72">
                    <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-skin-hover bg-skin-fill px-4 shadow-sm sm:gap-x-6 sm:px-6 md:border-b lg:px-8">
                      <button
                        type="button"
                        onClick={() => setSidebarOpen(true)}
                        className="-m-2.5 p-2.5 text-skin-base lg:hidden"
                      >
                        <span className="sr-only">Open sidebar</span>
                        <Bars3Icon aria-hidden="true" className="h-6 w-6" />
                      </button>

                      {/* Separator */}
                      {sidebarOpen && (
                        <div
                          aria-hidden="true"
                          className="h-6 w-px bg-gray-200 lg:hidden"
                        />
                      )}

                      <div className="flex flex-1 items-end justify-end gap-x-4 bg-skin-fill/80 lg:gap-x-6">
                        <div className="flex flex-row items-end gap-4">
                          <div className="flex items-end">
                            <NewAgentButton />
                          </div>
                          <ProfileMenu />
                        </div>
                      </div>
                    </div>

                    <main>
                      <div className="ml-10 bg-skin-fill">{children}</div>
                    </main>
                  </div>
                  <FloatingAlert />
                  <LoadingSpinnerWithBackdrop />
                </div>
              </AgentAgentToolContextWrapper>
            </MessageContextWrapper>
          </ClientAgentContextWrapper>
        </AgentToolContextWrapper>
      </AgentContextWrapper>
    </ToolContextWrapper>
  );
}
