export function parseCentsToDollars(cents: number): string {
  if (cents === 0) {
    return "$0.00";
  }

  if (!cents) {
    return "N/A";
  }
  return (cents / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export function parseCentsToDollarsNoDollarSign(cents: string): string {
  if (!cents) {
    return "N/A";
  }
  const dollars = (parseInt(cents) / 100).toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return dollars;
}

export function parseDollarsToCents(dollars: string) {
  if (!dollars) {
    return null;
  }
  // Remove any non-digit characters from the input string
  const cleanedString = dollars.replace(/[^0-9.]/g, "");

  // Parse the cleaned string as a float and multiply by 100 to get cents
  const cents = parseFloat(cleanedString) * 100;

  // Round to the nearest whole number (integer)
  return Math.round(cents);
}

export function calculateFee(
  amount: number,
  paymentMethodType: string | undefined,
) {
  if (!amount) return "";
  if (!paymentMethodType) return "";
  const bankTransferFee = 0.005;
  const creditCardFee = 0.03;
  const fee = paymentMethodType === "card" ? creditCardFee : bankTransferFee;
  const amountWithFee = roundToTwoDecimalPlacesWithFee(Number(amount), fee);
  return amountWithFee;
}

export function roundToTwoDecimalPlacesWithFee(
  amount: number,
  fee: number,
): string {
  const amountWithFee = amount * (1 + fee);
  return amountWithFee.toFixed(2);
}

export function roundToTwoDecimalPlaces(amount: number): string {
  return amount.toFixed(2);
}

export const centsToUsDollars = (number: number) =>
  `$${new Intl.NumberFormat("us").format(number / 100).toString()}`;
