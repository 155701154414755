/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import { UseMutateFunction, useMutation } from "react-query";

import { useParams } from "react-router-dom";
import {
  forkToolById,
  getToolById,
  updateToolById,
} from "../../../api/tool.api";
import { ToolRow, ToolUpdate } from "../../../api/tool.type";

import { postSaveConnectionId } from "../../../api/nango.api";
import { useAuthContext } from "../../fabrk/hooks/AuthContext";
import { useAlertContext } from "../../shared/alert/alertContext";

export const ToolContextWrapper = (props: any) => {
  const { setAlertProps, setGlobalLoading } = useAlertContext();
  const { companyId } = useAuthContext();

  const [isCompanyTool, setIsCompanyTool] = useState(false);
  const [isFileTool, setIsFileTool] = useState(false);

  const [selectedTool, setSelectedTool] = useState<ToolRow | undefined>();
  const { id } = useParams();

  const { mutate: getTool, data: tool } = useMutation(getToolById);

  const { mutate: updateTool } = useMutation(updateToolById, {
    onSuccess: async (res) => {
      getTool(id as string);
      setAlertProps({
        message: "Tool updated",
        color: "green",
      });
    },
    onError(error: Error) {
      setAlertProps({
        message: error.message,
        color: "red",
      });
    },
  });

  const { mutate: forkTool } = useMutation(forkToolById, {
    onSuccess: async (res) => {
      if (id) {
        getTool(id as string);
      } else {
        if (res.newTool) {
          getTool(res.newTool.id);
        }
      }

      setAlertProps({
        message: "Tool created",
        color: "green",
      });
    },
    onError(error: Error) {
      setAlertProps({
        message: error.message,
        color: "red",
      });
    },
  });

  const { mutate: saveConnectionId, isLoading: saveConnectionLoading } =
    useMutation(postSaveConnectionId, {
      onSuccess: async (res) => {
        getTool(id as string);

        setAlertProps({
          message: "Connection created",
          color: "green",
        });
      },
      onError(error: Error) {
        setAlertProps({
          message: error.message,
          color: "red",
        });
      },
    });

  function handleGetTool() {
    if (id) {
      getTool(id);
    }
  }

  useEffect(() => {
    if (tool?.type === "file_search" || tool?.type === "data_analysis") {
      setIsFileTool(true);
    }
  }, [tool]);

  const [isForkingToolId, setIsForkingToolId] = useState("");

  function handleForkTool(toolId: string) {
    forkTool({ toolId, companyId });
  }

  useEffect(() => {
    if (isForkingToolId && companyId) {
      handleForkTool(id as string);
      setIsForkingToolId("");
    }
  }, [companyId, isForkingToolId]);

  useEffect(() => {
    if (!tool) {
      handleGetTool();
    }

    return () => {
      setSelectedTool(undefined);
    };
  }, [id]);

  useEffect(() => {
    if (tool) {
      const found = tool?.company_tool?.find(
        (ct) => ct.company_id === companyId,
      );

      if (found) {
        setIsCompanyTool(true);
      }
    }
  }, [tool, companyId]);

  useEffect(() => {
    setGlobalLoading(saveConnectionLoading);
  }, [saveConnectionLoading]);

  const value = {
    tool,
    updateTool,
    handleGetTool,
    selectedTool,
    setSelectedTool,
    isCompanyTool,
    handleForkTool,
    isFileTool,
    saveConnectionId,
  };

  return (
    <toolContext.Provider value={value}>{props.children}</toolContext.Provider>
  );
};

export const toolContext = createContext({
  tool: {} as ToolRow | undefined,
  updateTool: {} as UseMutateFunction<ToolRow, Error, ToolUpdate, unknown>,
  handleGetTool: {} as () => void,
  selectedTool: {} as ToolRow | undefined,
  setSelectedTool: {} as (tool: ToolRow) => void,
  isCompanyTool: false,
  handleForkTool: {} as (toolId: string) => void,
  isFileTool: false,
  saveConnectionId: {} as UseMutateFunction<
    void,
    Error,
    {
      connectionId: string;
      agentToolId?: string;
    },
    unknown
  >,
});

export const useToolContext = () => useContext(toolContext);
