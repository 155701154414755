/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import {
  getVisibilityByAgent,
  updateVisibilityById,
} from "../../../api/visibility.api";
import {
  UpdateVisibilityRow,
  VisibilityRow,
} from "../../../api/visibility.type";
import { useAlertContext } from "../../shared/alert/alertContext";
import { useAgentContext } from "./AgentContext";

export const VisibilityContextWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { setAlertProps } = useAlertContext();
  const { activeAgent } = useAgentContext();

  const [updatedValues, setUpdatedValues] = useState<
    Partial<UpdateVisibilityRow>
  >({} as Partial<UpdateVisibilityRow>);

  const {
    mutate: getAgentVisibility,
    data: visibilityValues,
    isLoading: agentVisibilityLoading,
  } = useMutation(getVisibilityByAgent);

  const { mutate: update, isLoading: createVisibilityLoading } = useMutation(
    updateVisibilityById,
    {
      onSuccess() {
        setAlertProps({
          message: "Visibility configuration updated",
          color: "green",
        });
        getAgentVisibility(activeAgent?.id as string);
      },
    },
  );

  async function handleUpdateVisibility() {
    if (updatedValues && visibilityValues) {
      // check for values in updatedValues
      if (Object.keys(updatedValues).length) {
        update({
          id: visibilityValues?.id,
          ...updatedValues,
        });
      }
    }
  }

  useEffect(() => {
    if (activeAgent) {
      getAgentVisibility(activeAgent?.id);
    }
  }, [activeAgent, getAgentVisibility]);

  const value = {
    visibilityValues,
    isLoading: agentVisibilityLoading || createVisibilityLoading,
    handleUpdateVisibility,

    setUpdatedValues,
  };

  return (
    <VisibilityContext.Provider value={value}>
      {children}
    </VisibilityContext.Provider>
  );
};

export const VisibilityContext = createContext({
  visibilityValues: {} as VisibilityRow | undefined,
  handleUpdateVisibility: () => {},
  setUpdatedValues: (values: Partial<UpdateVisibilityRow>) => {},
});

export const useVisibilityContext = () => useContext(VisibilityContext);
