import { useQuery } from "react-query";
import { getCreateToolsList } from "../../../api/tool.api";

export function useLoadCreateToolsList() {
  const {
    data: createToolsList,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: "createToolsList",
    queryFn: () => getCreateToolsList(),
  });

  return {
    isLoading,
    createToolsList,
    error,
    refetch,
  };
}
