/* eslint-disable react-hooks/exhaustive-deps */
import { PlusIcon, ShareIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "@material-tailwind/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PublicAgentRow } from "../../../api/agent.type";
import { AgentToolRow } from "../../../api/agentTool.type";

import { useAuthContext } from "../../fabrk/hooks/AuthContext";
import { useClientAgentContext } from "../../fabrk/hooks/ClientAgentContext";
import { useClientContext } from "../../fabrk/hooks/ClientContext";
import BaseChip from "../../shared/chip/BaseChip";
import { DiscoverGridItem } from "../../shared/containers/Containers";
import FabrkMenuDivider from "../../shared/dividers/FabrkMenuDivider";
import { useAgentContext } from "../hooks/AgentContext";
import AgentHeaderPills from "./AgentHeaderPills";
import DiscoverListItemHeader from "./DiscoverListItemHeader";

function DiscoverAgentListItem({ c }: { c: PublicAgentRow }) {
  const navigate = useNavigate();

  const {
    id,
    created_at,
    name,
    agent_fork,
    client_agent,
    goal,
    system_prompt,
    agent_tool,
    image_path,
  } = c;

  const { createClientAgent, newClientAgent } = useClientAgentContext();

  const { companyId } = useAuthContext();
  const { client } = useClientContext();

  const { fork } = useAgentContext();

  useEffect(() => {
    if (newClientAgent) {
      navigate(`/a/${newClientAgent.agent_id}/t/${newClientAgent.id}`);
    }
  }, [newClientAgent]);

  return (
    <DiscoverGridItem key={id}>
      <DiscoverListItemHeader
        link={`/agent/${id}`}
        createdAt={created_at}
        name={name}
        imagePath={image_path}
      >
        <AgentHeaderPills
          agentForkCount={agent_fork?.length || 0}
          clientAgentCount={client_agent?.length || 0}
        />
      </DiscoverListItemHeader>
      <div className="flex w-full flex-col gap-6">
        <div className="group relative">
          <p className="mt-5 line-clamp-3 text-wrap p-1 text-sm leading-6 text-skin-base">
            {goal || system_prompt?.text || ""}
          </p>
        </div>
        <div className="w-full">
          <div className="flex flex-row justify-between font-semibold">
            <p className="text-skin-base">Tools</p>
            <div className="rounded-md bg-skin-accentSecondary px-2 py-1">
              <p className="flex items-baseline gap-x-2">
                <span className="tracking-tight text-skin-inverse">
                  {agent_tool?.length || 0}
                </span>
              </p>
            </div>
          </div>
          <FabrkMenuDivider />
          <div className="grid grid-cols-1 gap-1 md:grid-cols-2">
            {agent_tool?.map((ai: AgentToolRow) => (
              <div key={ai.id}>
                <BaseChip>{ai.tool.name}</BaseChip>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="mt-5 flex flex-wrap space-y-3 sm:space-x-3 sm:space-y-0">
        <Tooltip
          placement="bottom"
          content="Chat with this agent. You will not be able to edit the agent."
          className="bg-skin-hover text-skin-base"
        >
          <button
            type="button"
            className="inline-flex w-24 flex-shrink-0 items-center justify-center gap-2 rounded-md bg-skin-accentSecondary px-3 py-2 text-sm font-semibold text-skin-hover shadow-sm hover:bg-skin-accent sm:flex-1"
            onClick={() => {
              createClientAgent({
                agentId: id,
                clientId: client?.id as string,
              });
            }}
          >
            <PlusIcon className="h-4 w-4" />
            Thread
          </button>
        </Tooltip>
        <Tooltip
          placement="bottom"
          content="Fork this agent. This will create a new agent that you can edit."
          className="bg-skin-hover text-skin-base"
        >
          <button
            type="button"
            onClick={() => {
              fork({
                agentId: id,
                companyId: companyId,
                clientId: client?.id as string,
              });
            }}
            className="inline-flex w-24 flex-shrink-0 items-center justify-center gap-2 rounded-md border-2 bg-skin-fill px-3 py-2 text-sm font-semibold text-skin-base shadow-sm hover:bg-skin-accentSecondary hover:text-skin-inverse sm:flex-1"
          >
            <ShareIcon className="h-4 w-4" />
            Fork
          </button>
        </Tooltip>
      </div>
    </DiscoverGridItem>
  );
}

export default DiscoverAgentListItem;
