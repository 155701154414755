import { Spinner } from "@material-tailwind/react";

export function LoadingSpinnerForButtons() {
  return (
    <div className="flex items-center justify-center">
      <Spinner className="h-4 w-4" />
    </div>
  );
}

function LoadingSpinner() {
  return (
    <div className="mt-20 flex items-center justify-center">
      <Spinner className="h-12 w-12" />
    </div>
  );
}

export default LoadingSpinner;
