import { ApiEndpoints } from "./apiEndpoints";
import { fetchData, setHeaderOptions } from "./apiHelpers";
import { PaymentRow } from "./payment.type";

export function getPayments(): Promise<PaymentRow[]> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.payment;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<PaymentRow[]>(url, request);
}
