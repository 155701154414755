import { MagnifyingGlassIcon, WrenchIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "@material-tailwind/react";
import LayoutContainer from "../../components/layout/LayoutContainer";
import { DiscoverGrid } from "../../components/shared/containers/Containers";
import LoadingSpinner from "../../components/shared/forms/LoadingSpinner";
import { PlusIcon, ShareIcon } from "../../components/shared/icons/icons";
import DiscoverToolsListItem from "../../components/tools/DiscoverToolsListItem";
import { useLoadPublicTools } from "../../components/tools/hooks/usePublicTools";
import { useNavigate } from "react-router-dom";

function DiscoverToolsContainer() {
  const { publicTools, isLoading } = useLoadPublicTools();

  const navigate = useNavigate();

  return (
    <LayoutContainer>
      <div className="h-full min-h-screen bg-skin-fill py-10 sm:py-24">
        <div className="mx-auto flex max-w-7xl justify-between px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="flex flex-row items-center gap-4 text-3xl font-bold tracking-tight text-skin-base sm:text-4xl">
              <WrenchIcon className="h-8 w-8" />
              Public Tools
            </h2>

            <p className="mt-2 text-lg leading-8 text-skin-base">
              List of public tools that you can explore and add to your agents.
            </p>
          </div>
          <div className="mt-10 flex items-center gap-x-6">
            <button
              className="flex flex-row items-center gap-2 rounded-md bg-skin-accentSecondary px-3.5 py-2.5 text-sm font-semibold text-skin-base shadow-sm hover:bg-skin-accent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
              onClick={() => navigate("/tool/create")}
            >
              <PlusIcon className="h-5 w-5" />
              Create Tool
            </button>
            <a
              href="/discover/agents"
              className="flex flex-row items-center gap-2 rounded-md px-3.5 py-2.5 text-sm font-semibold leading-6 text-skin-base hover:bg-skin-hover"
            >
              <MagnifyingGlassIcon className="h-5 w-5" />
              Discover agents
            </a>
          </div>
        </div>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <DiscoverGrid>
            {publicTools?.map((t) => (
              <>
                <DiscoverToolsListItem tool={t}>
                  <div className="mt-5 flex flex-wrap space-y-3 sm:space-x-3 sm:space-y-0">
                    <Tooltip
                      placement="bottom"
                      content="Fork this agent. This will create a new agent that you can edit."
                      className="bg-skin-hover text-skin-base"
                    >
                      <button
                        type="button"
                        className="inline-flex w-24 flex-shrink-0 items-center justify-center gap-2 rounded-md border-2 bg-skin-fill px-3 py-2 text-sm font-semibold text-skin-base shadow-sm hover:bg-skin-accentSecondary hover:text-skin-inverse sm:flex-1"
                      >
                        <ShareIcon className="h-4 w-4" />
                        Fork
                      </button>
                    </Tooltip>
                  </div>
                </DiscoverToolsListItem>
              </>
            ))}
          </DiscoverGrid>
        )}
      </div>
    </LayoutContainer>
  );
}

export default DiscoverToolsContainer;
