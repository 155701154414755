import clsx from "clsx";
import { ClientAgentRow } from "../../api/clientAgent.type";
import { formatFullDateToShortDate } from "../../utils/parseTimeStamp";
import { getFirstTwoLettersFromSentence } from "../../utils/stringManipulation";
import { Link, useSearchParams } from "react-router-dom";

function LcNavItem({
  lc,
  isActive = false,
}: {
  lc: ClientAgentRow;
  isActive: boolean;
}) {
  const [searchParams] = useSearchParams();

  const agentType = searchParams.get("agentType");
  return (
    <Link
      to={`/a/${lc?.agent_id}/t/${lc?.id}${agentType ? `?agentType=${agentType}` : ""}`}
      className={clsx(
        isActive ? "text-skin-accent" : "text-skin-base hover:bg-skin-hover",
        "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6",
      )}
    >
      <span
        className={clsx(
          isActive
            ? "border-skin-accent text-skin-accent"
            : "border-skin-muted text-skin-base group-hover:border-indigo-600",
          "flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border bg-skin-fill text-[0.625rem] font-medium capitalize",
        )}
      >
        {getFirstTwoLettersFromSentence(lc?.client?.first_name || "")}
      </span>
      <span className="truncate">
        {lc.client?.first_name &&
          lc.client.first_name + " " + lc.client.last_name + " - "}
        {formatFullDateToShortDate(lc?.created_at)}
      </span>
    </Link>
  );
}

export default LcNavItem;
