import clsx from "clsx";

function IconButton({
  children,
  onClick,
  active,
}: {
  children: React.ReactNode;
  onClick?: () => void;
  active?: boolean;
}) {
  return (
    <button
      className={clsx(
        "flex w-fit flex-row items-center gap-2 rounded-full px-2 py-1 text-sm font-medium",
        active
          ? "bg-skin-accentSecondary text-skin-hover"
          : "bg-skin-hover text-skin-muted hover:bg-skin-accent hover:text-skin-inverse",
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

export default IconButton;
