/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from "@material-tailwind/react";
import { useEffect, useRef, useState } from "react";
import Markdown from "react-markdown";
import rehypeHighlight from "rehype-highlight";
import remarkGfm from "remark-gfm";
import { MessageRow } from "../../../api/message.type";
import { formatTimeStampToHumanReadableDateTimeSeconds } from "../../../utils/parseTimeStamp";
import { List } from "../../shared/icons/icons";
import { MessageMetadataContextWrapper } from "./hooks/MessageMetadataContext";
import MessageMetadataContainer from "./metadata/MessageMetadataContainer";
import { useMessageContext } from "../hooks/MessageContext";
import { useVoiceContext } from "../../shared/voice/VoiceRecorderContext";

function AssistantMessageChatItem({
  message,
  showMetadata,
  userMessageId,
}: {
  message: MessageRow;
  showMetadata?: boolean;
  userMessageId?: string;
}) {
  const audioRef = useRef<HTMLAudioElement | null>(null); // Reference to the audio element
  const [showMessageMetadata, setShowMessageMetadata] = useState(false);
  const { urlToAutoPlay, setUrlToAutoPlay } = useMessageContext();
  const elementRef = useRef<HTMLDivElement | null>(null); // Reference to the message element
  const [isInView, setIsInView] = useState(false);
  const { autoplayEnabled } = useVoiceContext();

  function playAudio() {
    if (audioRef.current) {
      audioRef.current.play();
    }
  }

  useEffect(() => {
    if (autoplayEnabled && urlToAutoPlay === message.audio_url && isInView) {
      playAudio();
      setUrlToAutoPlay(null);
    }
  }, [urlToAutoPlay]);

  useEffect(() => {
    setShowMessageMetadata(showMetadata || false);
  }, [showMetadata]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(entry.isIntersecting); // Update state based on viewport visibility
      },
      {
        root: null, // Observe relative to the viewport
        threshold: 0.5, // Element is considered in view if 50% visible
      },
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []);

  return (
    <div
      className="flex w-full flex-col justify-between gap-4"
      ref={elementRef}
    >
      <div className="flex flex-col gap-2">
        {message.audio_url && (
          <audio ref={audioRef} controls src={message.audio_url}></audio>
        )}
        <div className="flex flex-row items-center justify-between p-2 text-skin-base">
          <div>
            <Markdown rehypePlugins={[rehypeHighlight, remarkGfm]}>
              {message.content}
            </Markdown>
            {message.created_at && (
              <Typography className="text-xs text-skin-base">
                {formatTimeStampToHumanReadableDateTimeSeconds(
                  message.created_at,
                )}
              </Typography>
            )}
          </div>

          {message.created_at && (
            <button
              className="justify-center rounded-md p-1 text-sm font-semibold hover:bg-skin-hover"
              onClick={() => setShowMessageMetadata(!showMessageMetadata)}
            >
              <List aria-hidden="true" className="h-4 w-4 text-skin-muted" />
            </button>
          )}
        </div>
      </div>
      <MessageMetadataContextWrapper
        assistantMessageId={message.id}
        userMessageId={userMessageId as string}
      >
        <MessageMetadataContainer showMetadata={showMessageMetadata} />
      </MessageMetadataContextWrapper>
    </div>
  );
}

export default AssistantMessageChatItem;
