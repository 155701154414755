import { Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { SmsMetadataRow } from "../../../../api/smsMetadata.type";
import {
  TableListContainer,
  TableRowBase,
} from "../../../shared/table/TableComponents";
import { useClientAgentContext } from "../../hooks/ClientAgentContext";
import ToolCallResponseInputValue from "./ToolCallResponseInputValue";

export function CalledToolRowView({ item }: { item: SmsMetadataRow }) {
  const { function_call_name, function_call_value, function_call_response } =
    item;

  const { activeClientAgent } = useClientAgentContext();

  const link = item.agent_tool_id
    ? `/a/${item?.agent_tool_id}/t/${activeClientAgent?.id}`
    : item?.tool_id
      ? `/tools/${item?.tool_id}`
      : "/tools";

  return (
    <TableListContainer key={item.id}>
      <TableRowBase>
        <Link to={link}>
          <Typography
            variant="small"
            className="font-normal text-skin-base hover:underline"
          >
            {function_call_name}
          </Typography>
        </Link>
      </TableRowBase>
      <TableRowBase>
        <ToolCallResponseInputValue functionCallValue={function_call_value} />
      </TableRowBase>

      <TableRowBase>
        <Typography
          variant="small"
          className="max-w-md text-wrap font-normal capitalize text-skin-base"
        >
          {function_call_response}
        </Typography>
      </TableRowBase>
    </TableListContainer>
  );
}
