import { useToolContext } from "./hooks/ToolContext";

function ViewToolHeaderImg() {
  const { tool } = useToolContext();

  return (
    <div className="lg:pr-4">
      <div className="relative overflow-hidden rounded-3xl bg-skin-base px-6 pb-9 pt-32 shadow-2xl sm:px-12 lg:max-w-lg lg:px-8 lg:pb-8 xl:px-10 xl:pb-10">
        <img
          alt=""
          src={tool?.image_path || "/images/robot.webp"}
          className="absolute inset-0 h-full w-full object-cover brightness-125 saturate-0"
        />

        <figure className="relative isolate">
          <figcaption className="mt-6 w-fit rounded-md bg-skin-hover px-4 leading-6">
            <strong className="text-xl font-bold text-skin-accent">
              {tool?.name}
            </strong>{" "}
          </figcaption>
        </figure>
      </div>
    </div>
  );
}

export default ViewToolHeaderImg;
