/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect } from "react";
import { UseMutateFunction, useMutation, useQueryClient } from "react-query";
import { createAction, runNewAction } from "../../api/action.api";
import { useAlertContext } from "../shared/alert/alertContext";
import {
  ActionInsert,
  ActionRow,
  RunActionResponse,
} from "../../api/action.type";
import { useNavigate } from "react-router-dom";
import { useMessageContext } from "../fabrk/hooks/MessageContext";

export const ActionContextWrapper = (props: any) => {
  const { setAlertProps, setGlobalLoading } = useAlertContext();
  const { setAction } = useMessageContext();

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { mutate: runAction, isLoading: loading } = useMutation(runNewAction, {
    onSuccess: async (res: RunActionResponse) => {
      queryClient.invalidateQueries("clientAgents");

      const { action, clientAgent } = res;
      setAction(res.action);
      navigate(`/a/${action.agent_id}/t/${clientAgent.id}`);
    },
    onError(error: Error) {
      setAlertProps({
        message: error.message,
        color: "red",
      });
    },
  });

  const { mutate: create } = useMutation(createAction, {
    onSuccess: async (res) => {
      setAlertProps({
        message:
          "Action created. Navigate to the home screen to see available actions.",
        color: "green",
      });
    },
    onError(error: Error) {
      setAlertProps({
        message: error.message,
        color: "red",
      });
    },
  });

  useEffect(() => {
    setGlobalLoading(loading);
  }, [loading]);

  const value = {
    create,
    runAction,
  };

  return (
    <ActionContext.Provider value={value}>
      {props.children}
    </ActionContext.Provider>
  );
};

export const ActionContext = createContext({
  create: {} as UseMutateFunction<
    ActionRow,
    Error,
    Partial<ActionInsert> | null,
    unknown
  >,
  runAction: {} as UseMutateFunction<
    RunActionResponse,
    Error,
    {
      clientId: string;
      actionId: string;
    },
    unknown
  >,
});

export const useActionContext = () => {
  return useContext(ActionContext);
};
