/* eslint-disable react-hooks/exhaustive-deps */
import { TrashIcon } from "@heroicons/react/24/outline";
import { Typography } from "@material-tailwind/react";
import { Controller, FieldArrayWithId } from "react-hook-form";
import { CreateCredentialRow } from "../../../api/credentials.type";
import { InputBase } from "../../shared/forms/input/InputBase";
import { EditIcon } from "../../shared/icons/icons";
import {
  TableListContainer,
  TableRowBase,
} from "../../shared/table/TableComponents";
import { useCredentialsContext } from "../hooks/CredentialsContext";

export type CredentialsForm = {
  id: string;
  credentials: CreateCredentialRow[];
};

export function CredentialEditRow({
  item,
  index,
  isEdit,
  setIsEdit,
}: {
  isEdit: boolean;
  setIsEdit: (value: boolean) => void;
  item: FieldArrayWithId<CredentialsForm, "credentials", "id">;
  index: number;
}) {
  const { remove, control } = useCredentialsContext();

  const { id } = item;

  return (
    <TableListContainer key={id}>
      <TableRowBase>
        <Typography variant="small" className="font-normal text-skin-base">
          {index + 1}
        </Typography>
      </TableRowBase>
      <TableRowBase>
        <Controller
          render={({ field }) => {
            return (
              <div>
                <InputBase
                  placeholder="Key"
                  value={field.value as string}
                  setValue={field.onChange}
                />
              </div>
            );
          }}
          name={`credentials.${index}.key_value.key`}
          control={control}
        />
      </TableRowBase>

      <TableRowBase>
        <Controller
          render={({ field }) => {
            return (
              <div>
                <InputBase
                  placeholder="Value"
                  value={field.value as string}
                  setValue={field.onChange}
                />
              </div>
            );
          }}
          name={`credentials.${index}.key_value.value`}
          control={control}
        />
      </TableRowBase>

      <TableRowBase>
        <button
          onClick={(e) => {
            e.preventDefault();
            setIsEdit(true);
          }}
        >
          <div className="flex flex-row items-center gap-2">
            <EditIcon className="h-4 w-4" />
          </div>
        </button>
      </TableRowBase>
      <TableRowBase>
        <button
          onClick={(e) => {
            e.preventDefault();
            remove(index);
          }}
        >
          <div className="flex flex-row items-center gap-2">
            <TrashIcon className="h-4 w-4 text-red-400" />
          </div>
        </button>
      </TableRowBase>
    </TableListContainer>
  );
}
