import { LinkIcon } from "@heroicons/react/24/outline";

function ApiDocsLink() {
  return (
    <a
      href="https://docs.fabrk.ai"
      target="_blank"
      rel="noreferrer"
      className="flex items-center gap-2"
    >
      <LinkIcon className="h-4 w-4" />
      <p className="text-skin-accentSecondary underline">API Docs</p>
    </a>
  );
}

export default ApiDocsLink;
