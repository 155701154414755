/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { ScheduleRow } from "../../api/schedule.type";
import TableWithSearch, {
  TableHeadItem,
} from "../shared/table/TableWithSearch";
import { handlePageNav } from "../shared/table/handlePagination";
import {
  handleFilterValue,
  setHeadFilters,
} from "../shared/table/setTableHeadWithFilters";
import ScheduleList from "./ScheduleList";
import AddSaveButtons from "../shared/table/AddSaveButtons";
import { useScheduleContext } from "./ScheduleContext";

const baseTableHead = [
  { label: "Created" },
  { label: "Name" },
  { label: "Content" },
  { label: "Cron" },
  { label: "Edit" },
  { label: "Remove" },
];

function ScheduleTable({
  schedules,
}: {
  schedules: ScheduleRow[] | undefined;
}) {
  const [currentPage, setCurrentPage] = useState(0);

  const [filteredResponses, setFilteredResponses] = useState<ScheduleRow[]>(
    schedules ? schedules : [],
  );

  const {
    handleCreateSchedules,
    handleReset,
    isEdit,
    setIsEdit,
    fields,
    append,
  } = useScheduleContext();

  const rowsPerPage = 20;

  const [tableHead, setTableHead] = useState<TableHeadItem[]>(baseTableHead);

  function baseFilter() {
    return (er: Partial<ScheduleRow>, index: number) => index < rowsPerPage;
  }

  const [extractionFilterFunction, setExtractionFilterFunction] =
    useState<(tool: Partial<ScheduleRow>, index: number) => boolean>(
      baseFilter,
    );

  const rowCount = schedules && schedules.length > 0 ? schedules.length : null;

  function handleAppend() {
    setIsEdit(true);
    const newSchedule = {
      id: (fields.length + 1).toString(),
      created_at: new Date().toISOString(),
      name: "",
      content: "",
      jobId: "",
      cronSchedule: "* * * * *",
    };

    append(newSchedule);
  }

  function handleSave() {
    handleCreateSchedules();
  }

  useEffect(() => {
    if (schedules) {
      setHeadFilters(
        schedules,
        filteredResponses,
        setFilteredResponses,
        setTableHead,
        baseTableHead,
      );
    }
  }, [schedules]);

  return (
    <div className="max-w-full gap-4">
      {schedules && schedules.length > 0 && (
        <TableWithSearch
          tableHead={tableHead}
          rowsPerPage={rowsPerPage}
          rowCount={rowCount}
          currentPage={currentPage}
          handleFilterValue={(value) =>
            handleFilterValue(
              tableHead,
              value,
              schedules,
              setFilteredResponses,
              setExtractionFilterFunction,
              baseFilter,
            )
          }
          handleNextPage={() =>
            handlePageNav({
              currentPage,
              pageSetFunction: setCurrentPage,
              rowsPerPage,
              setFilterFunction: setExtractionFilterFunction,
              advancePage: true,
            })
          }
          handlePreviousPage={() =>
            handlePageNav({
              currentPage,
              pageSetFunction: setCurrentPage,
              rowsPerPage,
              setFilterFunction: setExtractionFilterFunction,
              advancePage: false,
            })
          }
          headerComponent={
            <AddSaveButtons
              addButtonLabel="Add Schedule"
              handleAppend={handleAppend}
              isEdit={isEdit}
              handleReset={handleReset}
              handleSave={handleSave}
            />
          }
        >
          <ScheduleList
            schedules={schedules}
            filterFunction={extractionFilterFunction}
          />
        </TableWithSearch>
      )}
    </div>
  );
}

export default ScheduleTable;
