/* eslint-disable eqeqeq */
import { Fragment } from "react";

import { FieldArrayWithId } from "react-hook-form";
import ExtractionParametersRowContainer from "./ExtractionParametersRowContainer";
import { useExtractionParametersTableContext } from "./ExtractionParametersTableContext";

export type ParameterQuestions = {
  questionId: string;
  type: string;
  question: string;
  required: boolean;
};

export type ParameterNameWithQuestions = {
  name: string | undefined;
  id: string | undefined;
  questions: ParameterQuestions[];
};

export type ParameterField = FieldArrayWithId<
  ParameterNameWithQuestions,
  "questions",
  "id"
>;

function ExtractionParametersList() {
  const { fields } = useExtractionParametersTableContext();
  return (
    <Fragment>
      {fields &&
        fields.map((item, index) => {
          return (
            <ExtractionParametersRowContainer
              key={index}
              item={item}
              index={index}
            />
          );
        })}
    </Fragment>
  );
}

export default ExtractionParametersList;
