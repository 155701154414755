import { ShareIcon, ChatBubbleWithWritingIcon } from "../../shared/icons/icons";

function AgentHeaderPills({
  agentForkCount,
  clientAgentCount,
}: {
  agentForkCount?: number;
  clientAgentCount?: number;
}) {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex h-fit flex-row items-center justify-evenly gap-1 rounded-md bg-skin-fill p-1 text-skin-base">
        <ShareIcon className="h-3 w-3" />
        <p className="text-xs">Forked</p>
        <p className="text-xs">{agentForkCount || 0}</p>
      </div>
      <div className="flex h-fit flex-row items-center justify-evenly gap-1 rounded-md bg-skin-fill p-1 text-skin-base">
        <ChatBubbleWithWritingIcon className="h-3 w-3" />
        <p className="text-xs">Threads</p>
        <p className="text-xs">{clientAgentCount || 0}</p>
      </div>
    </div>
  );
}

export default AgentHeaderPills;
