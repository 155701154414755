import { ReactNode } from "react";

function AgentToolItemHeader({
  icon,
  name,
  description,
}: {
  icon: ReactNode;
  name: string;
  description: string;
}) {
  return (
    <div className="relative flex w-full flex-col items-center justify-between gap-4 space-x-6 px-2 py-4">
      <div className="grid w-full grid-cols-3 justify-items-center">
        <div className="h-fit w-fit rounded-md bg-skin-accentSecondary p-1 text-skin-inverse">
          {icon}
        </div>
        <div className="col-span-2 flex items-center space-x-3">
          <h3 className="text-md text-wrap font-medium text-skin-base">
            {name}
          </h3>
        </div>
      </div>
      <p className="mt-1 text-wrap text-sm text-skin-muted">{description}</p>
    </div>
  );
}

export default AgentToolItemHeader;
