/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { ToolRow } from "../../../api/tool.type";
import SecondaryButton from "../../shared/buttons/SecondaryButton";
import SubmitButton from "../../shared/buttons/SubmitButton";
import AddButton from "../../shared/forms/AddButton";
import TableWithSearch, {
  TableHeadItem,
} from "../../shared/table/TableWithSearch";
import { handlePageNav } from "../../shared/table/handlePagination";
import { handleFilterValue } from "../../shared/table/setTableHeadWithFilters";
import ExtractionParametersList from "./ExtractionParametersList";
import { useExtractionParametersTableContext } from "./ExtractionParametersTableContext";

const baseTableHead = [
  { label: "ID" },
  { label: "Type" },
  { label: "Question" },
  { label: "Required" },
  { label: "Edit" },
  { label: "Remove" },
];

function ExtractionParametersTable() {
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage] = useState(10);

  const [filteredTools, setFilteredTools] = useState<Partial<ToolRow>[]>();

  const { handleSubmit, onSubmit, append, fields, isDirty, reset } =
    useExtractionParametersTableContext();

  const [tableHead] = useState<TableHeadItem[]>(baseTableHead);

  function baseFilter() {
    return (tool: Partial<ToolRow>, index: number) => index < 10;
  }

  const [setToolFilterFunction] =
    useState<(tool: Partial<ToolRow>, index: number) => boolean>(baseFilter);

  const rowCount =
    filteredTools && filteredTools.length > 0 ? filteredTools.length : null;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="max-w-full gap-4">
        {fields && fields.length > 0 && (
          <TableWithSearch
            tableHead={tableHead}
            rowsPerPage={10}
            rowCount={rowCount}
            currentPage={currentPage}
            handleFilterValue={(value) =>
              handleFilterValue(
                tableHead,
                value,
                fields,
                setFilteredTools,
                setToolFilterFunction,
                baseFilter,
              )
            }
            handleNextPage={() =>
              handlePageNav({
                currentPage,
                pageSetFunction: setCurrentPage,
                rowsPerPage,
                setFilterFunction: setToolFilterFunction,
                advancePage: true,
              })
            }
            handlePreviousPage={() =>
              handlePageNav({
                currentPage,
                pageSetFunction: setCurrentPage,
                rowsPerPage,
                setFilterFunction: setToolFilterFunction,
                advancePage: false,
              })
            }
            headerComponent={
              <div className="flex flex-col gap-2 p-2">
                <div>
                  <AddButton
                    iconClass="h-4 w-4"
                    onClick={() => {
                      append({
                        questionId: (fields.length + 1).toString(),
                        type: "text",
                        question: "",
                        required: false,
                      });
                    }}
                  >
                    Add question
                  </AddButton>
                </div>
                <div>
                  {isDirty && (
                    <div className="flex flex-row gap-2">
                      <div className="flex min-w-20">
                        <SecondaryButton
                          onClick={(e) => {
                            e.preventDefault();
                            reset();
                          }}
                        >
                          Cancel
                        </SecondaryButton>
                      </div>
                      <div className="flex min-w-20">
                        <SubmitButton
                          type="submit"
                          onClick={(e) => {
                            handleSubmit(onSubmit);
                          }}
                        >
                          Save
                        </SubmitButton>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            }
          >
            <ExtractionParametersList />
          </TableWithSearch>
        )}
      </div>
    </form>
  );
}

export default ExtractionParametersTable;
