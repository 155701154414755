/* eslint-disable eqeqeq */
import { Fragment } from "react";

import { ExtractionResponseRow } from "../../../api/extractionResponse.type";
import { ExtractionResponsesViewRow } from "./ExtractionResponsesViewRow";

function ExtractionResponsesList({
  extractionResponses,
  filterFunction,
}: {
  extractionResponses: ExtractionResponseRow[] | undefined;
  filterFunction?: (
    tool: Partial<ExtractionResponseRow>,
    index: number,
  ) => boolean;
}) {
  return (
    <Fragment>
      {extractionResponses &&
        extractionResponses
          .filter((er, index) => {
            if (filterFunction) {
              return filterFunction(er, index);
            } else {
              return true;
            }
          })
          .map((item, index) => {
            return <ExtractionResponsesViewRow key={index} item={item} />;
          })}
    </Fragment>
  );
}

export default ExtractionResponsesList;
