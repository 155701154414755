/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useRef } from "react";
import SubmitButton from "../shared/buttons/SubmitButton";

import Nango, { ConnectUI } from "@nangohq/frontend";
import { useMutation } from "react-query";
import { getSession } from "../../api/nango.api";
import { useToolContext } from "../tools/hooks/ToolContext";

const apiURL = process.env.REACT_APP_NANGO_HOST ?? "https://api.nango.dev";
const nango = new Nango({ host: apiURL, publicKey: "empty" });

function AuthenticateTool({
  nangoId,
  agentToolId,
}: {
  nangoId?: string;
  agentToolId?: string;
}) {
  const connectUI = useRef<ConnectUI>();

  const { mutate: getNangoSession } = useMutation(getSession);

  const { saveConnectionId } = useToolContext();

  useEffect(() => {
    getNangoSession({
      nango_id: nangoId,
    });
  }, []);

  function connect() {
    connectUI.current = nango.openConnectUI({
      onEvent: (event) => {
        if (event.type === "close") {
          // we refresh on close so user can see the diff
          // void queryClient.refetchQueries({ queryKey: ["connections"] });
        } else if (event.type === "connect") {
          saveConnectionId({
            connectionId: event.payload.connectionId,
            agentToolId,
          });
        }
      },
    });

    // We defer the token creation so the iframe can open and display a loading screen
    setTimeout(async () => {
      const res = await getSession({
        nango_id: nangoId,
      });
      connectUI.current!.setSessionToken(res);
    }, 10);
  }

  return <SubmitButton onClick={connect}>Connect</SubmitButton>;
}

export default AuthenticateTool;
