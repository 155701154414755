/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import {
  UseMutateFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import {
  createClientAgentWithExistingLead,
  createClientAgentWithNewLead,
  getClientAgentById,
  getClientAgentsByCompany,
  getClientAgentsByClientId,
} from "../../../api/clientAgent.api";
import { ClientAgentRow } from "../../../api/clientAgent.type";
import { useClientContext } from "./ClientContext";
import { useAlertContext } from "../../shared/alert/alertContext";
import { AgentType } from "../../../api/agent.type";
import { useParams } from "react-router-dom";
import { useAuthContext } from "./AuthContext";

export const ClientAgentContextWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { threadId } = useParams();
  const { companyId } = useAuthContext();

  const { setGlobalLoading, setAlertProps } = useAlertContext();

  const [selectedAgentType, setSelectedAgentType] = useState<AgentType | "all">(
    "all",
  );

  const queryClient = useQueryClient();

  const { client } = useClientContext();

  // These are clientAgents that match the user's company. These are the agents that should be fully accessible to the user.
  const {
    data: companyClientAgents,
    isLoading: isLoadingClientAgents,
    refetch: refetchCompanyClientAgents,
  } = useQuery({
    queryFn: async () => await getClientAgentsByCompany(companyId as string),
    queryKey: ["companyClientAgents", companyId],
    enabled: companyId ? true : false,
    onSettled: () => {
      setGlobalLoading(false);
    },
  });

  // These are the client agents that match the current client. These will have overlapping data with the companyClientAgents. However, they will include clientAgents that were created by an action and not the agent will not be fully accessible to the user without forking.
  const {
    data: clientAgents,
    isLoading,
    refetch: refetchClientAgents,
  } = useQuery({
    queryFn: async () => await getClientAgentsByClientId(client?.id as string),
    queryKey: ["clientAgents", client?.id],
    enabled: client?.id ? true : false,
    onSettled: () => {
      setGlobalLoading(false);
    },
  });

  const { data: activeClientAgent, isLoading: activeClientAgentLoading } =
    useQuery({
      queryFn: async () => await getClientAgentById(threadId as string),
      queryKey: ["activeClientAgent", threadId],
      enabled: threadId ? true : false,
      onSettled: () => {
        setGlobalLoading(false);
      },
    });

  const refetchData = () => {
    refetchCompanyClientAgents();
    refetchClientAgents();
  };

  const [newClientAgent, setNewClientAgent] = useState<ClientAgentRow>();

  // This uses the exiting client and should be used in cases where there isn't a user yet. This should be used sparingly as it can cause confusion if the same client is used for multiple agents.
  const { mutate: createClientAgent, isLoading: newClientAgentLoading } =
    useMutation(createClientAgentWithExistingLead, {
      onSuccess: async (res) => {
        setNewClientAgent(res);
        queryClient.invalidateQueries("clientAgents");
        queryClient.invalidateQueries("companyAgents");
      },
      onError(error: Error) {
        setAlertProps({
          message: error.message,
          color: "red",
        });
      },
      onSettled: () => {
        setTimeout(() => {
          setNewClientAgent(undefined);
        }, 1000);
      },
    });

  // The creates a new client each time and should be used if the users is logged in
  const { mutate: createNewClientAgent, isLoading: loading } = useMutation(
    createClientAgentWithNewLead,
    {
      onSuccess: async (res) => {
        setNewClientAgent(res);
        queryClient.invalidateQueries("clientAgents");
        queryClient.invalidateQueries("companyAgents");
      },
      onError(error: Error) {
        setAlertProps({
          message: error.message,
          color: "red",
        });
      },
      onSettled: () => {
        setTimeout(() => {
          setNewClientAgent(undefined);
        }, 1000);
      },
    },
  );

  useEffect(() => {
    setGlobalLoading(newClientAgentLoading);
  }, [newClientAgentLoading]);

  const value = {
    companyClientAgents,
    clientAgents,
    activeClientAgent,
    loading:
      isLoading ||
      newClientAgentLoading ||
      loading ||
      isLoadingClientAgents ||
      activeClientAgentLoading,
    setGlobalLoading,
    createClientAgent,
    refetchData,
    newClientAgent,
    createNewClientAgent,
    selectedAgentType,
    setSelectedAgentType,
  };

  return (
    <ClientAgentContext.Provider value={value}>
      {children}
    </ClientAgentContext.Provider>
  );
};

export const ClientAgentContext = createContext({
  clientAgents: {} as ClientAgentRow[] | undefined,
  companyClientAgents: {} as ClientAgentRow[] | undefined,
  activeClientAgent: {} as ClientAgentRow | null | undefined,
  loading: false,
  createClientAgent: {} as UseMutateFunction<
    ClientAgentRow,
    Error,
    {
      agentId: string;
      clientId: string;
    },
    unknown
  >,
  refetchData: {} as () => void,
  newClientAgent: {} as ClientAgentRow | undefined,
  createNewClientAgent: {} as UseMutateFunction<
    ClientAgentRow,
    Error,
    {
      agentId: string;
    },
    unknown
  >,
  selectedAgentType: "all" as AgentType | "all",
  setSelectedAgentType: {} as React.Dispatch<
    React.SetStateAction<AgentType | "all">
  >,
});

export const useClientAgentContext = () => useContext(ClientAgentContext);
