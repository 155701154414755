/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ExtractionResponseRow } from "../../../api/extractionResponse.type";
import SubmitButton from "../../shared/buttons/SubmitButton";
import TableWithSearch, {
  TableHeadItem,
} from "../../shared/table/TableWithSearch";
import { handlePageNav } from "../../shared/table/handlePagination";
import {
  handleFilterValue,
  setHeadFilters,
} from "../../shared/table/setTableHeadWithFilters";
import ExtractionResponsesList from "./ExtractionResponsesList";

const baseTableHead = [
  { label: "ID" },
  { label: "Created" },
  { label: "Client ID", columnName: "client" },
  { label: "Type" },
  { label: "Question" },
  { label: "Answer" },
];

function ExtractionResponsesTable({
  extractionResponses,
}: {
  extractionResponses: ExtractionResponseRow[] | undefined;
}) {
  const [currentPage, setCurrentPage] = useState(0);

  const [searchParams] = useSearchParams();

  const clientId = searchParams.get("clientId");

  const [filteredResponses, setFilteredResponses] = useState<
    ExtractionResponseRow[]
  >(extractionResponses ? extractionResponses : []);

  const rowsPerPage = 20;

  const [tableHead, setTableHead] = useState<TableHeadItem[]>(baseTableHead);

  function baseFilter() {
    return (er: Partial<ExtractionResponseRow>, index: number) =>
      index < rowsPerPage;
  }

  const [extractionFilterFunction, setExtractionFilterFunction] =
    useState<(tool: Partial<ExtractionResponseRow>, index: number) => boolean>(
      baseFilter,
    );

  const rowCount =
    extractionResponses && extractionResponses.length > 0
      ? extractionResponses.length
      : null;

  useEffect(() => {
    if (extractionResponses && clientId) {
      setFilteredResponses(
        extractionResponses.filter((er) => er.client_id === clientId),
      );
    }
  }, [extractionResponses, clientId]);

  useEffect(() => {
    if (extractionResponses) {
      setHeadFilters(
        extractionResponses,
        filteredResponses,
        setFilteredResponses,
        setTableHead,
        baseTableHead,
      );
    }
  }, [extractionResponses, clientId]);

  return (
    <div className="max-w-full gap-4">
      {!filteredResponses || filteredResponses.length === 0 ? (
        <div className="flex h-96 flex-col items-center justify-center gap-4">
          <p className="text-skin-muted">
            No responses found {clientId ? `for client ID ${clientId}` : ""}.
          </p>
          {clientId &&
            extractionResponses &&
            extractionResponses.length > 0 && (
              <div>
                <SubmitButton
                  onClick={() => {
                    searchParams.delete("clientId");

                    if (extractionResponses) {
                      setFilteredResponses(extractionResponses);
                    }
                  }}
                >
                  Show All Responses
                </SubmitButton>
              </div>
            )}
        </div>
      ) : null}

      {filteredResponses && filteredResponses.length > 0 && (
        <TableWithSearch
          tableHead={tableHead}
          rowsPerPage={rowsPerPage}
          rowCount={rowCount}
          currentPage={currentPage}
          handleFilterValue={(value) =>
            handleFilterValue(
              tableHead,
              value,
              extractionResponses,
              setFilteredResponses,
              setExtractionFilterFunction,
              baseFilter,
            )
          }
          handleNextPage={() =>
            handlePageNav({
              currentPage,
              pageSetFunction: setCurrentPage,
              rowsPerPage,
              setFilterFunction: setExtractionFilterFunction,
              advancePage: true,
            })
          }
          handlePreviousPage={() =>
            handlePageNav({
              currentPage,
              pageSetFunction: setCurrentPage,
              rowsPerPage,
              setFilterFunction: setExtractionFilterFunction,
              advancePage: false,
            })
          }
        >
          <ExtractionResponsesList
            extractionResponses={filteredResponses}
            filterFunction={extractionFilterFunction}
          />
        </TableWithSearch>
      )}
    </div>
  );
}

export default ExtractionResponsesTable;
