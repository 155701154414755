import {
  CogIcon,
  UserCircleIcon,
  WrenchIcon,
} from "@heroicons/react/24/outline";
import { Badge } from "@material-tailwind/react";
import IconButton from "../../buttons/IconButton";
import { useAgentToolContext } from "../../../fabrk/hooks/AgentToolContext";
import { useMessageContext } from "../../../fabrk/hooks/MessageContext";
import { useClientAgentContext } from "../../../fabrk/hooks/ClientAgentContext";
import { useEventStreaming } from "../../../message/StreamMessageContext";
import { useAgentAgentToolContext } from "../../../agent/hooks/AgentAgentToolContext";

function TextAreaToolBadge({
  toggleTools,
  toggleSettings,
}: {
  toggleTools: () => void;
  toggleSettings: () => void;
}) {
  const { agentTools } = useAgentToolContext();
  const { activeClientAgent } = useClientAgentContext();
  const { startStreamingPersona, personaStreaming, streaming, cycles } =
    useEventStreaming();
  const { agentAgentTools } = useAgentAgentToolContext();

  const { persona, setCounter, loading } = useMessageContext();

  function startCycles() {
    setCounter(Number(cycles));
    startStreamingPersona(
      activeClientAgent?.id as string,
      persona?.id as string,
    );
  }

  return (
    <div className="flex w-3/4 max-w-4xl justify-end gap-1">
      {persona && !personaStreaming && !streaming && !loading && (
        <IconButton onClick={startCycles}>
          <UserCircleIcon className="h-6 w-6" /> {cycles} more cycles
        </IconButton>
      )}
      <IconButton onClick={toggleSettings}>
        <CogIcon className="h-6 w-6" />
      </IconButton>
      <Badge
        className="bg-skin-accent p-1"
        content={(agentTools?.length || 0) + (agentAgentTools?.length || 0)}
      >
        <IconButton onClick={toggleTools}>
          <WrenchIcon className="h-6 w-6" />
        </IconButton>
      </Badge>
    </div>
  );
}

export default TextAreaToolBadge;
