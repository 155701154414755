import { Link } from "react-router-dom";
import {
  formatTimeStampToHumanReadableShortDateTime,
  yearMonthDayToMonthDayYear,
} from "../../../utils/parseTimeStamp";

function DiscoverListItemHeader({
  createdAt,
  name,
  link,
  imagePath,
  children,
}: {
  createdAt: string;
  name: string;
  link: string;
  imagePath?: string;
  children?: React.ReactNode;
}) {
  return (
    <div className="w-full">
      <Link to={link}>
        <div className="flex flex-col justify-between gap-8">
          <div className="aspect-h-4 aspect-w-3 sm:aspect-none bg-skin-accentSecondary group-hover:opacity-75 sm:h-40">
            <img
              alt={name}
              src={imagePath || "/images/woven-tech.png"}
              className="h-full w-full object-cover object-center sm:h-full sm:w-full"
            />
          </div>
          <div className="flex flex-row items-center justify-between gap-x-4">
            <div className="flex flex-row items-center gap-2">
              <div className="flex flex-grow flex-col gap-2">
                <h3 className="text-lg font-semibold leading-6 text-skin-base group-hover:text-skin-hover">
                  <span />
                  {name}
                </h3>

                <time
                  dateTime={formatTimeStampToHumanReadableShortDateTime(
                    createdAt,
                  )}
                  className="flex-shrink-0 text-xs text-skin-base"
                >
                  {yearMonthDayToMonthDayYear(createdAt)}
                </time>
              </div>
            </div>
            {children}
          </div>
        </div>
      </Link>
    </div>
  );
}

export default DiscoverListItemHeader;
