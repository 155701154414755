import { Fragment, useEffect, useState } from "react";
import { ExtractionParametersEditRow } from "./ExtractionParametersEditRow";
import { ParameterField } from "./ExtractionParametersList";
import { ExtractionParametersViewRow } from "./ExtractionParametersViewRow";

export type ParametersRowProps = {
  item: ParameterField;
  index: number;
};

export type ParametersRowPropsWithEdit = ParametersRowProps & {
  isEdit: boolean;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
};

function ExtractionParametersRowContainer({
  item,

  index,
}: ParametersRowProps) {
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (item) {
      setIsEdit(false);
    }

    if (item.question === "") {
      setIsEdit(true);
    }
  }, [item]);

  return (
    <Fragment>
      {isEdit ? (
        <ExtractionParametersEditRow
          index={index}
          setIsEdit={setIsEdit}
          isEdit={isEdit}
          item={item}
        />
      ) : (
        <ExtractionParametersViewRow
          index={index}
          setIsEdit={setIsEdit}
          isEdit={isEdit}
          item={item}
        />
      )}
    </Fragment>
  );
}

export default ExtractionParametersRowContainer;
