// +15555555555 -> (555) 555-5555
export function formatPhoneNumber(phoneNumber: string): string {
  if (!phoneNumber) return "";
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");
  const match = cleaned.match(/1?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return phoneNumber;
}

// (555) 555-5555 -> +15555555555
export function formatPhoneNumberToDbFormat(number: string) {
  if (!number) return "";
  // Remove all non-digit characters
  const digits = number.replace(/\D/g, "");

  // Check if the number is 10 digits
  if (digits.length !== 10) {
    console.log("The provided number is not 10 digits.");
    return "";
  }

  return `+1${digits}`;
}
