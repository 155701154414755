import LayoutContainer from "../../components/layout/LayoutContainer";
import CreateTool from "../../components/tools/CreateTool";
import { ToolContextWrapper } from "../../components/tools/hooks/ToolContext";
import { mixpanelTrackEvent } from "../../utils/mixpanel";

function CreateToolContainer() {
  mixpanelTrackEvent("Create Tool");
  return (
    <ToolContextWrapper>
      <LayoutContainer>
        <CreateTool />
      </LayoutContainer>
    </ToolContextWrapper>
  );
}

export default CreateToolContainer;
