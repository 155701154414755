import { Card, CardBody, Typography } from "@material-tailwind/react";

import ColumnFilter from "./ColumnFilter";
import TableFooter from "./TableFooter";

export type TabsType = {
  label: string;
  value: string;
};

export type TableHeadItem = {
  label: string;
  columnName?: string;
  filterValues?: string[];
};

export type FilterValue = {
  value: string;
  column: string;
};

export type CsvDownloadHeaders = {
  label: string;
  key: string;
};

export type DownloadData = {
  headers: CsvDownloadHeaders[];
  csvData: any[];
  fileName: string;
};

export type TableWithSearchProps = {
  tableHead: TableHeadItem[];
  children: React.ReactNode;
  rowCount?: number | null;
  rowsPerPage: number;
  currentPage?: number;
  handleNextPage?: () => void;
  handlePreviousPage?: () => void;
  handleFilterValue?: (value: FilterValue) => void;
  isLoading?: boolean;
  headerComponent?: React.ReactNode;
};

export default function TableWithSearch({
  tableHead,
  children,
  rowCount,
  handleNextPage,
  handlePreviousPage,
  rowsPerPage = 10,
  currentPage = 0,
  handleFilterValue,
  isLoading,
  headerComponent,
}: TableWithSearchProps) {
  return (
    <div
      className="flex flex-col gap-4"
      style={{
        maxWidth: "100%",
      }}
    >
      <Card className="h-full border-2 border-skin-hover/50 bg-skin-fill text-skin-base">
        {headerComponent}
        <CardBody className="px-0">
          <table className="min-w-full max-w-full table-auto text-left">
            <thead>
              <tr>
                {tableHead.map((head, index) => (
                  <th
                    key={head.label}
                    className="cursor-pointer border-y border-skin-accentSecondary bg-skin-fill p-4 transition-colors hover:bg-skin-hover"
                  >
                    <Typography
                      variant="small"
                      className="flex items-center justify-between gap-2 font-normal leading-none text-skin-base"
                    >
                      {head.label}{" "}
                      {index !== tableHead.length - 1 && head.filterValues && (
                        <ColumnFilter
                          head={head}
                          handleFilterValue={handleFilterValue}
                        />
                      )}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>{children}</tbody>
          </table>
        </CardBody>
        {rowCount && rowCount > 9 && (
          <TableFooter
            rowCount={rowCount}
            rowsPerPage={rowsPerPage}
            currentPage={currentPage}
            handleNextPage={handleNextPage && handleNextPage}
            handlePreviousPage={handlePreviousPage && handlePreviousPage}
            isLoading={isLoading}
          />
        )}
      </Card>
    </div>
  );
}
