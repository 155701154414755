import React from "react";

// Function to detect if a line is a header (based on "schema name:")
const isHeader = (line: string) => line.startsWith("schema name:");

// Function to detect if a line is a question (based on "question_id:")
const isQuestion = (line: string) =>
  line.startsWith("question_id:") || line.startsWith("question_name:");

// Main formatting function
const FormatContext = (text: string) => {
  // Split the text into sections based on known keywords
  const sections = text.split(/\n(?=schema name:|question_id:)/g);

  if (sections.length === 1) {
    return <p>{text}</p>;
  }

  return sections.map((section, index) => {
    if (sections.length === 1) {
      return <p>{text}</p>;
    }

    const lines = section?.trim().split("\n");

    if (!lines) {
      return null;
    }

    // If the first line of the section is a header, display it as a header
    if (isHeader(lines[0])) {
      return (
        <div key={index} style={{ marginBottom: "1em" }}>
          <h2>{lines[0].replace("schema name:", "").trim()}</h2>
          <ul>
            {lines
              ?.slice(1)
              .map((line, idx) => <li key={idx}>{line.trim()}</li>)}
          </ul>
        </div>
      );
    }

    // If the section contains questions, display them as list items
    if (lines?.some(isQuestion)) {
      return (
        <div key={index} style={{ marginBottom: "1em" }}>
          <ul>
            {lines.map((line, idx) => (
              <li key={idx}>{line.trim()}</li>
            ))}
          </ul>
        </div>
      );
    }

    // Otherwise, treat the section as a paragraph
    return (
      <p key={index} style={{ marginBottom: "1em" }}>
        {section?.trim()}
      </p>
    );
  });
};

const ReadableTextFormatter: React.FC<{ text: string }> = ({ text }) => {
  return <div>{FormatContext(text)}</div>;
};

export default ReadableTextFormatter;
