/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useAuthContext } from "../fabrk/hooks/AuthContext";

declare global {
  interface Window {
    google?: any;
  }
}

const id = process.env.REACT_APP_GOOGLE_CLIENT_ID as string;

const GoogleSignIn = ({
  viewType,
}: {
  viewType: "Log In" | "Sign Up" | "Reset Password";
}) => {
  const { handleCredentialResponse } = useAuthContext();

  useEffect(() => {
    // Load the Google's GSI client library script dynamically
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      // Initialize Google Sign-In
      window.google?.accounts.id.initialize({
        client_id: id + ".apps.googleusercontent.com", // Replace with your client ID
        callback: handleCredentialResponse,
      });

      // Render the Google Sign-In button
      window.google?.accounts.id.renderButton(
        document.getElementById("buttonDiv"),
        {
          theme: "outline",
          size: "large",
          text: viewType === "Log In" ? "signin_with" : "signup_with",
        }, // Customization attributes
      );

      // Display the One Tap dialog
      window.google?.accounts.id.prompt();
    };

    // Cleanup the script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, [window]);

  return <div id="buttonDiv" style={{ borderRadius: "40px" }}></div>;
};

export default GoogleSignIn;
