import { useEffect, useState } from "react";

import { CopyToClipboardIcon, SmallCheckMark } from "../icons/icons";

/* 

You can add state to the location object in react router dom like this. This will create a location that will be listed in the breadcrumbs. This will allow the use to go back to the previous page.

navigate(`/client/${activeReimbursement?.client_id}`, {
  state: {
    name: "Reimbursement",
    href: location.pathname,
    active: false,
  },
  */

export function CopyToClipboard({ text }: { text: string }) {
  const [copied, setCopied] = useState(false);
  const handleCopyClick = () => {
    navigator.clipboard.writeText(text);
    setCopied(true);
  };

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);
  return (
    <button className="text-primary-500" onClick={handleCopyClick}>
      {copied ? "Copied!" : "Copy to clipboard"}
    </button>
  );
}

export function CopyToClipboardIconButton({
  text,
  className,
}: {
  text: string;
  className?: string;
}) {
  const [copied, setCopied] = useState(false);
  const handleCopyClick = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    navigator.clipboard.writeText(text);
    setCopied(true);
  };

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);
  return (
    <button onClick={handleCopyClick} className={className}>
      {copied ? (
        <SmallCheckMark className="h-4 w-4 text-skin-accent" />
      ) : (
        <CopyToClipboardIcon className="h-4 w-4 text-skin-accent" />
      )}
    </button>
  );
}
