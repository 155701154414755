import { Link } from "react-router-dom";
import EvaluationTable from "../../evaluation/EvaluationTable";
import SubmitButton from "../../shared/buttons/SubmitButton";
import { useAgentContext } from "../hooks/AgentContext";
import { useEvalsToolContext } from "../hooks/EvalsContext";

function AgentEvalsContainer() {
  const { activeAgent } = useAgentContext();

  const { enableEvals, hasEvalAgent, evaluations, evalParamLink } =
    useEvalsToolContext();

  return (
    <div>
      {hasEvalAgent ? (
        <div className="flex flex-col gap-4">
          <div className="rounded-lg border-2 border-skin-hover/50 p-2">
            {evalParamLink && (
              <Link to={`/tool/${evalParamLink}`}>
                Link to evaluation parameters
              </Link>
            )}
            {(!evaluations || evaluations?.length === 0) && (
              <p>
                No evaluations found. Send a message to the agent to trigger
                evaluations.
              </p>
            )}
          </div>
          {evaluations && evaluations.length > 0 && <EvaluationTable />}
        </div>
      ) : (
        <div className="max-w-lg">
          <SubmitButton onClick={() => enableEvals(activeAgent?.id as string)}>
            Enable Evals
          </SubmitButton>
        </div>
      )}
    </div>
  );
}

export default AgentEvalsContainer;
