import { useViewClientContext } from "./hooks/ViewClientContext";

function ViewClient() {
  const { activeClient } = useViewClientContext();

  return (
    <div>
      <h1>Client Id: {activeClient?.id}</h1>
    </div>
  );
}

export default ViewClient;
