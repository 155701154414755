/* eslint-disable eqeqeq */
import { Fragment } from "react";

import { CredentialsRow } from "../../../api/credentials.type";
import { useCredentialsContext } from "../hooks/CredentialsContext";
import { CredentialEditRow } from "./CredentialEditRow";
import { CredentialViewRow } from "./CredentialViewRow";

function CredentialsList({
  credentials,
  filterFunction,
}: {
  credentials: CredentialsRow[] | undefined;
  filterFunction?: (tool: Partial<CredentialsRow>, index: number) => boolean;
}) {
  const { fields, setIsEdit, isEdit } = useCredentialsContext();

  return (
    <Fragment>
      {!isEdit &&
        credentials &&
        credentials
          .filter((er, index) => {
            if (filterFunction) {
              return filterFunction(er, index);
            } else {
              return true;
            }
          })
          .map((item, index) => {
            return (
              <CredentialViewRow
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                key={index}
                credential={item}
                index={index}
              />
            );
          })}
      {isEdit &&
        fields.map((item, index) => {
          console.log("item", item);
          return (
            <CredentialEditRow
              key={index}
              item={item}
              index={index}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
            />
          );
        })}
    </Fragment>
  );
}

export default CredentialsList;
