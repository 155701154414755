import ViewToolHeaderDetails from "./ViewToolHeaderDetails";
import ViewToolHeaderImg from "./ViewToolHeaderImg";

function ViewToolHeader() {
  return (
    <div className="max-w-7xl px-6 lg:px-8">
      <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-12 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        <ViewToolHeaderImg />
        <ViewToolHeaderDetails />
      </div>
    </div>
  );
}

export default ViewToolHeader;
