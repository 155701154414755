/* eslint-disable react-hooks/exhaustive-deps */
import ExtractionParametersTable from "../extraction/parameters/ExtractionParametersTable";
import { ExtractionParametersTableContextWrapper } from "../extraction/parameters/ExtractionParametersTableContext";

function ExtractionParametersForm() {
  return (
    <div>
      <ExtractionParametersTableContextWrapper>
        <ExtractionParametersTable />
      </ExtractionParametersTableContextWrapper>
    </div>
  );
}

export default ExtractionParametersForm;
