export function handlePageNav({
  pageSetFunction,
  currentPage,
  rowsPerPage,
  setFilterFunction,
  advancePage,
}: {
  pageSetFunction: any;
  currentPage: number;
  rowsPerPage: number;
  setFilterFunction: any;
  advancePage: boolean;
}) {
  const direction = advancePage ? 1 : -1;

  pageSetFunction((currentPage: number) => currentPage + direction);
  setFilterFunction(() => (_lead: any, index: number) => {
    // Calculate the starting index of the next page
    const startIndex = (currentPage + direction) * rowsPerPage;

    // Calculate the ending index of the next page
    const endIndex = startIndex + rowsPerPage;

    // Return true for the items within the range of the next page
    return index >= startIndex && index < endIndex;
  });
}
