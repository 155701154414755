import { MessageRow } from "../../../api/message.type";
import { ActionContextWrapper } from "../../action/ActionContext";
import AssistantMessageChatItem from "./AssistantMessageChatItem";
import UserMessageChatItem from "./UserMessageChatItem";

function UserAndAssistantMessages({
  messages,
  menuType,
}: {
  messages: MessageRow[] | undefined;
  menuType: string;
}) {
  return (
    <div className="flex flex-col gap-8">
      {messages?.map((message, index) => {
        if (message.role === "user") {
          return (
            <div key={index}>
              <ActionContextWrapper>
                <UserMessageChatItem message={message} />
              </ActionContextWrapper>
            </div>
          );
        }
        if (message.role === "assistant") {
          return (
            <div key={index} className="flex flex-row">
              <AssistantMessageChatItem
                message={message}
                showMetadata={menuType === "meta"}
                userMessageId={messages[index - 1]?.id}
              />
            </div>
          );
        }
        return null;
      })}
    </div>
  );
}

export default UserAndAssistantMessages;
