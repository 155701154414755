import { useSession, useSupabaseClient } from "@supabase/auth-helpers-react";
import { useForm } from "react-hook-form";
import { useAuthContext } from "../fabrk/hooks/AuthContext";
import { useAlertContext } from "../shared/alert/alertContext";
import SubmitButton from "../shared/buttons/SubmitButton";
import { ErrorHelperText } from "../shared/forms/ErrorHelperText";
import { FabrkInput } from "../shared/forms/input/InputBase";

function ResetPassword() {
  const { loginError } = useAuthContext();
  const supabase = useSupabaseClient();
  const session = useSession();
  const { setAlertProps } = useAlertContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm({
    defaultValues: {
      password: "",
      newPassword: "",
      repeatPassword: "",
    },
  });

  async function onSubmit(data: {
    password: string;
    newPassword: string;
    repeatPassword: string;
  }) {
    if (session?.user) {
      await supabase.auth
        .updateUser({ password: data.newPassword })
        .then((res) => {
          if (res.error) {
            setError("repeatPassword", {
              type: "manual",
              message: res.error.message,
            });
          } else {
            setAlertProps({
              message: "Password updated successfully",
              color: "green",
            });
            reset();
          }
        })
        .catch((error) => {
          setError("repeatPassword", {
            type: "manual",
            message: error.message,
          });
        });
    }
  }

  return (
    <div className="mt-5 h-fit min-h-screen max-w-lg rounded-lg bg-skin-fill p-8">
      {loginError && (
        <div className="mb-5">
          <ErrorHelperText text={loginError} />
        </div>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-5">
          <FabrkInput
            register={register}
            errors={errors}
            label="New Password"
            type="password"
            name="newPassword"
          />

          <FabrkInput
            register={register}
            errors={errors}
            label="Confirm Password"
            type="password"
            name="repeatPassword"
          />

          <SubmitButton type="submit">Update Password</SubmitButton>
        </div>
      </form>
    </div>
  );
}

export default ResetPassword;
