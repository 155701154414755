import { useSession } from "@supabase/auth-helpers-react";
import ResetPassword from "../../components/auth/ResetPassword";
import LayoutContainer from "../../components/layout/LayoutContainer";
import { PageHeader } from "../../components/shared/containers/Containers";
import TabContainer from "../../components/shared/containers/Tabs";
import {
  DataValueBase,
  LabelBase,
} from "../../components/shared/typography/TypographyBase";
import { mixpanelTrackEvent } from "../../utils/mixpanel";

const tabs = [
  { name: "Overview", href: "#overview", current: false },
  { name: "Password", href: "#password", current: true },
];

function ViewUserContainer() {
  mixpanelTrackEvent("View User Settings");
  const session = useSession();

  return (
    <LayoutContainer>
      <div className="ml-10 h-full min-h-screen bg-skin-fill py-4 md:py-10">
        <PageHeader title="User Settings" />
        <TabContainer tabs={tabs}>
          {(activeTab: string) => (
            <>
              {activeTab === "Overview" && (
                <div>
                  <LabelBase>Email</LabelBase>
                  <DataValueBase>{session?.user?.email}</DataValueBase>
                </div>
              )}
              {activeTab === "Password" && <ResetPassword />}
            </>
          )}
        </TabContainer>
      </div>
    </LayoutContainer>
  );
}

export default ViewUserContainer;
