import { Menu, MenuButton, MenuItems } from "@headlessui/react";

export default function DropdownMenu({
  className,
  MenuButtonChild,
  children,
}: {
  className: string;
  MenuButtonChild: React.ReactNode;
  children: React.ReactNode;
}) {
  return (
    <Menu as="div" className="relative z-50 overflow-visible">
      <MenuButton className="w-full">{MenuButtonChild}</MenuButton>

      <MenuItems transition className={className}>
        {children}
      </MenuItems>
    </Menu>
  );
}
