export function FormDivider() {
  return (
    <div
      className="my-1 max-w-md bg-skin-base/10"
      style={{
        height: 1,
      }}
    ></div>
  );
}
