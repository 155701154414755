import { useAgentContext } from "../hooks/AgentContext";
import { EvalsContextWrapper } from "../hooks/EvalsContext";
import AgentContextAndEvals from "./AgentContextAndEvals";
import AgentDetails from "./AgentDetails";
import AgentMediumButtons from "./AgentMediumButtons";
import AgentModelButtons from "./AgentModelButtons";

function AgentSettingsContent() {
  const { activeAgent, isCompanyAgent } = useAgentContext();
  return (
    <div className="px-4 pb-5 pt-5 sm:px-0 sm:pt-0">
      <dl className="flex flex-col gap-4 px-4 sm:space-y-6 sm:px-6">
        <div>
          <dd className="mt-1 text-sm text-skin-base sm:col-span-2">
            <p>
              These are your agent settings. You can name your agent and give it
              instructions on how to respond.
            </p>
          </dd>
        </div>
        <div className="flex flex-col gap-8">
          <AgentDetails />
          <EvalsContextWrapper>
            <AgentContextAndEvals />
          </EvalsContextWrapper>
          {activeAgent?.visibility === "public" && isCompanyAgent && (
            <AgentMediumButtons />
          )}

          <AgentModelButtons />
        </div>
      </dl>
    </div>
  );
}

export default AgentSettingsContent;
