import { ThemeProvider } from "@material-tailwind/react";
import { SessionContextProvider } from "@supabase/auth-helpers-react";
import { createClient } from "@supabase/supabase-js";
import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { AlertContextWrapper } from "./components/shared/alert/alertContext";
import { AuthContextWrapper } from "./components/fabrk/hooks/AuthContext";
import { ClientContextWrapper } from "./components/fabrk/hooks/ClientContext";
import { DarkModeProvider } from "./components/fabrk/layout/hooks/useDarkMode";
import { CompanyContextWrapper } from "./components/fabrk/hooks/CompanyContext";
import { EventStreamingWrapper } from "./components/message/StreamMessageContext";
import { ExtractionSchemaContextWrapper } from "./components/fabrk/hooks/ExtractionSchemaContext";

const theme = {
  select: {
    styles: {
      base: {
        container: {
          position: "relative",
          width: "w-full",
          minWidth: "min-w-0",
        },
      },
    },
  },
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      notifyOnChangeProps: "tracked",
    },
  },
});

const url = process.env.REACT_APP_SUPABASE_URL as string;
const key = process.env.REACT_APP_SUPABASE_ANON_KEY as string;

const supabase = createClient(url, key);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider value={theme}>
        <QueryClientProvider client={queryClient}>
          <SessionContextProvider supabaseClient={supabase}>
            <AlertContextWrapper>
              <AuthContextWrapper>
                <ClientContextWrapper>
                  <DarkModeProvider>
                    <CompanyContextWrapper>
                      <EventStreamingWrapper>
                        <ExtractionSchemaContextWrapper>
                          <App />
                        </ExtractionSchemaContextWrapper>
                      </EventStreamingWrapper>
                    </CompanyContextWrapper>
                  </DarkModeProvider>
                </ClientContextWrapper>
              </AuthContextWrapper>
            </AlertContextWrapper>
          </SessionContextProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
