import { Typography } from "@material-tailwind/react";

function LoginFooterLink({ setSignup }: { setSignup: () => void }) {
  return (
    <Typography variant="small" className="text-skin-accent">
      Have an account?{" "}
      <span
        style={{
          cursor: "pointer",
        }}
        onClick={() => {
          setSignup();
        }}
      >
        Login
      </span>
    </Typography>
  );
}

export default LoginFooterLink;
