/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import {
  UseMutateFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import {
  createCampaignExtractionSchema,
  createExtractionSchema,
  deleteCampaignExtractionSchema,
  getExtractionSchemaAgentByTool,
  getExtractionSchemas,
  updateExtractionSchema,
} from "../../../api/extractionSchema.api";
import {
  CreateExtractionSchema,
  ExtractionSchemaAgentRow,
  ExtractionSchemaRow,
  QuestionTypes,
  SchemaBase,
} from "../../../api/extractionSchema.type";
import { useAlertContext } from "../../shared/alert/alertContext";
import { useToolContext } from "../../tools/hooks/ToolContext";
import {
  CreateCampaignExtractionSchema,
  CreateSchema,
  DeleteCampaignExtractionSchema,
} from "./ExtractionSchema.type";
import { useClientAgentContext } from "./ClientAgentContext";
import { useAuthContext } from "./AuthContext";

export const ExtractionSchemaContextWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const queryClient = useQueryClient();

  const { setAlertProps } = useAlertContext();

  const { companyId } = useAuthContext();

  const { refetchData } = useClientAgentContext();

  const { tool } = useToolContext();

  const { handleGetTool } = useToolContext();

  const startingSchema = [
    {
      id: "1",
      question: "What is your name?",
      required: true,
      type: QuestionTypes.TEXT,
    },
  ];

  const [activeSchema, setActiveSchema] =
    useState<SchemaBase[]>(startingSchema);

  const { data: schema, refetch } = useQuery({
    queryKey: "extractionSchema",
    queryFn: () => getExtractionSchemas(companyId as string),
    enabled: companyId ? true : false,
  });

  const [extractionSchemaAgents, setExtractionSchemaAgents] =
    useState<ExtractionSchemaAgentRow[]>();

  const { mutate: addSchemaToCampaign } = useMutation(
    createCampaignExtractionSchema,
    {
      onSuccess: (data) => {
        getToolSchema(tool?.id as string);
        refetchData();
        setAlertProps({
          message: "Schema added successfully",
          color: "green",
        });
      },
      onError: (error: any) => {
        setAlertProps({
          message: "There was an error fetching the schema: " + error.message,
          color: "red",
        });
      },
    },
  );

  const { mutate: getToolSchema, isLoading } = useMutation(
    getExtractionSchemaAgentByTool,
    {
      onSuccess: (data) => {
        setExtractionSchemaAgents(data);
      },
      onError: (error: any) => {
        setAlertProps({
          message: "There was an error fetching the schema: " + error.message,
          color: "red",
        });
      },
    },
  );

  const { mutate: removeFromCampaign } = useMutation(
    deleteCampaignExtractionSchema,
    {
      onSuccess: (data) => {
        refetch();
        refetchData();
        getToolSchema(tool?.id as string);
        setAlertProps({
          message: "Schema removed successfully",
          color: "green",
        });
      },
      onError: (error: any) => {
        setAlertProps({
          message: "There was an error deleting the schema: " + error.message,
          color: "red",
        });
      },
    },
  );

  const { mutate: createSchema } = useMutation(createExtractionSchema, {
    onSuccess: () => {
      queryClient.invalidateQueries("clientAgents");
      handleGetTool();
      refetch();
      refetchData();
      setAlertProps({
        message: "Schema created successfully!",
        color: "green",
      });

      if (tool) {
        getToolSchema(tool.id as string);
      }
    },
    onError: (error: any) => {
      setAlertProps({
        message: "There was an error creating the schema: " + error.message,
        color: "red",
      });
    },
  });

  const { mutate: updateSchema } = useMutation(updateExtractionSchema, {
    onSuccess: () => {
      handleGetTool();
      refetch();
      refetchData();
      setAlertProps({
        message: "Schema updated successfully!",
        color: "green",
      });

      if (tool?.id) {
        getToolSchema(tool.id as string);
      }
    },
    onError: (error: any) => {
      setAlertProps({
        message: "There was an error updating the schema: " + error.message,
        color: "red",
      });
    },
  });

  useEffect(() => {
    if (
      extractionSchemaAgents &&
      extractionSchemaAgents.length > 0 &&
      extractionSchemaAgents[0].extraction_schema?.schema
    ) {
      setActiveSchema(extractionSchemaAgents[0].extraction_schema?.schema);
    } else {
      setActiveSchema(startingSchema);
    }
  }, [extractionSchemaAgents, tool, isLoading]);

  useEffect(() => {
    if (tool && tool.id) {
      getToolSchema(tool.id as string);
    }
  }, [tool]);

  const value = {
    schema,
    createSchema,
    extractionSchemaAgents,
    addSchemaToCampaign,
    removeFromCampaign,
    loading: isLoading,
    updateSchema,
    activeSchema,
  };

  return (
    <ExtractionSchemaContext.Provider value={value}>
      {children}
    </ExtractionSchemaContext.Provider>
  );
};

export const ExtractionSchemaContext = createContext({
  schema: {} as ExtractionSchemaRow[] | undefined,
  createSchema: {} as CreateSchema,
  extractionSchemaAgents: {} as ExtractionSchemaAgentRow[] | undefined,
  addSchemaToCampaign: {} as CreateCampaignExtractionSchema,
  removeFromCampaign: {} as DeleteCampaignExtractionSchema,
  loading: false,
  updateSchema: {} as UseMutateFunction<
    ExtractionSchemaRow,
    any,
    CreateExtractionSchema,
    unknown
  >,
  activeSchema: [] as SchemaBase[],
});

export const useExtractionSchemaContext = () =>
  useContext(ExtractionSchemaContext);
