import { useState } from "react";

import SubmitButton from "../../shared/buttons/SubmitButton";
import { useAgentContext } from "../hooks/AgentContext";
import ViewAgentHeaderDetails from "./ViewAgentHeaderDetails";
import ViewAgentHeaderImg from "./ViewAgentHeaderImg";
import ViewAgentHeaderStats from "./ViewAgentHeaderStats";
import { FileUploadContextWrapper } from "../../shared/forms/hooks/fileUploadContext";

function ViewAgentHeader() {
  const [isEdit, setIsEdit] = useState(false);
  const { isCompanyAgent, handleFork } = useAgentContext();

  return (
    <div className="max-w-7xl px-6">
      <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-16 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        <div className="lg:pr-4">
          <FileUploadContextWrapper>
            <ViewAgentHeaderImg />
          </FileUploadContextWrapper>
          <ViewAgentHeaderStats />
        </div>
        <div className="col-span-2 flex flex-col gap-10">
          <ViewAgentHeaderDetails isEdit={isEdit} setIsEdit={setIsEdit} />
        </div>

        {!isCompanyAgent && (
          <SubmitButton onClick={handleFork}>Fork Agent</SubmitButton>
        )}
      </div>
    </div>
  );
}

export default ViewAgentHeader;
