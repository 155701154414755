/* eslint-disable react-hooks/exhaustive-deps */

import { useAgentContext } from "../agent/hooks/AgentContext";
import ActiveAgentThreadList from "../threads/ActiveAgentThreadList";
import ThreadList from "../threads/ThreadList";

function ThreadListContainer() {
  const { activeAgent } = useAgentContext();

  return (
    <div className="flex flex-col gap-4">
      {activeAgent && <ActiveAgentThreadList />}
      <ThreadList />
    </div>
  );
}

export default ThreadListContainer;
