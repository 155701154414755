/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useAlertContext } from "../../shared/alert/alertContext";

import { useMutation } from "react-query";
import { audioFileToMessage } from "../../../api/message.api";
import { useClientAgentContext } from "../../fabrk/hooks/ClientAgentContext";
import { useMessageContext } from "../../fabrk/hooks/MessageContext";

export const VoiceContextWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { setAlertProps } = useAlertContext();
  const { activeClientAgent } = useClientAgentContext();
  const { setNewMessage } = useMessageContext();
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);

  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState<string | null>(null);
  const [autoplayEnabled, setAutoplayEnabled] = useState(false); // Track autoplay permission

  const [audioContent, setAudioContent] = useState<string | null>(null);

  async function startRecording() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;
      audioChunksRef.current = [];

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: "audio/webm",
        });
        setAudioBlob(audioBlob);

        const audioURL = URL.createObjectURL(audioBlob);
        setAudioURL(audioURL);

        // Cleanup the stream
        stream.getTracks().forEach((track) => track.stop());
      };

      mediaRecorder.start();
      setIsRecording(true);
    } catch (error) {
      console.error("Error accessing microphone:", error);
      alert("Unable to access microphone. Please check your device settings.");
    }
  }

  function stopRecording() {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  }

  const { mutate: upload, isLoading: audioUploadLoading } = useMutation(
    audioFileToMessage,
    {
      onSuccess: async (res) => {
        setAudioContent(res.content);
        setNewMessage(res.message);
        setAudioBlob(null);
      },
      onError(error: Error) {
        console.log(error);
        setAlertProps({
          message: "Failed to send message" + error.message,
          color: "red",
        });
      },
    },
  );

  async function uploadRecording() {
    if (!audioBlob) {
      setAlertProps({
        message: "No recording to upload.",
        severity: "error",
      });
      return;
    }

    // const timestamp = new Date().toISOString().replace(/[:.]/g, "-"); // Create a timestamp
    const fileName = `recording.webm`; // Generate the file name

    upload({
      fileName,
      audioBlob,
      additionalData: {
        location: "recordings",
        clientAgentId: activeClientAgent?.id,
      },
    });
  }

  useEffect(() => {
    // Event listener for Control key
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.code === "ControlLeft" || event.code === "ControlRight") {
        event.preventDefault(); // Prevent any unintended default behavior
        if (isRecording) {
          stopRecording();
        } else {
          startRecording();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isRecording]);

  useEffect(() => {
    if (audioBlob) {
      uploadRecording();
    }
  }, [audioBlob]);

  const value = {
    startRecording,
    stopRecording,
    uploadRecording,
    isRecording,
    audioURL,
    audioContent,
    setAudioContent,
    audioUploadLoading,
    autoplayEnabled,
    setAutoplayEnabled,
  };

  return (
    <VoiceContext.Provider value={value}>{children}</VoiceContext.Provider>
  );
};

export const VoiceContext = createContext({
  startRecording: () => {},
  stopRecording: () => {},
  uploadRecording: () => {},
  isRecording: false,
  audioURL: null as string | null,
  audioContent: null as string | null,
  setAudioContent: (audioContent: string | null) => {},
  audioUploadLoading: false,
  autoplayEnabled: true,
  setAutoplayEnabled: (autoplayEnabled: boolean) => {},
});

export const useVoiceContext = () => useContext(VoiceContext);
