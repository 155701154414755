import { TrashIcon } from "@heroicons/react/24/outline";
import { Typography } from "@material-tailwind/react";
import { Controller } from "react-hook-form";
import { CreateScheduleRow } from "../../api/schedule.type";
import { formatTimeStampToHumanReadableShortDateTime } from "../../utils/parseTimeStamp";
import { InputBase } from "../shared/forms/input/InputBase";
import { EditIcon } from "../shared/icons/icons";
import {
  TableListContainer,
  TableRowBase,
} from "../shared/table/TableComponents";
import { useScheduleContext } from "./ScheduleContext";

export function ScheduleEditRow({
  schedule,
  index,
}: {
  schedule: CreateScheduleRow;
  index: number;
}) {
  const { id, created_at } = schedule;

  const { remove, control, isEdit, setIsEdit } = useScheduleContext();

  return (
    <TableListContainer key={id}>
      <TableRowBase>
        <Typography variant="small" className="font-normal text-skin-base">
          {formatTimeStampToHumanReadableShortDateTime(created_at)}
        </Typography>
      </TableRowBase>

      <TableRowBase>
        <Controller
          render={({ field, fieldState }) => {
            return (
              <InputBase
                placeholder="Name"
                value={field.value as string}
                setValue={field.onChange}
                error={fieldState.error}
              />
            );
          }}
          name={`schedules.${index}.name`}
          control={control}
        />
      </TableRowBase>
      <TableRowBase>
        <Controller
          render={({ field, fieldState }) => {
            return (
              <InputBase
                placeholder="Content"
                value={field.value as string}
                setValue={field.onChange}
                error={fieldState.error}
              />
            );
          }}
          name={`schedules.${index}.content`}
          control={control}
        />
      </TableRowBase>
      <TableRowBase>
        <Controller
          render={({ field, fieldState }) => {
            return (
              <InputBase
                placeholder="Cron"
                value={field.value as string}
                setValue={field.onChange}
                error={fieldState.error}
              />
            );
          }}
          name={`schedules.${index}.cronSchedule`}
          control={control}
        />
      </TableRowBase>
      <TableRowBase>
        <button
          onClick={(e) => {
            e.preventDefault();
            setIsEdit(!isEdit);
          }}
        >
          <div className="flex flex-row items-center gap-2">
            <EditIcon className="h-4 w-4" />
          </div>
        </button>
      </TableRowBase>
      <TableRowBase>
        <button
          onClick={(e) => {
            e.preventDefault();
            remove(index);
          }}
        >
          <div className="flex flex-row items-center gap-2">
            <TrashIcon className="h-4 w-4 text-red-400" />
          </div>
        </button>
      </TableRowBase>
    </TableListContainer>
  );
}
