import { LoadingSpinnerForButtons } from "../forms/LoadingSpinner";

function SubmitButton(props: {
  loading?: boolean;
  onClick?: (e: any) => void;
  children?: React.ReactNode;
  [x: string]: HTMLButtonElement["attributes"] | any;
}) {
  const { loading, onClick, children, ...rest } = props;
  return (
    <button
      onClick={onClick}
      type="button"
      className="flex w-full flex-row items-center justify-center gap-2 rounded-md bg-skin-accentSecondary px-3.5 py-2.5 text-sm font-semibold text-skin-hover shadow-sm hover:bg-skin-accent disabled:cursor-not-allowed disabled:bg-skin-muted disabled:text-skin-hover"
      {...rest}
    >
      {loading && <LoadingSpinnerForButtons />}
      {children || "Submit"}
    </button>
  );
}

export default SubmitButton;
