import { Button, Typography } from "@material-tailwind/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useAuthContext } from "../fabrk/hooks/AuthContext";
import FabrkMenuDivider from "../shared/dividers/FabrkMenuDivider";
import LogoWithTitle from "../layout/LogoWithTitle";
import { ErrorHelperText } from "../shared/forms/ErrorHelperText";
import { FabrkInput } from "../shared/forms/input/InputBase";
import LoginFooterLink from "./LoginFooterLink";
import SignupFooterLink from "./SignupFooterLink";
import GoogleSignIn from "./GoogleSignIn";

export interface AuthProps {
  viewType: "Log In" | "Sign Up" | "Reset Password";
}

// This is for the /login route
function LoginSignupContainer({ viewType }: AuthProps) {
  const {
    signIn,
    loginError,
    signUp,
    resetPassword,
    passwordResetInstructions,
  } = useAuthContext();
  const [error, setError] = useState<string | null>(null);

  const [dialogTitle, setDialogTitle] = useState(viewType);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
      repeatPassword: "",
    },
  });

  function onSubmit(data: any) {
    if (dialogTitle === "Reset Password") {
      resetPassword(data.email);
      return;
    }

    if (dialogTitle === "Log In") {
      signIn({
        email: data.email,
        password: data.password,
      });
      return;
    } else {
      if (data.password !== data.repeatPassword) {
        setError("Passwords do not match");
        return;
      } else {
        signUp({
          email: data.email,
          password: data.password,
        });
      }
    }
  }

  return (
    <div className="mt-5 flex h-fit flex-col gap-4 rounded-xl bg-skin-hover/35 p-4">
      <LogoWithTitle />
      {viewType !== "Reset Password" && (
        <div>
          <GoogleSignIn viewType={viewType} />
        </div>
      )}

      <Typography variant="h4" className="mt-5 text-skin-base">
        {dialogTitle} {dialogTitle === "Log In" && "to Fabrk"}{" "}
        {dialogTitle === "Sign Up" && "for Fabrk"}
      </Typography>
      <FabrkMenuDivider />
      {(loginError || error) && (
        <div className="mb-5">
          <ErrorHelperText text={(error as string) || loginError} />
        </div>
      )}

      {passwordResetInstructions && (
        <div className="mb-5">
          <ErrorHelperText text={passwordResetInstructions} />
        </div>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-5">
          <FabrkInput
            register={register}
            errors={errors}
            label="Email"
            name="email"
          />

          {dialogTitle !== "Reset Password" && (
            <FabrkInput
              register={register}
              errors={errors}
              label="Password"
              type="password"
              name="password"
            />
          )}

          {dialogTitle === "Sign Up" && (
            <FabrkInput
              register={register}
              errors={errors}
              label="Confirm Password"
              type="password"
              name="repeatPassword"
            />
          )}

          <Button className="bg-skin-accent" type="submit">
            {dialogTitle}
          </Button>

          <Typography variant="small" className="text-skin-base/80">
            Forgot password?{" "}
            <span
              style={{
                cursor: "pointer",
              }}
              onClick={() => setDialogTitle("Reset Password")}
            >
              Reset
            </span>
          </Typography>

          {dialogTitle === "Sign Up" ? (
            <LoginFooterLink
              setSignup={() => {
                reset();
                setError(null);
                setDialogTitle("Log In");
              }}
            />
          ) : (
            <SignupFooterLink
              setLogin={() => {
                reset();
                setError(null);
                setDialogTitle("Sign Up");
              }}
            />
          )}
        </div>
      </form>
    </div>
  );
}

export default LoginSignupContainer;
