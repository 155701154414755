import BalanceAmountCard from "../../components/project/BalanceAmountCard";
import PaymentHistoryCard from "../../components/project/PaymentHistoryCard";

export default function BillingContainer() {
  return (
    <div className="mx-auto flex max-w-7xl flex-col gap-8">
      <BalanceAmountCard />
      <PaymentHistoryCard />
      {/* <UsageCard /> */}
    </div>
  );
}
