import {
  ChatBubbleOvalLeftIcon,
  WrenchIcon,
} from "@heroicons/react/24/outline";
import { useAgentAgentToolContext } from "../hooks/AgentAgentToolContext";
import { useAgentContext } from "../hooks/AgentContext";

function ViewAgentHeaderStats() {
  const { activeAgent } = useAgentContext();
  const { agentAgentTools } = useAgentAgentToolContext();

  return (
    <dl className="mt-10 grid grid-cols-2 gap-8 sm:grid-cols-2">
      <div>
        <dt className="flex flex-row items-center gap-2 text-sm font-semibold leading-6 text-skin-base">
          <WrenchIcon className="h-4 w-4" /> Tool Count
        </dt>
        <dd className="mt-2 text-3xl font-bold leading-10 tracking-tight text-skin-base">
          {(activeAgent?.agent_tool?.length || 0) +
            (agentAgentTools?.length || 0)}
        </dd>
      </div>
      <div>
        <dt className="flex flex-row items-center gap-2 text-sm font-semibold leading-6 text-skin-base">
          <ChatBubbleOvalLeftIcon className="h-4 w-4" /> Thread Count
        </dt>
        <dd className="mt-2 text-3xl font-bold leading-10 tracking-tight text-skin-base">
          {activeAgent?.client_agent?.length || 0}
        </dd>
      </div>
    </dl>
  );
}

export default ViewAgentHeaderStats;
