/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import MetadataButton from "../../../shared/buttons/MetadataButton";
import LoadingSpinner from "../../../shared/forms/LoadingSpinner";
import { useAgentToolContext } from "../../hooks/AgentToolContext";
import { useMessageMetadataContext } from "../hooks/MessageMetadataContext";
import MetadataItemList from "./MetadataItemList";

function MessageMetadataContainer({
  showMetadata = false,
}: {
  showMetadata?: boolean;
}) {
  const { agentTools } = useAgentToolContext();
  const {
    handleGetData,
    responses,
    systemMessages,
    userSystemMessages,
    functionMessages,
    toolMessages,
    errorMessages,
    loading,
  } = useMessageMetadataContext();

  const containerRef = useRef<HTMLDivElement>(null); // Ref for the component

  const [isInViewport, setIsInViewport] = useState<boolean>(false);

  const [content, setContent] = useState<string>("context");

  // UseEffect with Intersection Observer to detect when the component is in the viewport
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          setIsInViewport(true);
        } else {
          setIsInViewport(false);
        }
      },
      {
        root: null, // Default is the viewport
        threshold: 0.1, // Trigger when 10% of the component is in view
      },
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  // Trigger the API call only when the component is in the viewport and userMessageId is present
  useEffect(() => {
    if (showMetadata && isInViewport) {
      handleGetData();
    }
  }, [isInViewport, agentTools, showMetadata]);

  return (
    <div ref={containerRef}>
      {showMetadata && (
        <div className="flex w-full flex-col gap-4 rounded-md bg-skin-muted p-3 text-skin-inverse">
          <div className="grid grid-cols-1 rounded-md bg-skin-hover sm:grid-cols-4">
            <MetadataButton
              isActive={content === "context"}
              onClick={() => setContent("context")}
            >
              <span>Context</span>
            </MetadataButton>
            <MetadataButton
              isActive={content === "available"}
              onClick={() => {
                if (content === "available") {
                  setContent("");
                } else {
                  setContent("available");
                }
              }}
            >
              <span>{functionMessages?.length}</span>{" "}
              <span>
                Tool{functionMessages?.length === 1 ? "" : "s"} Available
              </span>
            </MetadataButton>

            <MetadataButton
              isActive={content === "called"}
              onClick={() => setContent("called")}
            >
              <span>
                {toolMessages?.length} Tool
                {toolMessages?.length === 1 ? " " : "s "}
                Called
              </span>
            </MetadataButton>

            {(!errorMessages || errorMessages.length === 0) && (
              <MetadataButton
                isActive={content === "data"}
                onClick={() => setContent("data")}
              >
                <span>{responses?.length}</span> <span>Extraction</span>
              </MetadataButton>
            )}
            {errorMessages && errorMessages.length > 0 && (
              <MetadataButton
                isActive={content === "error"}
                onClick={() => setContent("error")}
              >
                <span>{errorMessages?.length}</span>{" "}
                <span>Error{errorMessages?.length === 1 ? " " : "s "}</span>
              </MetadataButton>
            )}
          </div>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <MetadataItemList
              content={content}
              systemMessages={systemMessages}
              functionMessages={functionMessages}
              toolMessages={toolMessages}
              extractionResponses={responses}
              userSystemMessages={userSystemMessages}
              errorMessages={errorMessages}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default MessageMetadataContainer;
