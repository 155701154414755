/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { formatTimeStampToHumanReadableShortDateTime } from "../../utils/parseTimeStamp";
import { getFileNameFromPath } from "../../utils/stringManipulation";
import FabrkMenuDivider from "../shared/dividers/FabrkMenuDivider";
import IconButton from "../shared/buttons/IconButton";
import { EditIcon } from "../shared/icons/icons";
import { LabelBase } from "../shared/typography/TypographyBase";
import { useToolContext } from "./hooks/ToolContext";
import AddFilesToTool from "./AddFilesToTool";

function ToolFilesContainer() {
  const { isCompanyTool, tool } = useToolContext();
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    if (isCompanyTool && tool?.file_store_tool?.length === 0) {
      setEdit(true);
    }
  }, [tool]);

  return (
    <div className="flex max-w-2xl flex-col gap-4">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-col gap-4">
          <p className="mt-1 max-w-2xl text-sm leading-6 text-skin-base/40">
            Files available for this tool
          </p>
        </div>
        {isCompanyTool && (
          <IconButton
            onClick={() => {
              setEdit(!edit);
            }}
          >
            <EditIcon className="h-5 w-5" />
          </IconButton>
        )}
      </div>
      <FabrkMenuDivider />

      {edit && isCompanyTool && <AddFilesToTool setEdit={setEdit} />}

      {tool?.file_store_tool?.map((file) => {
        return (
          <div className="mt-4 flex max-w-lg flex-col gap-1">
            <LabelBase>
              Added:{" "}
              {formatTimeStampToHumanReadableShortDateTime(file.created_at)}
            </LabelBase>
            <a href={file.file_store.url}>
              <p className="text-skin-accentSecondary underline">
                {getFileNameFromPath(file?.file_store?.url)}
              </p>
            </a>
          </div>
        );
      })}
    </div>
  );
}

export default ToolFilesContainer;
