/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from "@material-tailwind/react";
import { useState } from "react";
import { useAgentAgentToolContext } from "../agent/hooks/AgentAgentToolContext";
import { useAgentContext } from "../agent/hooks/AgentContext";
import { useLoadCompanyAgents } from "../agent/hooks/useCompanyAgents";
import SubmitButton from "../shared/buttons/SubmitButton";
import GenericSelect from "../shared/forms/GenericSelect";

function AddAgentToolForm({
  setDialogOpen,
}: {
  setDialogOpen: (open: boolean) => void;
}) {
  const { activeAgent } = useAgentContext();

  const { companyAgents } = useLoadCompanyAgents();

  const [selectedAgentString, setSelectedAgentString] = useState<string>("");

  const { agentAgentTools, addAgentAgentTool } = useAgentAgentToolContext();

  const selectedAgent = companyAgents?.find(
    (ca) => ca.id === selectedAgentString,
  );

  const handleSubmit = () => {
    setDialogOpen(false);
    addAgentAgentTool({
      parentAgentId: activeAgent?.id as string,
      childAgentId: selectedAgent?.id as string,
    });
  };

  return (
    <div className="flex flex-col gap-4 p-5">
      <Typography variant="paragraph">
        Select another agent to add as a tool to the {activeAgent?.name} agent.
      </Typography>

      <Typography variant="paragraph">
        Once added you'll need to give this agent instructions on when to call
        it. For example, you could tell it to call the agent when a user asks
        for help.
      </Typography>
      {companyAgents && (
        <GenericSelect
          values={companyAgents
            ?.filter((ca) => {
              if (!agentAgentTools) {
                return true;
              } else {
                const foundAgentAlreadyAdded = agentAgentTools?.some(
                  (aat) => aat.tool_agent_id === ca.id,
                );
                return !foundAgentAlreadyAdded;
              }
            })
            .map((agent) => ({
              label: agent.name,
              value: agent.id,
            }))}
          setSelected={(value) => setSelectedAgentString(value)}
          selected={selectedAgentString}
        />
      )}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <SubmitButton disabled={!selectedAgent} fullWidth={true} type="submit">
          Add {selectedAgent?.name} as a tool for {activeAgent?.name}
        </SubmitButton>
      </form>
    </div>
  );
}

export default AddAgentToolForm;
