import { LinkIcon } from "@heroicons/react/24/outline";
import { Typography } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { ExtractionResponseRow } from "../../../../api/extractionResponse.type";
import { SmsMetadataRow } from "../../../../api/smsMetadata.type";
import ExtractionResponsesTable from "../../../extraction/responses/ExtractionResponsesTable";
import BaseChip from "../../../shared/chip/BaseChip";
import ReadableTextFormatter from "../../../shared/forms/FormatContext";
import ToolCalledMetadataContainer from "./ToolCalledMetadataContainer";

const apiUrl = "https://api.fabrk.ai";

function MetadataItemList({
  content,
  systemMessages,
  functionMessages,
  toolMessages,
  extractionResponses,
  userSystemMessages,
  errorMessages,
}: {
  content: string;
  systemMessages?: SmsMetadataRow[];
  functionMessages?: SmsMetadataRow[];
  toolMessages?: SmsMetadataRow[];
  extractionResponses?: ExtractionResponseRow[];
  userSystemMessages?: SmsMetadataRow[];
  errorMessages?: SmsMetadataRow[];
}) {
  return (
    <div>
      {content === "context" && (
        <div className="min-w-md flex flex-col gap-2">
          {userSystemMessages?.map((msg, idx) => (
            <div key={idx} className="flex flex-col gap-4">
              <ReadableTextFormatter text={msg.content} />
            </div>
          ))}
          {systemMessages?.map((msg, idx) => (
            <div key={idx} className="flex flex-col gap-4">
              {apiUrl && (
                <a
                  className="flex flex-row gap-2 font-bold text-skin-inverse hover:underline"
                  target="_blank"
                  href={`${apiUrl}/openai/run-steps?threadId=${msg?.thread_id}&runId=${msg?.run_id}`}
                  rel="noreferrer"
                >
                  <LinkIcon className="w-4" /> Link to run steps
                </a>
              )}
              <ReadableTextFormatter text={msg.content} />
            </div>
          ))}
        </div>
      )}
      {content === "available" && (
        <div className="min-w-md grid grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-cols-3">
          {functionMessages?.map((msg, idx) => (
            <div key={idx}>
              <BaseChip>{msg?.tool?.name || msg.function_call_name}</BaseChip>
            </div>
          ))}
        </div>
      )}
      {content === "called" && (
        <ToolCalledMetadataContainer toolMessages={toolMessages} />
      )}

      {content === "data" && (
        <div className="flex flex-col gap-2">
          <Link
            className="flex w-fit flex-row place-items-end items-center gap-2 text-skin-inverse hover:underline"
            to={`/agent/${systemMessages?.[0]?.agent_id}?clientId=${systemMessages?.[0]?.client_id}#extractions`}
          >
            <LinkIcon className="w-4" /> Link to all responses for this chat
          </Link>
          <div className="w-full">
            {extractionResponses && extractionResponses.length > 0 && (
              <ExtractionResponsesTable
                extractionResponses={extractionResponses}
              />
            )}
          </div>

          {(!extractionResponses || extractionResponses?.length === 0) && (
            <Typography className="w-full">
              No data extracted. Add a data extraction tool to this agent to
              extract data.
            </Typography>
          )}
        </div>
      )}
      {content === "error" && (
        <div className="min-w-md">
          {errorMessages?.map((msg, idx) => (
            <div key={idx}>
              <ReadableTextFormatter text={msg.content} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default MetadataItemList;
