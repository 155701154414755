import clsx from "clsx";
import { Link, useSearchParams } from "react-router-dom";
import { AgentRow } from "../../api/agent.type";
import { formatTimeStampToHumanReadableShortDateTime } from "../../utils/parseTimeStamp";
import { getFirstTwoLettersFromSentence } from "../../utils/stringManipulation";

function ThreadNavItem({
  a,
  isActive = false,
}: {
  a: AgentRow;
  isActive: boolean;
}) {
  const [searchParams] = useSearchParams();

  const agentType = searchParams.get("agentType");

  return (
    <div className="flex w-full flex-row items-center justify-between">
      <Link
        to={`/a/${a.id}${agentType ? `?agentType=${agentType}` : ""}`}
        className={clsx(
          isActive ? "text-skin-accent" : "text-skin-base hover:bg-skin-hover",
          "group flex w-full gap-x-3 rounded-md p-2 text-sm font-semibold leading-6",
        )}
      >
        <span
          className={clsx(
            isActive
              ? "border-skin-accent text-skin-accent"
              : "border-skin-muted text-skin-base group-hover:border-indigo-600",
            "flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border bg-skin-fill text-[0.625rem] font-medium capitalize",
          )}
        >
          {a.image_path ? (
            <img className="h-full w-full" src={a.image_path} alt={a.name} />
          ) : (
            getFirstTwoLettersFromSentence(a.name)
          )}
        </span>
        <span className="truncate">
          {a.name || formatTimeStampToHumanReadableShortDateTime(a?.created_at)}
        </span>
      </Link>
    </div>
  );
}

export default ThreadNavItem;
