export function snakeCaseToTitleCase(input: string): string {
  if (!input) {
    return "";
  }
  const words = input.split("_");
  const titleCaseWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
  );
  return titleCaseWords.join(" ");
}

export function camelCaseToTitleCase(input: string): string {
  if (!input) {
    return "";
  }
  const words = input.split(/(?=[A-Z])/);
  const titleCaseWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
  );
  return titleCaseWords.join(" ");
}

export function camelCaseToHyphens(input: string): string {
  if (!input) {
    return "";
  }
  const words = input.split(/(?=[A-Z])/);
  const hyphenWords = words.map((word) => word.toLowerCase());
  return hyphenWords.join("-");
}

export function titleCaseToCamelCase(input: string): string {
  if (!input) {
    return "";
  }
  const words = input.split(" ");
  const camelCaseWords = words.map((word, index) => {
    if (index === 0) {
      return word.toLowerCase();
    }
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
  return camelCaseWords.join("");
}

export function toLowerCaseAndReplaceSpacesWithHyphens(input?: string): string {
  if (!input) {
    return "";
  }
  // Convert all characters to lowercase
  const lowerCaseString = input.toLowerCase();

  // Replace all spaces with hyphens
  const refactoredString = lowerCaseString.replace(/ /g, "-");

  return refactoredString;
}

export const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

export function getFileNameFromPath(path: string): string {
  // Handle both Unix-like and Windows paths by splitting on both / and \
  const segments = path.split(/[/\\]/);
  return segments.pop() || ""; // Return the last segment or an empty string if not found
}

export function extractPlaceholders(url: string): string[] {
  const regex = /{{([^}]+)}}/g;
  let match;
  const placeholders: string[] = [];

  while ((match = regex.exec(url)) !== null) {
    placeholders.push(match[1]);
  }

  return placeholders;
}

export function isUUID(text: string): string | null {
  const uuidPattern =
    /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
  const match = text.match(uuidPattern);
  return match ? match[0] : null;
}

export function getContentAfterC(url: string) {
  const path = url.split("?")[0]; // Remove query parameters
  const segments = path.split("/c/"); // Split the path by "/c/"

  if (segments.length > 1) {
    const nextSegment = segments[1].split("/")[0]; // Get the first part after "/c/"
    return nextSegment;
  }

  return null;
}

export function getCompanySlugFromUrl(url: string) {
  if (!url) {
    return null;
  }
  const regex = /\/c\/([^/?#]+)/;
  const match = url.match(regex);
  return match ? match[1] : null;
}

export function switchPort(url: string): string {
  try {
    const base = "http://localhost";
    const currentPort = new URL(url);

    let oppositePort = "3000";

    if (currentPort.port === "3000") {
      oppositePort = "3001";
    } else if (currentPort.port === "3001") {
      oppositePort = "3000";
    }

    return base + ":" + oppositePort + "/";
  } catch (error) {
    console.error("Invalid URL:", error);
    return url;
  }
}

function randomTwoLetters(): string {
  const alphabet = "abcdefghijklmnopqrstuvwxyz";
  const randomIndex = Math.floor(Math.random() * alphabet.length);
  return alphabet[randomIndex];
}

export function getFirstTwoLettersFromSentence(sentence?: string): string {
  if (!sentence) {
    return randomTwoLetters();
  }

  const cleanWord = (word: string) => word.replace(/[^a-zA-Z]/g, ""); // Remove non-alphabetic characters

  const words = sentence.split(" ");
  const firstWord = cleanWord(words[0] || "");
  const secondWord = cleanWord(words[1] || "");

  if (!firstWord) {
    return randomTwoLetters();
  }
  if (!secondWord) {
    return firstWord.charAt(0).toUpperCase();
  }
  return firstWord.charAt(0).toUpperCase() + secondWord.charAt(0).toUpperCase();
}

export function truncateStringAddEllipsis(str: string, length: number): string {
  if (str.length > length) {
    return str.substring(0, length) + "...";
  }
  return str;
}
