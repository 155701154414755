import { Button, CardFooter, Typography } from "@material-tailwind/react";
import LoadingSpinner from "../forms/LoadingSpinner";
import { useAlertContext } from "../alert/alertContext";

function TableFooter({
  rowCount,
  rowsPerPage,
  currentPage,
  handleNextPage,
  handlePreviousPage,
  isLoading = false,
}: {
  rowCount: number;
  rowsPerPage: number;
  currentPage: number;
  handleNextPage?: () => void;
  handlePreviousPage?: () => void;
  isLoading?: boolean;
}) {
  const lastPage = Math.ceil(rowCount / rowsPerPage);

  const { globalLoading } = useAlertContext();

  return (
    <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
      <Typography variant="small" color="blue-gray" className="font-normal">
        Page {currentPage + 1} of {lastPage || 1}
      </Typography>
      <div className="flex gap-2">
        <Button
          onClick={handlePreviousPage}
          variant="outlined"
          color="blue-gray"
          size="sm"
          disabled={currentPage === 0}
          className="min-w-[100px]"
        >
          {isLoading ? <LoadingSpinner /> : "Previous"}
        </Button>
        <Button
          onClick={handleNextPage}
          variant="outlined"
          color="blue-gray"
          size="sm"
          disabled={currentPage + 1 === lastPage}
          className="min-w-[100px]"
        >
          {globalLoading ? <LoadingSpinner /> : "Next"}
        </Button>
      </div>
    </CardFooter>
  );
}

export default TableFooter;
