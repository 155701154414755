import { useState } from "react";
import { useScreenSize } from "../fabrk/layout/hooks/ScreenSizeContext";
import { mediumLaunchPost } from "../auth/SignupSuccess";

const rocketEmoji = String.fromCodePoint(0x1f680);

function NotificationBannerContainer() {
  const [isVisible, setIsVisible] = useState(true);

  const { smallScreen } = useScreenSize();

  if (!isVisible) return null;

  return (
    <div className="fixed left-1/2 top-4 z-50 mx-auto w-full -translate-x-1/2 rounded-md bg-skin-accent px-4 py-2 text-center text-skin-inverse shadow-md md:mt-4 md:w-1/2">
      {!smallScreen && (
        <button
          onClick={() => setIsVisible(false)}
          className="absolute right-1 top-0 rounded-full text-skin-accentSecondary hover:text-skin-inverse focus:outline-none"
          aria-label="Close"
        >
          &times;
        </button>
      )}
      <div className="flex flex-row items-center justify-center gap-4 text-skin-inverse">
        <span>
          {" "}
          Fabrk AI is excited to announce its{" "}
          <a className="font-bold hover:underline" href={mediumLaunchPost}>
            beta launch!
          </a>{" "}
          {rocketEmoji}
        </span>
        {!smallScreen && <span>Want a personal demo? </span>}
        <a
          target="_blank"
          href="https://calendly.com/kevin-fabrk/fabrk-ai-demo"
          rel="noreferrer"
          className="w-fit rounded-lg border-2 border-skin-accentSecondary p-1 font-bold text-skin-accentSecondary hover:border-skin-accent hover:bg-skin-accentSecondary hover:text-skin-inverse"
        >
          Book a demo
        </a>
      </div>
      {smallScreen && (
        <button
          onClick={() => setIsVisible(false)}
          className="ml-4 text-xs text-skin-inverse/80 hover:underline"
        >
          Dismiss
        </button>
      )}
    </div>
  );
}

export default NotificationBannerContainer;
