/* eslint-disable react-hooks/exhaustive-deps */
import { LinkIcon, PencilIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "@material-tailwind/react";
import { useEffect, useMemo, useState } from "react";
import FabrkMenuDivider from "../../shared/dividers/FabrkMenuDivider";
import IconButton from "../../shared/buttons/IconButton";
import SubmitButton from "../../shared/buttons/SubmitButton";
import { NumberInputBase, TextArea } from "../../shared/forms/input/InputBase";
import ShowMoreParagraph from "../../shared/typography/ShowMoreParagraph";
import {
  DataValueBase,
  HeadingBase,
  LabelBase,
} from "../../shared/typography/TypographyBase";
import { useAgentContext } from "../hooks/AgentContext";
import { useEvalsToolContext } from "../hooks/EvalsContext";
import { useClientAgentContext } from "../../fabrk/hooks/ClientAgentContext";
import { Link } from "react-router-dom";
import { useAlertContext } from "../../shared/alert/alertContext";

function AgentContextAndEvals() {
  const [isEditing, setIsEditing] = useState(false);
  const { setAlertProps } = useAlertContext();

  const { activeAgent, updatePrompt, isCompanyAgent, updateAgent } =
    useAgentContext();

  const { activeClientAgent } = useClientAgentContext();

  const [instructions, setInstructions] = useState(
    activeAgent?.system_prompt?.text || "",
  );

  const [trailingMessageCount, setTrailingMessageCount] = useState(
    activeAgent?.trailing_msg_count || "25",
  );

  const [evalsLoading, setEvalsLoading] = useState(false);

  const {
    hasEvalAgent,
    evalParamLink,
    enableEvals,
    runEvals,
    runEvalsLoading,
  } = useEvalsToolContext();

  useMemo(() => {
    if (activeAgent) {
      setInstructions(activeAgent.system_prompt?.text || "");
    }
  }, [activeAgent]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (Number(trailingMessageCount) > 25) {
      setAlertProps({
        message: "Trailing messages cannot exceed 25",
        color: "red",
      });
      return;
    }

    if (instructions !== activeAgent?.system_prompt?.text) {
      updatePrompt({
        id: activeAgent?.system_prompt?.id as string,
        text: instructions,
      });
    }

    if (trailingMessageCount !== activeAgent?.trailing_msg_count) {
      updateAgent({
        id: activeAgent?.id as string,
        trailing_msg_count: trailingMessageCount,
      });
    }

    setIsEditing(false);
  };

  useEffect(() => {
    if (runEvalsLoading) {
      setEvalsLoading(true);
    }
  }, [runEvalsLoading]);

  useEffect(() => {
    if (evalsLoading) {
      setTimeout(() => {
        setEvalsLoading(false);
      }, 20000);
    }
  }, [evalsLoading]);

  return (
    <div>
      <div className="flex flex-row justify-between">
        <HeadingBase>Context and Evaluations</HeadingBase>
        {isCompanyAgent && (
          <IconButton
            onClick={() => {
              setIsEditing(!isEditing);
              setInstructions(activeAgent?.system_prompt?.text || "");
            }}
          >
            <PencilIcon className="h-4 w-4" />
          </IconButton>
        )}
      </div>
      <FabrkMenuDivider />
      <form onSubmit={handleSubmit}>
        <div className="mt-4 flex w-full flex-col gap-4">
          <div>
            <Tooltip
              placement="top"
              content="Instructions are the text that the AI model will use to generate responses. You can edit this text to change the behavior of the AI model."
              className="max-w-md bg-skin-hover text-skin-base"
            >
              <div className="flex flex-row gap-2">
                <LabelBase>Instructions</LabelBase>
                <LabelBase>
                  Version: {activeAgent?.system_prompt?.version}
                </LabelBase>
              </div>
            </Tooltip>
            {isEditing ? (
              <TextArea value={instructions} setValue={setInstructions} />
            ) : (
              <ShowMoreParagraph text={instructions} />
            )}
          </div>
          <div>
            <Tooltip
              placement="top"
              content="This is the total number of past messages that will be passed into the AI model to generate responses. The default and max is 25."
              className="max-w-md bg-skin-hover text-skin-base"
            >
              <div>
                <LabelBase>Trailing Messages Passed to the AI</LabelBase>
              </div>
            </Tooltip>

            {isEditing ? (
              <NumberInputBase
                value={trailingMessageCount}
                setValue={setTrailingMessageCount}
              />
            ) : (
              <DataValueBase>{activeAgent?.trailing_msg_count}</DataValueBase>
            )}
          </div>

          <div>
            {!hasEvalAgent && isCompanyAgent && !isEditing && (
              <SubmitButton
                onClick={(e) => {
                  e.preventDefault();
                  enableEvals(activeAgent?.id as string);
                }}
              >
                Enable Evals
              </SubmitButton>
            )}
          </div>
          <div>
            {evalParamLink && (
              <Link to={`/tool/${evalParamLink}`}>
                <div className="flex flex-row items-center gap-2">
                  Link to evaluation parameters
                  <LinkIcon className="h-4 w-4" />
                </div>
              </Link>
            )}
          </div>

          <div>
            {evalParamLink && (
              <Link to={`/agent/${activeAgent?.id}#evals`}>
                <div className="flex flex-row items-center gap-2">
                  Link to evaluation responses
                  <LinkIcon className="h-4 w-4" />
                </div>
              </Link>
            )}
          </div>

          <div>
            {hasEvalAgent && activeClientAgent && !isEditing && (
              <SubmitButton
                loading={evalsLoading}
                onClick={(e) => {
                  e.preventDefault();
                  runEvals(activeClientAgent?.id as string);
                }}
              >
                {evalsLoading
                  ? "Evaluations Running..."
                  : "Run Evaluations on this Thread"}
              </SubmitButton>
            )}
          </div>

          {isEditing && (
            <div className="flex justify-end">
              <div>
                <SubmitButton
                  type="submit"
                  onClick={handleSubmit}
                  disabled={
                    instructions === activeAgent?.system_prompt?.text &&
                    trailingMessageCount === activeAgent?.trailing_msg_count
                  }
                />
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
}

export default AgentContextAndEvals;
