/* eslint-disable react-hooks/exhaustive-deps */

import { HeadingBase } from "../shared/typography/TypographyBase";

import AuthenticateTool from "../auth/AuthenticateTool";

function IntegrationsOverview() {
  return (
    <div className="flex min-h-64 max-w-lg flex-col gap-8 rounded-lg border-2 p-2">
      <div className="flex flex-row justify-between border-b-2 py-2">
        <HeadingBase>Integrations</HeadingBase>
        {/* <IconButton
            onClick={(e?: React.MouseEvent<HTMLButtonElement>) => {
              e?.preventDefault();

              setIsEditing(!isEditing);
            }}
          >
            <PencilIcon className="h-4 w-4" />
          </IconButton> */}
      </div>

      <div>
        <AuthenticateTool />
      </div>
    </div>
  );
}

export default IntegrationsOverview;
