/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import {
  Control,
  FieldArrayWithId,
  useFieldArray,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  useForm,
  UseFormRegister,
  UseFormReset,
} from "react-hook-form";
import { useMutation } from "react-query";
import {
  createOrUpdateCredentialsForAgent,
  getCredentialsByAgent,
} from "../../../api/credentials.api";
import { CredentialsRow } from "../../../api/credentials.type";
import { useAlertContext } from "../../shared/alert/alertContext";
import { CredentialsForm } from "../view/CredentialEditRow";
import { useAgentContext } from "./AgentContext";

export const CredentialsContextWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { setAlertProps } = useAlertContext();
  const { activeAgent } = useAgentContext();

  const {
    mutate: getAgentCredentials,
    data: agentCredentials,
    isLoading: agentCredentialsLoading,
  } = useMutation(getCredentialsByAgent);

  const {
    mutate: createOrUpdateCredentials,
    isLoading: createCredentialsLoading,
  } = useMutation(createOrUpdateCredentialsForAgent, {
    onSuccess() {
      setAlertProps({
        message: "Credentials added",
        color: "green",
      });
      getAgentCredentials(activeAgent?.id as string);
    },
  });

  const [isEdit, setIsEdit] = useState(false);

  const ac = useMemo(() => {
    return agentCredentials;
  }, [agentCredentials, activeAgent]);

  const defaultValues = ac?.map((item) => {
    return {
      id: item.id,
      key_value: item.key_value,
    };
  });

  const {
    register,
    control,
    reset,
    formState: { isDirty, isValid },
    getValues,
  } = useForm({
    defaultValues: {
      id: "1",
      credentials: defaultValues || [],
    } as CredentialsForm,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "credentials",
  });

  // UseEffect to update the form when activeSchema changes
  useEffect(() => {
    if (agentCredentials) {
      const updatedValues = {
        id: "1",
        credentials: defaultValues,
      };
      reset(updatedValues); // Reset form with new values when activeSchema changes
    }
  }, [activeAgent, agentCredentials]);

  async function handleCreateCredentials() {
    const { credentials } = getValues();

    if (isValid) {
      createOrUpdateCredentials({
        agentId: activeAgent?.id as string,
        credentials,
      });
    }

    reset({
      credentials: agentCredentials,
    });
  }

  useEffect(() => {
    if (activeAgent) {
      getAgentCredentials(activeAgent?.id);
    }
  }, [activeAgent, getAgentCredentials]);

  useEffect(() => {
    if (agentCredentials && agentCredentials.length > 0) {
      setIsEdit(false);
    }
  }, [agentCredentials]);

  const value = {
    agentCredentials,
    isLoading: agentCredentialsLoading || createCredentialsLoading,
    register,
    control,
    fields,
    reset,
    remove,
    append,
    isDirty,
    handleCreateCredentials,
    isEdit,
    setIsEdit,
  };

  return (
    <CredentialsContext.Provider value={value}>
      {children}
    </CredentialsContext.Provider>
  );
};

export const CredentialsContext = createContext({
  agentCredentials: [] as CredentialsRow[] | undefined,
  fields: [] as FieldArrayWithId<CredentialsForm, "credentials", "id">[],
  reset: {} as UseFormReset<CredentialsForm>,
  remove: {} as UseFieldArrayRemove,
  control: {} as Control<CredentialsForm, any>,
  register: {} as UseFormRegister<CredentialsForm>,
  isLoading: false,
  append: {} as UseFieldArrayAppend<CredentialsForm, "credentials">,
  isDirty: false,
  handleCreateCredentials: () => {},
  isEdit: false,
  setIsEdit: (value: boolean) => {},
});

export const useCredentialsContext = () => useContext(CredentialsContext);
