import { useSession } from "@supabase/auth-helpers-react";
import { useEffect, useState } from "react";
import LoginSignupModal from "../../../auth/LoginSignupModal";
import GenericDialog from "../../../shared/dialog/GenericDialog";
import { useScreenSize } from "../hooks/ScreenSizeContext";

function LoginSignupButtons() {
  const session = useSession();

  const [dialogTitle, setDialogTitle] = useState<
    "Log In" | "Sign Up" | "Reset Password"
  >("Log In");
  const [dialogOpen, setDialogOpen] = useState(false);

  const { smallScreen } = useScreenSize();

  useEffect(() => {
    if ((dialogTitle === "Log In" || dialogTitle === "Sign Up") && session) {
      setDialogOpen(false);
    }
  }, [dialogTitle, session]);

  return (
    <div
      className="flex flex-row gap-2"
      style={{
        marginTop: `${smallScreen ? "3rem" : "0"}`,
      }}
    >
      <button
        type="button"
        className="inline-flex items-center rounded-md bg-skin-fill px-3 py-2 text-sm font-semibold text-skin-base shadow-sm ring-inset hover:bg-skin-hover"
        onClick={() => {
          setDialogOpen(true);
          setDialogTitle("Log In");
        }}
      >
        Sign In
      </button>
      <button
        type="button"
        className="ml-3 inline-flex items-center rounded-md bg-skin-accent px-3 py-2 text-sm font-semibold text-skin-inverse shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        onClick={() => {
          setDialogOpen(true);
          setDialogTitle("Sign Up");
        }}
      >
        Sign Up
      </button>
      <GenericDialog
        title={dialogTitle}
        open={dialogOpen}
        setOpen={setDialogOpen}
      >
        <LoginSignupModal viewType={dialogTitle} />
      </GenericDialog>
    </div>
  );
}

export default LoginSignupButtons;
