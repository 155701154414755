import { Typography } from "@material-tailwind/react";

const templateArray = Array.from({ length: 3 });

function MessageLoadingSkeleton({ text }: { text?: string }) {
  return (
    <div className="max-w-full animate-pulse">
      <Typography
        as="div"
        variant="h1"
        className="mb-4 h-3 w-72 rounded-full bg-gray-300"
      >
        &nbsp;
      </Typography>
      {text && <Typography>{text}</Typography>}
      {templateArray.map((_, index) => (
        <Typography
          key={index}
          as="div"
          variant="paragraph"
          className="mb-2 h-2 rounded-full bg-gray-300"
        >
          &nbsp;
        </Typography>
      ))}
    </div>
  );
}

export default MessageLoadingSkeleton;
