import { useQuery } from "react-query";
import { getPayments } from "../../../api/payment.api";
import { useCompanyContext } from "../../fabrk/hooks/CompanyContext";

export function useLoadPayments() {
  const { company } = useCompanyContext();

  const {
    data: payments,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: "payments",
    queryFn: () => getPayments(),
    enabled: !!company,
  });

  return {
    isLoading,
    payments,
    error,
    refetch,
  };
}
