/* eslint-disable react-hooks/exhaustive-deps */
import { MagnifyingGlassIcon, PlusIcon } from "@heroicons/react/24/outline";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import LayoutContainer from "../../components/layout/LayoutContainer";
import LoadingSpinner from "../../components/shared/forms/LoadingSpinner";
import DiscoverToolsListItem from "../../components/tools/DiscoverToolsListItem";
import { useLoadUsersTools } from "../../components/tools/hooks/useLoadUserTools";
import { mixpanelTrackEvent } from "../../utils/mixpanel";
import { useAuthContext } from "../../components/fabrk/hooks/AuthContext";

function ToolsContainer() {
  mixpanelTrackEvent("View Tools");
  const navigate = useNavigate();
  const { companyId } = useAuthContext();

  const { usersTools, isLoading, refetch } = useLoadUsersTools(companyId);

  useEffect(() => {
    if (companyId) {
      refetch();
    }
  }, [companyId]);

  return (
    <LayoutContainer>
      <div className="h-full min-h-screen bg-skin-fill py-10 sm:py-24">
        <div className="mx-auto flex max-w-7xl justify-between gap-8 px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-skin-base sm:text-4xl">
              Your Tools
            </h2>

            <p className="mt-2 max-w-lg text-lg leading-8 text-skin-base">
              This is a list of your tools. Tools can be added to agents to
              given them the ability to perform certain tasks.
            </p>
          </div>
          <div className="mt-10 flex items-center gap-x-6">
            <button
              className="flex flex-row items-center gap-2 rounded-md bg-skin-accentSecondary px-3.5 py-2.5 text-sm font-semibold text-skin-hover shadow-sm hover:bg-skin-accent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
              onClick={() => navigate("/tool/create")}
            >
              <PlusIcon className="h-5 w-5" />
              Create Tool
            </button>
            <a
              href="/discover/tools"
              className="flex flex-row items-center gap-2 rounded-md px-3.5 py-2.5 text-sm font-semibold leading-6 text-skin-base hover:bg-skin-hover"
            >
              <MagnifyingGlassIcon className="h-5 w-5" />
              Explore Tools
            </a>
          </div>
        </div>
        {isLoading ? (
          <div className="h-screen">
            <LoadingSpinner />
          </div>
        ) : (
          <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 px-6 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 xl:max-w-none xl:grid-cols-3">
            {usersTools &&
              usersTools
                .sort((t) => {
                  if (t.visibility === "public") {
                    return -1;
                  } else {
                    return 1;
                  }
                })
                .map((t, index) => (
                  <div key={index}>
                    <DiscoverToolsListItem tool={t} />
                  </div>
                ))}
          </div>
        )}
      </div>
    </LayoutContainer>
  );
}

export default ToolsContainer;
