import { ActionInsert, ActionRow, RunActionResponse } from "./action.type";
import { ApiEndpoints } from "./apiEndpoints";
import { fetchData } from "./apiHelpers";

export function createAction(
  action: Partial<ActionInsert> | null,
): Promise<ActionRow> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.action;

  const request = {
    method: "POST",
    body: JSON.stringify(action),
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetchData<ActionRow>(url, request);
}

export function runNewAction({
  clientId,
  actionId,
}: {
  clientId: string;
  actionId: string;
}): Promise<RunActionResponse> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.action + "/run";

  const request = {
    method: "POST",
    body: JSON.stringify({
      clientId,
      actionId,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  };
  return fetchData<RunActionResponse>(url, request);
}

export function getPublicActions(): Promise<ActionRow[]> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.action;

  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetchData<ActionRow[]>(url, request);
}

export function getActionById(id: string): Promise<ActionRow> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.action + `/${id}`;

  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetchData<ActionRow>(url, request);
}
