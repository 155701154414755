/* eslint-disable eqeqeq */
import { Fragment } from "react";

import { SmsMetadataRow } from "../../../../api/smsMetadata.type";
import { CalledToolRowView } from "./CalledToolRowView";

function CalledToolsList({
  calledTools,
  filterFunction,
}: {
  calledTools: SmsMetadataRow[] | undefined;
  filterFunction?: (tool: Partial<SmsMetadataRow>, index: number) => boolean;
}) {
  return (
    <Fragment>
      {calledTools &&
        calledTools
          .filter((er, index) => {
            if (filterFunction) {
              return filterFunction(er, index);
            } else {
              return true;
            }
          })
          .map((item, index) => {
            return <CalledToolRowView key={index} item={item} />;
          })}
    </Fragment>
  );
}

export default CalledToolsList;
