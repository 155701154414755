import { AgentRow } from "./agent.type";
import { ToolRow } from "./tool.type";

export type AgentAgentToolRow = {
  id: string;
  created_at: string;
  agent_id: string;
  tool_agent_id: string;
  is_active: boolean;
  parentAgent: AgentRow;
  childAgent: AgentRow;
};

export function mapAgentAgentToolToTool(
  agentAgentTool: AgentAgentToolRow,
): ToolRow {
  return {
    id: agentAgentTool.childAgent.id,
    created_at: agentAgentTool.created_at,
    name: agentAgentTool.childAgent.name || "", // Assuming name exists on childAgent
    description: agentAgentTool.childAgent.goal || "", // Assuming description exists on childAgent
    type: "agent", // Set type to "agent" as per the requirement
    // Assuming slug exists on childAgent
    file_store_tool: [], // Assuming no initial value, can be modified based on your needs
    context: agentAgentTool.childAgent?.system_prompt?.text || "", // Assuming context exists on childAgent

    visibility: agentAgentTool.childAgent.visibility || "public", // Assuming visibility exists on childAgent
    image_path: agentAgentTool.childAgent.image_path || "", // Assuming image_path exists on childAgent
    agent_tool: [], // Assuming no initial value, can be modified based on your needs
    company_tool: [], // Assuming no initial value, can be modified based on your needs
    function_name: "",
    slug: "",
    parameters: {},
  };
}
