import { ToolRow } from "../../api/tool.type";
import DiscoverListItemHeader from "../agent/discover/DiscoverListItemHeader";
import { DiscoverGridItem } from "../shared/containers/Containers";
import ToolHeaderPills from "./ToolHeaderPills";

function DiscoverToolsListItem({
  tool,
  children,
}: {
  tool: ToolRow;
  children?: React.ReactNode;
}) {
  const { name, created_at, image_path, id, agent_tool } = tool;

  return (
    <DiscoverGridItem>
      <DiscoverListItemHeader
        link={`/tool/${id}`}
        createdAt={created_at}
        name={name}
        imagePath={image_path}
      >
        <ToolHeaderPills agentUseCount={agent_tool?.length || 0} />
      </DiscoverListItemHeader>
      {children}
    </DiscoverGridItem>
  );
}

export default DiscoverToolsListItem;
