function SecondaryButton(props: {
  onClick?: (e: any) => void;
  children?: React.ReactNode;
  [x: string]: HTMLButtonElement["attributes"] | any;
}) {
  const { onClick, children, ...rest } = props;
  return (
    <button
      onClick={onClick}
      type="button"
      className="w-full rounded-md border-2 px-3.5 py-2.5 text-sm font-semibold shadow-sm hover:bg-skin-hover disabled:cursor-not-allowed disabled:bg-skin-muted disabled:text-skin-hover"
      {...rest}
    >
      {children || "Submit"}
    </button>
  );
}

export default SecondaryButton;
