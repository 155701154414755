import { MicrophoneIcon } from "@heroicons/react/24/outline";
import { useVoiceContext } from "./VoiceRecorderContext";

export default function VoiceRecorder() {
  const { isRecording, startRecording, stopRecording } = useVoiceContext();

  return (
    <div className="voice-recorder">
      <button onClick={isRecording ? stopRecording : startRecording}>
        {isRecording ? (
          <MicrophoneIcon className="h-6 w-6 text-red-500" />
        ) : (
          <MicrophoneIcon className="h-6 w-6 text-green-500" />
        )}
      </button>
    </div>
  );
}
