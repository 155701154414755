import { TrashIcon } from "@heroicons/react/24/outline";
import { Typography } from "@material-tailwind/react";
import { CredentialsRow } from "../../../api/credentials.type";
import {
  TableListContainer,
  TableRowBase,
} from "../../shared/table/TableComponents";
import { formatTimeStampToHumanReadableShortDateTime } from "../../../utils/parseTimeStamp";
import { EditIcon } from "../../shared/icons/icons";
import { useCredentialsContext } from "../hooks/CredentialsContext";

export function CredentialViewRow({
  credential,
  index,
  isEdit,
  setIsEdit,
}: {
  credential: CredentialsRow;
  index: number;
  isEdit: boolean;
  setIsEdit: (value: boolean) => void;
}) {
  const { created_at, id, key_value } = credential;
  const { remove } = useCredentialsContext();

  return (
    <TableListContainer key={id}>
      <TableRowBase>
        <Typography variant="small" className="w-32 font-normal text-skin-base">
          {formatTimeStampToHumanReadableShortDateTime(created_at)}
        </Typography>
      </TableRowBase>

      <TableRowBase>
        <Typography
          variant="small"
          className="min-w-32 font-normal text-skin-base"
        >
          {key_value.key}
        </Typography>
      </TableRowBase>
      <TableRowBase>
        <Typography variant="small" className="font-normal text-skin-base">
          {key_value.value}
        </Typography>
      </TableRowBase>
      <TableRowBase>
        <button
          className="w-8"
          onClick={(e) => {
            e.preventDefault();
            setIsEdit(!isEdit);
          }}
        >
          <div className="flex flex-row items-center gap-2">
            <EditIcon className="h-4 w-4" />
          </div>
        </button>
      </TableRowBase>
      <TableRowBase>
        <button
          className="w-8"
          onClick={(e) => {
            e.preventDefault();
            setIsEdit(!isEdit);
            remove(index);
          }}
        >
          <div className="flex flex-row items-center gap-2">
            <TrashIcon className="h-4 w-4 text-red-400" />
          </div>
        </button>
      </TableRowBase>
    </TableListContainer>
  );
}
