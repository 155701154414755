/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from "react";
import { camelCaseToTitleCase } from "../../../utils/stringManipulation";
import { useAuthContext } from "../../fabrk/hooks/AuthContext";
import IconButton from "../../shared/buttons/IconButton";
import FileUploadInput from "../../shared/forms/FileUploadInput";
import { useFileUploadContext } from "../../shared/forms/hooks/fileUploadContext";
import { EditIcon } from "../../shared/icons/icons";
import { useAgentContext } from "../hooks/AgentContext";
import { agentTypeIcons } from "./agentTypeToIconMap";
import { useAlertContext } from "../../shared/alert/alertContext";

function ViewAgentHeaderImg() {
  const { setGlobalLoading } = useAlertContext();

  const { activeAgent, updateAgent } = useAgentContext();
  const { companyId } = useAuthContext();
  const {
    hiddenFileInput,
    handleChange,
    handleOpenFileSelect,
    setFileName,
    uploadOneFile,
    file,
    filePath,
    setFilePath,
  } = useFileUploadContext();

  useEffect(() => {
    if (file) {
      setGlobalLoading(true);
      setFileName(file.name);
      uploadOneFile({
        file,
        additionalData: {
          companyId,
          location: "images",
        },
      });
    }
  }, [file]);

  useEffect(() => {
    if (filePath) {
      updateAgent({
        id: activeAgent?.id,
        image_path: filePath,
      });

      setFilePath("");
    }
  }, [filePath]);

  useEffect(() => {
    if (file || filePath) {
      setGlobalLoading(true);
    }
  }, [file, filePath]);

  return (
    <div className="relative overflow-hidden rounded-2xl bg-skin-fill pb-9 pt-32 shadow-2xl sm:px-12 lg:max-w-lg lg:pb-8 xl:px-10 xl:pb-10">
      <img
        alt="agent-avatar"
        src={activeAgent?.image_path || "/images/robot.webp"}
        className="absolute inset-0 h-full w-full bg-skin-fill object-cover"
      />

      {/* Edit button positioned in the top-right corner */}
      <div className="absolute right-2 top-2 z-10">
        <IconButton
          onClick={() => {
            handleOpenFileSelect();
          }}
        >
          <EditIcon className="h-5 w-5" />
        </IconButton>
      </div>

      <figure className="relative isolate">
        <figcaption className="mt-6 w-fit rounded-md bg-skin-hover px-4 leading-6">
          <strong className="text-xl font-bold text-skin-accent">
            {activeAgent?.name}
          </strong>{" "}
        </figcaption>
      </figure>

      {activeAgent?.type && (
        <figure className="relative isolate">
          <figcaption className="mt-6 flex w-fit flex-row gap-2 rounded-md bg-skin-hover px-4 text-lg leading-6">
            {agentTypeIcons[activeAgent.type]}{" "}
            <strong className="font-bold capitalize text-skin-base">
              {camelCaseToTitleCase(activeAgent?.type)}
            </strong>{" "}
          </figcaption>
        </figure>
      )}
      <FileUploadInput
        handleChange={handleChange}
        hiddenFileInput={hiddenFileInput}
        accept={"image/*"}
        multiple={false}
      />
    </div>
  );
}

export default ViewAgentHeaderImg;
