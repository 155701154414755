import ChatLayout from "../../components/layout/ChatLayout";
import LayoutContainer from "../../components/layout/LayoutContainer";

function AgentChat() {
  return (
    <LayoutContainer>
      <ChatLayout initialMenuType="settings" isOpen={true} />
    </LayoutContainer>
  );
}

export default AgentChat;
