import { useSession } from "@supabase/auth-helpers-react";
import { useState } from "react";
import { MessageRow } from "../../../api/message.type";
import { useActionContext } from "../../action/ActionContext";
import { useAgentContext } from "../../agent/hooks/AgentContext";
import SubmitButton from "../../shared/buttons/SubmitButton";
import GenericDialog from "../../shared/dialog/GenericDialog";
import { LabelBase } from "../../shared/typography/TypographyBase";
import { useMessageContext } from "../hooks/MessageContext";
import ActionMenu from "./ActionMenu";

function UserMessageChatItem({ message }: { message: MessageRow }) {
  const [actionMenuOpen, setActionMenuOpen] = useState(false);

  const { activeAgent } = useAgentContext();

  const session = useSession();

  const { create } = useActionContext();

  const { handleCreateMessage } = useMessageContext();

  function toggleActionMenu() {
    setActionMenuOpen((prev) => !prev);
  }

  function handleCreateAction() {
    create({
      sms_message_id: message.id,
      agent_id: activeAgent?.id || "",
      user_id: session?.user.id || "",
      company_id: activeAgent?.company_id || "",
      content: message.content,
    });
    setActionMenuOpen(false);
  }

  return (
    <div className="mr-2 mt-10 flex items-center justify-end gap-4">
      <div className="max-w-xl text-wrap break-words rounded-lg bg-skin-hover p-2 text-skin-base">
        {message.content}
      </div>
      <ActionMenu
        rerun={() =>
          handleCreateMessage({
            content: message.content,
            agentId: activeAgent?.id as string,
          })
        }
        toggle={toggleActionMenu}
      />
      <GenericDialog
        open={actionMenuOpen}
        setOpen={setActionMenuOpen}
        title="Create action"
      >
        <div className="flex flex-col gap-4">
          <div>
            Creating an action with this message will let users rerun this
            message with a click of a button.
          </div>
          <div>
            <LabelBase>Message content:</LabelBase>
            <p>{message.content}</p>
          </div>
          <SubmitButton onClick={handleCreateAction}>
            Create action
          </SubmitButton>
        </div>
      </GenericDialog>
    </div>
  );
}

export default UserMessageChatItem;
