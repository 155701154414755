import { mapAgentAgentToolToTool } from "../../../api/agentAgentTool.type";
import { ExtractionResponsesContextWrapper } from "../../extraction/responses/ExtractionResponsesContext";
import { useAgentToolContext } from "../../fabrk/hooks/AgentToolContext";
import { ScheduleContextWrapper } from "../../schedule/ScheduleContext";
import TabContainer from "../../shared/containers/Tabs";
import ToolTable from "../../tools/ToolTable";
import { useAgentAgentToolContext } from "../hooks/AgentAgentToolContext";
import { useAgentContext } from "../hooks/AgentContext";
import { CredentialsContextWrapper } from "../hooks/CredentialsContext";
import { EvalsContextWrapper } from "../hooks/EvalsContext";
import { VisibilityContextWrapper } from "../hooks/VisibilityContext";
import AgentApiDetailsContainer from "./AgentApiDetailsContainer";
import AgentCredentialsContainer from "./AgentCredentialsContainer";
import AgentEvalsContainer from "./AgentEvalsContainer";
import AgentResponsesContainer from "./AgentResponsesContainer";
import AgentScheduleContainer from "./AgentScheduleContainer";
import ThreadListContainer from "./AgentThreadListContainer";
import AgentVisibilityList from "./visibility/AgentVisibilityList";
import ViewAgentHeader from "./ViewAgentHeader";

const tabs = [
  { name: "Tools", href: "#tools", current: true },
  { name: "Threads", href: "#threads", current: false },
  { name: "Evaluations", href: "#evals", current: false },
  { name: "Extractions", href: "#extractions", current: false },
  { name: "Schedule", href: "#schedule", current: false },
  { name: "Credentials", href: "#credentials", current: false },
  { name: "Visibility", href: "#visibility", current: false },
  { name: "API", href: "#api", current: false },
];

export default function ViewAgent() {
  const { activeAgent } = useAgentContext();

  const { agentTools } = useAgentToolContext();

  const toolMap = agentTools?.map((at) => at.tool);

  const { agentAgentTools } = useAgentAgentToolContext();

  const aaTools = agentAgentTools?.map((aat) => mapAgentAgentToolToTool(aat));

  const combined = [...(toolMap || []), ...(aaTools || [])];

  return (
    <ScheduleContextWrapper>
      <ExtractionResponsesContextWrapper>
        <EvalsContextWrapper>
          <CredentialsContextWrapper>
            <div className="h-full min-h-screen bg-skin-fill py-12 sm:py-24">
              <ViewAgentHeader />
              <div className="mt-16 max-w-7xl px-6 lg:px-8">
                <TabContainer tabs={tabs}>
                  {(activeTab: string) => (
                    <>
                      {activeTab === "Tools" && <ToolTable tools={combined} />}
                      {activeTab === "Threads" && (
                        <ThreadListContainer
                          clientAgents={activeAgent?.client_agent}
                        />
                      )}
                      {activeTab === "Evaluations" && <AgentEvalsContainer />}
                      {activeTab === "Extractions" && (
                        <AgentResponsesContainer />
                      )}
                      {activeTab === "Schedule" && (
                        <AgentScheduleContainer activeAgent={activeAgent} />
                      )}
                      {activeTab === "Credentials" && (
                        <AgentCredentialsContainer />
                      )}
                      {activeTab === "Visibility" && (
                        <VisibilityContextWrapper>
                          <AgentVisibilityList />
                        </VisibilityContextWrapper>
                      )}
                      {activeTab === "API" && <AgentApiDetailsContainer />}
                    </>
                  )}
                </TabContainer>
              </div>
            </div>
          </CredentialsContextWrapper>
        </EvalsContextWrapper>
      </ExtractionResponsesContextWrapper>
    </ScheduleContextWrapper>
  );
}
