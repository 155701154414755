import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  TransitionChild,
} from "@headlessui/react";
import { XMarkIcon } from "../shared/icons/icons";
import { navigation } from "./LayoutConfig";
import LogoWithTitle from "./LogoWithTitle";
import NavItem from "./NavItem";
import SettingsMenu from "./SettingsMenu";
import ThreadListContainer from "./ThreadListContainer";

function SidebarDialogContainer({
  sidebarOpen,
  setSidebarOpen,
  current,
}: {
  sidebarOpen: boolean;
  setSidebarOpen: (open: boolean) => void;
  current: string;
}) {
  return (
    <Dialog
      open={sidebarOpen}
      onClose={setSidebarOpen}
      className="relative z-50 lg:hidden"
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-skin-muted/50 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
      />

      <div className="fixed inset-0 flex">
        <DialogPanel
          transition
          className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
        >
          <TransitionChild>
            <div className="absolute right-2 top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
              <button
                type="button"
                onClick={() => setSidebarOpen(false)}
                className="-m-1.5 rounded-md p-1.5 hover:bg-skin-hover"
              >
                <span className="sr-only">Close sidebar</span>
                <XMarkIcon
                  aria-hidden="true"
                  className="h-6 w-6 text-skin-base"
                />
              </button>
            </div>
          </TransitionChild>

          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-skin-fill px-6 pb-4">
            <LogoWithTitle />
            <nav className="flex flex-1 flex-col">
              <ul className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <NavItem key={item.name} item={item} current={current} />
                    ))}
                  </ul>
                </li>
                <ThreadListContainer />

                <li className="mt-auto">
                  <SettingsMenu />
                </li>
              </ul>
            </nav>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
}

export default SidebarDialogContainer;
