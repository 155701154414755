/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect } from "react";
import { UseMutateFunction, useMutation } from "react-query";
import { AgentAgentToolRow } from "../../../api/agentAgentTool.type";
import {
  enableEvalsForAgent,
  getEvaluations,
  runEvalsForThread,
} from "../../../api/evaluation.api";
import { EvaluationRow } from "../../../api/evaluation.type";
import { useAlertContext } from "../../shared/alert/alertContext";
import { useAgentAgentToolContext } from "./AgentAgentToolContext";

export const EvalsContextWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { setAlertProps, setGlobalLoading } = useAlertContext();

  const { agentAgentTools } = useAgentAgentToolContext();

  const { handleGetAgentAgentTools } = useAgentAgentToolContext();

  const { mutate: getEvals, data: evaluations } = useMutation(getEvaluations);

  const { mutate: enableEvals, isLoading } = useMutation(enableEvalsForAgent, {
    onSuccess() {
      setAlertProps({
        message: "Evals enabled",
        color: "green",
      });
      handleGetAgentAgentTools();
    },
  });

  const { mutate: runEvals, isLoading: runEvalsLoading } = useMutation(
    runEvalsForThread,
    {
      onSuccess() {
        setAlertProps({
          message: "Evals running. This may take a minute or two.",
          color: "green",
        });
      },
    },
  );

  const hasEvalAgent = agentAgentTools?.find(
    (aat) => aat?.childAgent?.type && aat?.childAgent?.type === "evaluation",
  );

  useEffect(() => {
    setGlobalLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (hasEvalAgent) {
      getEvals(hasEvalAgent.agent_id);
    }
  }, [hasEvalAgent]);

  const evalParamLink =
    hasEvalAgent &&
    hasEvalAgent?.childAgent &&
    hasEvalAgent?.childAgent.agent_tool &&
    hasEvalAgent?.childAgent?.agent_tool[0]?.tool_id
      ? hasEvalAgent?.childAgent.agent_tool[0].tool_id
      : null;

  const value = {
    enableEvals,
    hasEvalAgent,
    evaluations,
    evalParamLink,
    runEvals,
    runEvalsLoading,
  };

  return (
    <EvalsToolContext.Provider value={value}>
      {children}
    </EvalsToolContext.Provider>
  );
};

export const EvalsToolContext = createContext({
  enableEvals: {} as UseMutateFunction<EvaluationRow, unknown, string, unknown>,
  hasEvalAgent: {} as AgentAgentToolRow | undefined,
  evaluations: {} as EvaluationRow[] | undefined,
  evalParamLink: null as string | null,
  runEvals: {} as UseMutateFunction<EvaluationRow, unknown, string, unknown>,
  runEvalsLoading: false,
});

export const useEvalsToolContext = () => useContext(EvalsToolContext);
