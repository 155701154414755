import { Dispatch, SetStateAction } from "react";
import { PlusIcon } from "../shared/icons/icons";
import { ChartBarIcon } from "@heroicons/react/24/outline";

function ToolGridHeaderButtons({
  setDialogOpen,
  setDialogTitle,
  dialogOpen,
}: {
  setDialogOpen: Dispatch<SetStateAction<boolean>>;
  setDialogTitle: Dispatch<SetStateAction<string>>;
  dialogOpen: boolean;
}) {
  return (
    <div className="flex w-full flex-wrap space-y-3 p-3 sm:space-x-3 sm:space-y-0">
      <button
        onClick={() => {
          setDialogTitle("Add Agent Tool");
          setDialogOpen(!dialogOpen);
        }}
        type="button"
        className="inline-flex w-full flex-shrink-0 items-center justify-center gap-4 rounded-md bg-skin-accentSecondary px-3 py-2 text-sm font-semibold text-skin-hover shadow-sm hover:bg-skin-accent sm:flex-1"
      >
        <PlusIcon className="h-4 w-4" />
        Add an agent tool
      </button>
      <button
        onClick={() => {
          setDialogTitle("Add Evaluations");
          setDialogOpen(!dialogOpen);
        }}
        type="button"
        className="inline-flex w-full flex-shrink-0 items-center justify-center gap-4 rounded-md bg-skin-accentSecondary px-3 py-2 text-sm font-semibold text-skin-hover shadow-sm hover:bg-skin-accent sm:flex-1"
      >
        <ChartBarIcon className="h-4 w-4" />
        Add evaluations
      </button>
    </div>
  );
}

export default ToolGridHeaderButtons;
