import { ApiEndpoints } from "../api/apiEndpoints";
import { fetchData, setHeaderOptions } from "../api/apiHelpers";
import { SmsMetadataRow } from "./smsMetadata.type";

export function getSmsMetadataById({
  messageId,
}: {
  messageId: string;
}): Promise<SmsMetadataRow[]> {
  const url =
    process.env.REACT_APP_API_URL +
    ApiEndpoints.smsMetadata +
    "/message/" +
    messageId;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData(url, request);
}
