import { formatTimeStampToHumanReadableShortDateTime } from "../../utils/parseTimeStamp";
import { getFirstTwoLettersFromSentence } from "../../utils/stringManipulation";
import { useAgentContext } from "../agent/hooks/AgentContext";
import ThreadNavItemWithSubList from "../layout/ThreadNavItemWithSubList";

function ActiveAgentThreadList() {
  const { activeAgent } = useAgentContext();

  return (
    <div>
      <div className="flex flex-row gap-x-3 p-2 text-sm font-semibold">
        <span
          className={"h-6 w-6 border-skin-accent capitalize text-skin-accent"}
        >
          {activeAgent?.image_path ? (
            <img
              className="h-6 w-6"
              src={activeAgent?.image_path}
              alt={activeAgent?.name}
            />
          ) : (
            getFirstTwoLettersFromSentence(activeAgent?.name)
          )}
        </span>
        <span className="truncate text-skin-base">
          {activeAgent?.name ||
            formatTimeStampToHumanReadableShortDateTime(
              activeAgent?.created_at,
            )}
        </span>
      </div>
      <div className="h-40 w-full overflow-y-auto overflow-x-hidden rounded-lg bg-skin-hover/50 p-2">
        <ul className="-mx-2 mt-2 space-y-1">
          <li className="relative">
            {activeAgent && (
              <ThreadNavItemWithSubList agent={activeAgent} isActive={true} />
            )}
          </li>
        </ul>
      </div>
    </div>
  );
}

export default ActiveAgentThreadList;
