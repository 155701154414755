import { useExtractionResponsesContext } from "../../extraction/responses/ExtractionResponsesContext";
import ExtractionResponsesTable from "../../extraction/responses/ExtractionResponsesTable";

function AgentResponsesContainer() {
  const { extractionResponses } = useExtractionResponsesContext();

  return (
    <div>
      {!extractionResponses || extractionResponses.length === 0 ? (
        <div className="flex h-96 items-center justify-center">
          <p className="text-skin-muted">
            No responses found. To enable extractions add an extraction tool to
            this agent.
          </p>
        </div>
      ) : null}
      <ExtractionResponsesTable extractionResponses={extractionResponses} />
    </div>
  );
}

export default AgentResponsesContainer;
