import ChatLayout from "../../components/layout/ChatLayout";
import LayoutContainer from "../../components/layout/LayoutContainer";

function AgentChatWithThread() {
  return (
    <LayoutContainer>
      <ChatLayout initialMenuType="settings" isOpen={false} />
    </LayoutContainer>
  );
}

export default AgentChatWithThread;
