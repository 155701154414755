import { CheckoutForm } from "../../components/checkout/StripeCheckout";
import LayoutContainer from "../../components/layout/LayoutContainer";
import { mixpanelTrackEvent } from "../../utils/mixpanel";

function CheckoutContainer() {
  mixpanelTrackEvent("View Checkout");
  return (
    <LayoutContainer>
      <div className="w-full p-8">
        <CheckoutForm />
      </div>
    </LayoutContainer>
  );
}

export default CheckoutContainer;
