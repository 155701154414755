import { TrashIcon } from "@heroicons/react/24/outline";
import { Typography } from "@material-tailwind/react";
import { ScheduleRow } from "../../api/schedule.type";
import { formatTimeStampToHumanReadableShortDateTime } from "../../utils/parseTimeStamp";
import {
  TableListContainer,
  TableRowBase,
} from "../shared/table/TableComponents";
import { useScheduleContext } from "./ScheduleContext";
import { EditIcon } from "../shared/icons/icons";

function CronLink({ cronExpression }: { cronExpression: string }) {
  const baseUrl = "https://crontab.guru/#";

  // Ensure the cron expression is URL-safe
  const cronUrl = `${baseUrl}${encodeURIComponent(cronExpression)}`;

  return (
    <a
      href={cronUrl}
      target="_blank"
      rel="noopener noreferrer"
      className="min-w-20 text-skin-accentSecondary underline"
    >
      {cronExpression}
    </a>
  );
}

export function ScheduleViewRow({
  schedule,
  index,
}: {
  schedule: ScheduleRow;
  index: number;
}) {
  const { created_at, sms_content, id, cron_schedule } = schedule;

  const { remove, isEdit, setIsEdit } = useScheduleContext();

  return (
    <TableListContainer key={id}>
      <TableRowBase>
        <Typography variant="small" className="font-normal text-skin-base">
          {formatTimeStampToHumanReadableShortDateTime(created_at)}
        </Typography>
      </TableRowBase>

      <TableRowBase>
        <Typography variant="small" className="font-normal text-skin-base">
          {sms_content?.name}
        </Typography>
      </TableRowBase>
      <TableRowBase>
        <Typography variant="small" className="font-normal text-skin-base">
          {sms_content?.content}
        </Typography>
      </TableRowBase>
      <TableRowBase>
        <CronLink cronExpression={cron_schedule} />
      </TableRowBase>
      <TableRowBase>
        <button
          onClick={(e) => {
            e.preventDefault();
            setIsEdit(!isEdit);
          }}
        >
          <div className="flex flex-row items-center gap-2">
            <EditIcon className="h-4 w-4" />
          </div>
        </button>
      </TableRowBase>
      <TableRowBase>
        <button
          onClick={(e) => {
            e.preventDefault();
            remove(index);
            setIsEdit(true);
          }}
        >
          <div className="flex flex-row items-center gap-2">
            <TrashIcon className="h-4 w-4 text-red-400" />
          </div>
        </button>
      </TableRowBase>
    </TableListContainer>
  );
}
