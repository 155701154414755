import { AgentRow } from "../../api/agent.type";
import { useAgentContext } from "../agent/hooks/AgentContext";
import ActiveLcList from "./LeadCampaignList";
import ThreadNavItem from "./ThreadNavItem";

function ThreadNavItemWithSubList({
  agent,
  isActive,
}: {
  agent: AgentRow;
  isActive: boolean;
}) {
  const { activeAgent } = useAgentContext();

  return (
    <div key={agent.id} className="w-full">
      {activeAgent?.id !== agent.id && (
        <ThreadNavItem a={agent} isActive={isActive} />
      )}
      {activeAgent?.id === agent.id && <ActiveLcList agentId={agent.id} />}
    </div>
  );
}

export default ThreadNavItemWithSubList;
