/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { ExtractionResponseRow } from "../../../../api/extractionResponse.type";
import { SmsMetadataRow } from "../../../../api/smsMetadata.type";
import { handlePageNav } from "../../../shared/table/handlePagination";
import {
  handleFilterValue,
  setHeadFilters,
} from "../../../shared/table/setTableHeadWithFilters";
import TableWithSearch, {
  TableHeadItem,
} from "../../../shared/table/TableWithSearch";
import CalledToolsList from "./CalledToolsList";

const baseTableHead = [
  { label: "Tool" },
  { label: "Input" },
  { label: "Response" },
];

function CalledToolsTable({
  calledTools,
}: {
  calledTools: SmsMetadataRow[] | undefined;
}) {
  const [currentPage, setCurrentPage] = useState(0);

  const [filteredRows, setFilteredRows] = useState<SmsMetadataRow[]>(
    calledTools ? calledTools : [],
  );

  const rowsPerPage = 20;

  const [tableHead, setTableHead] = useState<TableHeadItem[]>(baseTableHead);

  function baseFilter() {
    return (er: Partial<ExtractionResponseRow>, index: number) =>
      index < rowsPerPage;
  }

  const [extractionFilterFunction, setExtractionFilterFunction] =
    useState<(tool: Partial<ExtractionResponseRow>, index: number) => boolean>(
      baseFilter,
    );

  const rowCount =
    calledTools && calledTools.length > 0 ? calledTools.length : null;

  useEffect(() => {
    if (calledTools) {
      setHeadFilters(
        calledTools,
        filteredRows,
        setFilteredRows,
        setTableHead,
        baseTableHead,
      );
    }
  }, [calledTools]);

  return (
    <div className="max-w-fit gap-4">
      {!filteredRows || filteredRows.length === 0 ? (
        <div className="flex h-96 flex-col items-center justify-center gap-4">
          <p>No tools called</p>
        </div>
      ) : null}

      {filteredRows && filteredRows.length > 0 && (
        <TableWithSearch
          tableHead={tableHead}
          rowsPerPage={rowsPerPage}
          rowCount={rowCount}
          currentPage={currentPage}
          handleFilterValue={(value) =>
            handleFilterValue(
              tableHead,
              value,
              calledTools,
              setFilteredRows,
              setExtractionFilterFunction,
              baseFilter,
            )
          }
          handleNextPage={() =>
            handlePageNav({
              currentPage,
              pageSetFunction: setCurrentPage,
              rowsPerPage,
              setFilterFunction: setExtractionFilterFunction,
              advancePage: true,
            })
          }
          handlePreviousPage={() =>
            handlePageNav({
              currentPage,
              pageSetFunction: setCurrentPage,
              rowsPerPage,
              setFilterFunction: setExtractionFilterFunction,
              advancePage: false,
            })
          }
        >
          <CalledToolsList
            calledTools={filteredRows}
            filterFunction={extractionFilterFunction}
          />
        </TableWithSearch>
      )}
    </div>
  );
}

export default CalledToolsTable;
