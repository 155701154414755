import { AgentRow } from "../../api/agent.type";
import ThreadNavItem from "./ThreadNavItem";

interface AgentThreadListProps {
  activeAgent: AgentRow | undefined;
  agents?: AgentRow[];
}

function AgentThreadList({ activeAgent, agents }: AgentThreadListProps) {
  return (
    <>
      {agents && agents.length > 0 && (
        <ul>
          {agents.map((a, index) => {
            const isActive = activeAgent?.id === a.id;

            return <ThreadNavItem key={index} a={a} isActive={isActive} />;
          })}
        </ul>
      )}
    </>
  );
}

export default AgentThreadList;
