import FabrkMenuDivider from "../shared/dividers/FabrkMenuDivider";
import ConnectionDetailsTable from "./ConnectionDetailsTable";

function ViewToolConnectionContainer({
  requiresAuth,
}: {
  requiresAuth: boolean;
}) {
  return (
    <div className="max-w-4xl">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-col gap-4">
          <p className="mt-1 max-w-2xl text-sm leading-6 text-skin-base">
            Tool Connection Details
          </p>
        </div>
      </div>
      <FabrkMenuDivider />
      {requiresAuth ? (
        <ConnectionDetailsTable />
      ) : (
        <div className="flex h-48 items-center justify-center">
          <p className="text-skin-muted">
            This tool does not require connection authentication.
          </p>
        </div>
      )}
    </div>
  );
}

export default ViewToolConnectionContainer;
