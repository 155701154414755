import { Tooltip } from "@material-tailwind/react";
import IconButton from "../../shared/buttons/IconButton";
import { models } from "../../fabrk/config/fabrkModels";
import FabrkMenuDivider from "../../shared/dividers/FabrkMenuDivider";
import { HeadingBase } from "../../shared/typography/TypographyBase";

function AgentModelButtons() {
  return (
    <div className="flex w-full flex-col">
      <div className="flex flex-row justify-between">
        <HeadingBase>Model</HeadingBase>
      </div>
      <FabrkMenuDivider />
      <div className="flex flex-col gap-4">
        <div className="relative mt-4 flex gap-4">
          {models.map((model, index) => {
            return (
              <div key={index}>
                <Tooltip
                  content={model.description || ""}
                  className="bg-skin-hover text-skin-base"
                >
                  <div>
                    <IconButton active={index === 0} key={index}>
                      {model.icon}
                    </IconButton>
                  </div>
                </Tooltip>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default AgentModelButtons;
