import {
  ArrowUturnRightIcon,
  BellAlertIcon,
} from "@heroicons/react/24/outline";
import { Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useCompanyContext } from "../../components/fabrk/hooks/CompanyContext";
import IconButton from "../../components/shared/buttons/IconButton";
import SubmitButton from "../../components/shared/buttons/SubmitButton";
import BaseChip from "../../components/shared/chip/BaseChip";
import GenericDialog from "../../components/shared/dialog/GenericDialog";
import { PlusIcon } from "../../components/shared/icons/icons";
import ApiDocsLink from "../../components/shared/links/ApiDocsLink";
import {
  DataValueBase,
  HeadingBase,
} from "../../components/shared/typography/TypographyBase";
import { truncateStringAddEllipsis } from "../../utils/stringManipulation";

function ApiKeysContainer() {
  const { apiKey, createKey } = useCompanyContext();

  const [showKey, setShowKey] = useState(false);

  const [copied, setCopied] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");

  const handleCopyClick = () => {
    navigator.clipboard.writeText(apiKey?.jwt as string);
    setCopied(true);
  };

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  return (
    <div className="flex flex-col gap-8">
      <div className="flex w-full max-w-lg flex-row justify-between gap-8 rounded-lg border-2 p-2">
        <HeadingBase>API Documentation</HeadingBase>
        <ApiDocsLink />
      </div>
      <div className="flex min-h-64 w-full max-w-lg flex-col gap-8 rounded-lg border-2 p-2">
        <div className="flex w-full flex-row justify-between border-b-2 py-2">
          <HeadingBase>API Key</HeadingBase>

          <IconButton
            onClick={(e?: React.MouseEvent<HTMLButtonElement>) => {
              e?.preventDefault();

              if (apiKey?.jwt) {
                setDialogOpen(true);
                setDialogTitle("Regenerate API Key");
              } else {
                createKey();
              }
            }}
          >
            {apiKey?.jwt ? (
              <ArrowUturnRightIcon className="h-4 w-4" />
            ) : (
              <PlusIcon className="h-4 w-4" />
            )}
            {apiKey?.jwt ? "Regenerate API Key" : "Generate API key"}
          </IconButton>
        </div>

        {apiKey?.jwt && (
          <div className="w-full max-w-md">
            <div className="flex flex-col gap-4">
              <DataValueBase>
                {showKey
                  ? apiKey?.jwt
                  : truncateStringAddEllipsis(apiKey?.jwt, 10)}
              </DataValueBase>
              <div className="flex flex-row gap-2">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleCopyClick();
                  }}
                >
                  <BaseChip>
                    {copied ? "Copied!" : "Copy to clipboard"}
                  </BaseChip>
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setShowKey(!showKey);
                  }}
                >
                  <BaseChip>{showKey ? "Hide Key" : "Show Key"}</BaseChip>
                </button>
              </div>
            </div>
          </div>
        )}
        <GenericDialog
          title={dialogTitle}
          open={dialogOpen}
          setOpen={setDialogOpen}
        >
          <div className="flex flex-col gap-4">
            <div className="flex flex-row justify-center gap-4">
              <BellAlertIcon className="h-8 w-8 text-skin-accent" />
              <Typography variant="paragraph">Warning!</Typography>
            </div>
            <Typography variant="paragraph">
              Regenerating your API key will invalidate the current key.
            </Typography>
            <SubmitButton
              onClick={() => {
                createKey();

                setDialogOpen(false);
                setDialogTitle("");
              }}
            >
              Create New Key
            </SubmitButton>
          </div>
        </GenericDialog>
      </div>
    </div>
  );
}

export default ApiKeysContainer;
