import SignupSuccess from "../../components/auth/SignupSuccess";
import LayoutContainer from "../../components/layout/LayoutContainer";
import { mixpanelTrackEvent } from "../../utils/mixpanel";

function SignupSuccessContainer() {
  mixpanelTrackEvent("Sign up thank you");
  return (
    <LayoutContainer>
      <SignupSuccess />
    </LayoutContainer>
  );
}

export default SignupSuccessContainer;
