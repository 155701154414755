import { Typography } from "@material-tailwind/react";
import { SmsMetadataRow } from "../../../../api/smsMetadata.type";
import CalledToolsTable from "./CalledToolsTable";

function ToolCalledMetadataContainer({
  toolMessages,
}: {
  toolMessages?: SmsMetadataRow[];
}) {
  return (
    <div className={"flex max-w-fit flex-col gap-4 overflow-auto"}>
      {toolMessages && toolMessages.length > 0 && (
        <CalledToolsTable calledTools={toolMessages} />
      )}
      {toolMessages?.length === 0 && (
        <Typography>No tool calls made</Typography>
      )}
    </div>
  );
}

export default ToolCalledMetadataContainer;
