import { ToolRow } from "../../api/tool.type";
import { getToolIcon } from "../fabrk/config/ToolIcons";
import { useAgentToolContext } from "../fabrk/hooks/AgentToolContext";
import { RequiresAuthBadge } from "../shared/chip/RequiresAuthBadge";

function ToolItemHeader({ tool }: { tool: ToolRow }) {
  const { agentTools } = useAgentToolContext();

  const isAdded = agentTools?.some(
    (agentTool) => agentTool.tool.id === tool.id,
  );

  const foundAgentTool = agentTools?.find((a) => a.tool.id === tool.id);

  const { name, description, connection_type } = tool;

  const needsAuth =
    connection_type === "oauth" &&
    foundAgentTool &&
    !foundAgentTool?.connection_id;

  return (
    <div className="relative flex w-full flex-col items-center justify-between gap-4 space-x-6 px-2 py-4">
      <div>
        {needsAuth && (
          <a
            href={`/tool/${tool.id}${isAdded ? `?agentId=${foundAgentTool?.agent_id}` : ""}`}
            className="absolute -right-3 -top-3 flex flex-row gap-2"
          >
            <RequiresAuthBadge />
          </a>
        )}
      </div>
      <div className="grid w-full grid-cols-3 justify-items-center">
        <div className="h-fit w-fit rounded-md bg-skin-accentSecondary p-1 text-skin-inverse">
          {getToolIcon(tool)}
        </div>
        <div className="col-span-2 flex items-center space-x-3">
          <h3 className="text-md text-wrap font-medium text-skin-base">
            {name}
          </h3>
        </div>
      </div>
      <p className="mt-1 text-wrap text-sm text-skin-muted">{description}</p>
    </div>
  );
}

export default ToolItemHeader;
