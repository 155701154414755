import { Typography } from "@material-tailwind/react";

import { useAgentToolContext } from "../../fabrk/hooks/AgentToolContext";
import SubmitButton from "../../shared/buttons/SubmitButton";
import { useToolContext } from "../../tools/hooks/ToolContext";
import { useAgentContext } from "../hooks/AgentContext";
import SecondaryButton from "../../shared/buttons/SecondaryButton";

function AddToolModel({
  setDialogOpen,
}: {
  setDialogOpen: (open: boolean) => void;
}) {
  const { activeAgent } = useAgentContext();
  const { copyAndAddTool, addToolWithoutCopying } = useAgentToolContext();
  const { selectedTool } = useToolContext();

  function addTool(copy: boolean) {
    if (copy) {
      copyAndAddTool({
        toolId: selectedTool?.id as string,
        agentId: activeAgent?.id as string,
      });
    } else {
      addToolWithoutCopying({
        toolId: selectedTool?.id as string,
        agentId: activeAgent?.id as string,
      });
    }
    setDialogOpen(false);
  }

  return (
    <div className="flex flex-col gap-4">
      <Typography>{selectedTool?.description}</Typography>

      <Typography>
        <span className="font-bold">Copy and add</span> will copy this tool and
        add it to this agent. You can then make changes to the tool without
        affecting other agents.
      </Typography>
      <Typography>
        Select <span className="font-bold">Add without copying</span> to add
        this tool without copying it first. Updates will then propagate to all
        agents it is attached to.{" "}
      </Typography>

      <div className="flex flex-row gap-2">
        <SubmitButton type="submit" onClick={() => addTool(true)}>
          Copy and add (default)
        </SubmitButton>
        <SecondaryButton type="submit" onClick={() => addTool(false)}>
          Add without copying
        </SecondaryButton>
      </div>
    </div>
  );
}

export default AddToolModel;
