import { IconButton } from "@material-tailwind/react";

import { DeleteIcon } from "../icons/icons";

function DeleteButton({ onClick }: { onClick: () => void }) {
  return (
    <IconButton onClick={onClick} variant="text" color="red">
      <DeleteIcon className="h-4 w-4" />
    </IconButton>
  );
}

export default DeleteButton;
