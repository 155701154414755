import { useQuery } from "react-query";
import { getClientAgentsByAgent } from "../../../api/clientAgent.api";

export function useLoadAgentThreads(agentId: string) {
  const {
    data: agentThreads,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: "agentThreads",
    queryFn: () => getClientAgentsByAgent(agentId),
    enabled: agentId ? true : false,
  });

  return {
    isLoading,
    agentThreads,
    error,
    refetch,
  };
}
