import { NavigationItem, navigation } from "./LayoutConfig";
import NavItem from "./NavItem";
import SettingsMenu from "./SettingsMenu";
import ThreadListContainer from "./ThreadListContainer";

function LayoutNavContainer({ current }: { current: string }) {
  return (
    <nav className="flex h-screen flex-1 flex-col">
      <ul className="flex flex-1 flex-col gap-y-7">
        <li>
          <ul className="-mx-2 space-y-1">
            {navigation.map((item: NavigationItem) => (
              <li key={item.name}>
                <NavItem item={item} current={current} />
              </li>
            ))}
          </ul>
        </li>
        <ThreadListContainer />

        <li className="mt-auto">
          <SettingsMenu />
        </li>
      </ul>
    </nav>
  );
}

export default LayoutNavContainer;
