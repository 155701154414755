import { Typography } from "@material-tailwind/react";

function DefaultTitleForChat() {
  return (
    <div className="mx-auto mt-3 w-full">
      <Typography variant="h1" className="text-center">
        The World's First <span className="text-skin-accent">Agent Engine</span>
      </Typography>
    </div>
  );
}

export default DefaultTitleForChat;
