import ApiDocsLink from "../../shared/links/ApiDocsLink";
import { LabelBase } from "../../shared/typography/TypographyBase";
import ApiRoutesTable from "./ApiRoutesTable";

function AgentApiDetailsContainer() {
  return (
    <div className="flex flex-col gap-8">
      <div className="flex w-fit flex-row gap-4 rounded-lg border-2 px-4 py-2 sm:px-6 lg:px-8">
        <LabelBase>API Documentation</LabelBase>
        <ApiDocsLink />
      </div>
      <div className="flex w-fit flex-row gap-4 rounded-lg border-2 px-4 py-2 sm:px-6 lg:px-8">
        <ApiRoutesTable />
      </div>
    </div>
  );
}

export default AgentApiDetailsContainer;
