/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import TabContainer, { Tab } from "../shared/containers/Tabs";
import { useToolContext } from "./hooks/ToolContext";
import ParametersContainer from "./ParametersContainer";
import ToolFilesContainer from "./ToolFilesContainer";
import ViewToolContextContainer from "./ViewToolContextContainer";
import ViewToolHeader from "./ViewToolHeader";
import { ExtractionSchemaContextWrapper } from "../fabrk/hooks/ExtractionSchemaContext";
import ViewToolConnectionContainer from "./ViewToolConnectionContainer";
import { useSearchParams } from "react-router-dom";

export default function ViewTool() {
  const tabs = [
    { name: "Context", href: "#context", current: false },
    { name: "Parameters", href: "#parameters", current: true },
    {
      name: "Connection",
      href: "#connection",
      current: false,
      showBadge: false,
    },
    { name: "Files", href: "#files", current: false },
  ];

  const { isFileTool } = useToolContext();

  const [needsAuth, setNeedsAuth] = useState(false);

  const tabFilter = (t: Tab) => isFileTool || t?.name !== "Files";

  const [filteredTabs, setFilteredTabs] = useState<Tab[] | null>(null);

  const [searchParams] = useSearchParams();

  const agentId = searchParams.get("agentId");
  const { tool } = useToolContext();

  const foundAgentTool = tool?.agent_tool?.find((a) => a?.agent_id === agentId);

  useEffect(() => {
    setFilteredTabs(tabs.filter(tabFilter));
  }, [isFileTool]);

  useEffect(() => {
    if (needsAuth) {
      const isAuthenticated = foundAgentTool && foundAgentTool?.connection_id;

      setFilteredTabs((prev) => {
        return (prev || []).map((tab) =>
          tab.name === "Connection"
            ? { ...tab, showBadge: !isAuthenticated }
            : tab,
        );
      });
    }
  }, [needsAuth]);

  useEffect(() => {
    if (foundAgentTool) {
      const authRequired = tool?.connection_type ? true : false;

      setNeedsAuth(authRequired);
    }
  }, [foundAgentTool, tool]);

  return (
    <ExtractionSchemaContextWrapper>
      <div className="h-full min-h-screen bg-skin-fill py-24 sm:py-32">
        <ViewToolHeader />

        <div className="mt-16 max-w-7xl px-6 lg:px-8">
          <TabContainer
            tabs={filteredTabs && filteredTabs.length > 0 ? filteredTabs : tabs}
          >
            {(activeTab: string) => (
              <>
                {activeTab === "Context" && <ViewToolContextContainer />}
                {activeTab === "Parameters" && <ParametersContainer />}
                {activeTab === "Connection" && (
                  <ViewToolConnectionContainer requiresAuth={needsAuth} />
                )}

                {activeTab === "Files" && <ToolFilesContainer />}
              </>
            )}
          </TabContainer>
        </div>
      </div>
    </ExtractionSchemaContextWrapper>
  );
}
