import { Typography } from "@material-tailwind/react";
import { ExtractionResponseFunctionCall } from "../../../../api/extractionResponse.type";
import RecursiveKeyValue from "./RecursiveKeyValue";

function ToolCallResponseInputValue({
  functionCallValue,
}: {
  functionCallValue: string | null;
}) {
  const jsonValue: ExtractionResponseFunctionCall | null = functionCallValue
    ? JSON.parse(functionCallValue)
    : null;

  let parsedParams = null;

  if (jsonValue && jsonValue.params) {
    parsedParams = JSON.parse(jsonValue.params);
  }

  return (
    <div>
      {jsonValue ? (
        <div>
          {parsedParams && (
            <div className="flex flex-col gap-1">
              <RecursiveKeyValue data={parsedParams} />
            </div>
          )}

          {jsonValue.metadata && (
            <div className="flex flex-col gap-1">
              <Typography
                variant="small"
                className="font-semibold capitalize text-skin-base"
              >
                Metadata:
              </Typography>
              <RecursiveKeyValue data={jsonValue.metadata} />
            </div>
          )}
          {jsonValue.defaults && (
            <div className="flex flex-col gap-1">
              <Typography
                variant="small"
                className="font-semibold capitalize text-skin-base"
              >
                Defaults:
              </Typography>
              <RecursiveKeyValue data={jsonValue.defaults} />
            </div>
          )}
        </div>
      ) : (
        <Typography
          variant="small"
          className="max-w-md text-wrap font-normal text-skin-base"
        >
          {functionCallValue}
        </Typography>
      )}
    </div>
  );
}

export default ToolCallResponseInputValue;
