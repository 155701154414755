import React, { useState } from "react";
import { ClientAgentRow } from "../../api/clientAgent.type";
import { useClientAgentContext } from "../fabrk/hooks/ClientAgentContext";
import LcNavItem from "./LcNavItem";
import { useLoadAgentThreads } from "../threads/hooks/useLoadAgentThreads";

interface ClientAgentListProps {
  agentId: string;
}

function ActiveLcList({ agentId }: ClientAgentListProps) {
  const { agentThreads } = useLoadAgentThreads(agentId);
  const { activeClientAgent } = useClientAgentContext();

  // State to manage how many items to display
  const [visibleCount, setVisibleCount] = useState(5);

  // If no agents are available, return nothing
  if (!agentThreads || agentThreads.length === 0) return null;

  const showMoreAmount = 10;

  // Function to show more items
  const showMoreItems = () => {
    setVisibleCount((prevCount) => prevCount + showMoreAmount);
  };

  // Slicing the array to show only the number of visible items
  const visibleCampaigns = agentThreads.slice(0, visibleCount);

  return (
    <>
      <ul className="ml-2">
        {activeClientAgent && agentId === activeClientAgent?.agent_id && (
          <li>
            <LcNavItem lc={activeClientAgent} isActive={true} />
          </li>
        )}
        {visibleCampaigns
          .filter((lc) => lc.id !== activeClientAgent?.id)
          .sort((a, b) => {
            const start = a.created_at;
            const end = b.created_at;
            if (start > end) {
              return -1;
            } else if (start < end) {
              return 1;
            }
            return 0;
          })
          .map((lc: ClientAgentRow) => {
            return (
              <li key={lc.id}>
                <LcNavItem lc={lc} isActive={lc.id === activeClientAgent?.id} />
              </li>
            );
          })}

        {visibleCount < agentThreads.length && (
          <button
            onClick={showMoreItems}
            className="ml-3 mt-2 text-xs text-skin-accent hover:underline"
          >
            Show {showMoreAmount} More
          </button>
        )}
      </ul>
    </>
  );
}

export default ActiveLcList;
