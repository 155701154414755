/* eslint-disable react-hooks/exhaustive-deps */
import { TrashIcon } from "@heroicons/react/24/outline";
import { Checkbox, Typography } from "@material-tailwind/react";
import { Controller } from "react-hook-form";
import { availableQuestionTypes } from "../../../api/extractionSchema.type";
import GenericSelect from "../../shared/forms/GenericSelect";
import { InputBase } from "../../shared/forms/input/InputBase";
import { EditIcon } from "../../shared/icons/icons";
import { ParametersRowPropsWithEdit } from "./ExtractionParametersRowContainer";
import { useExtractionParametersTableContext } from "./ExtractionParametersTableContext";

export function ExtractionParametersEditRow({
  item,
  index,
  isEdit,
  setIsEdit,
}: ParametersRowPropsWithEdit) {
  const { remove, control, handleSetSelectedType } =
    useExtractionParametersTableContext();

  const { id, questionId } = item;

  return (
    <tr key={id} className="even:bg-blue-gray-50/50">
      <td>
        <div className="flex flex-col">
          <Typography variant="small" className="font-normal text-skin-base">
            {questionId}
          </Typography>
        </div>
      </td>

      <td>
        <Controller
          render={({ field }) => {
            return (
              <div>
                <GenericSelect
                  values={availableQuestionTypes.map((type) => {
                    return {
                      value: type,
                      label: type,
                    };
                  })}
                  setSelected={(value) => {
                    field.onChange(value);
                    handleSetSelectedType(index, value);
                  }}
                  selected={field.value}
                />
              </div>
            );
          }}
          name={`questions.${index}.type`}
          control={control}
        />
      </td>

      <td>
        <Controller
          render={({ field }) => {
            return (
              <div>
                <InputBase
                  placeholder="Question"
                  value={field.value as string}
                  setValue={field.onChange}
                />
              </div>
            );
          }}
          name={`questions.${index}.question`}
          control={control}
        />
      </td>

      <td>
        <Controller
          render={({ field }) => (
            <div className="mb-2">
              <Checkbox
                label="Required"
                crossOrigin={"true"}
                {...field}
                checked={field.value ? true : false}
                value={field.value ? "true" : "false"}
              />
            </div>
          )}
          name={`questions.${index}.required`}
          control={control}
        />
      </td>
      <td>
        <div className="flex flex-col">
          <button
            onClick={(e) => {
              e.preventDefault();
              setIsEdit(!isEdit);
            }}
          >
            <div className="flex flex-row items-center gap-2">
              <EditIcon className="h-4 w-4" />
            </div>
          </button>
        </div>
      </td>
      <td>
        <div className="flex flex-col">
          <button
            onClick={(e) => {
              e.preventDefault();
              remove(index);
            }}
          >
            <div className="flex flex-row items-center gap-2">
              <TrashIcon className="h-4 w-4 text-red-400" />
            </div>
          </button>
        </div>
      </td>
    </tr>
  );
}
