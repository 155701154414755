import { ApiEndpoints } from "./apiEndpoints";
import { fetchData } from "./apiHelpers";
import { ClientInsert, ClientWithRelations } from "./client.type";

export function updateLeadById({
  id,
  values,
}: {
  id: string;
  values: ClientInsert;
}): Promise<ClientWithRelations> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.client + "/fabrk/" + id;

  const request = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...values,
    }),
  };

  return fetchData<ClientWithRelations>(url, request);
}

export function getClientById(id: string): Promise<ClientWithRelations> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.client + "/fabrk/" + id;

  const request = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  return fetchData<ClientWithRelations>(url, request);
}
