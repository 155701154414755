/* eslint-disable react-hooks/exhaustive-deps */
import { DiscoverGrid, PageHeader } from "../shared/containers/Containers";
import CreateToolGridItem from "./CreateToolGridItem";
import { useLoadCreateToolsList } from "./hooks/useCreateToolsList";

function CreateTool() {
  const { createToolsList } = useLoadCreateToolsList();

  return (
    <div className="mx-10 h-full min-h-screen bg-skin-fill py-4 md:py-10">
      <PageHeader title="Tool Creation" />
      <DiscoverGrid>
        {createToolsList?.map((tool) => {
          return <CreateToolGridItem key={tool.id} toolItem={tool} />;
        })}
      </DiscoverGrid>
    </div>
  );
}

export default CreateTool;
