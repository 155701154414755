/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import { UseMutateFunction, useMutation, useQueryClient } from "react-query";
import {
  addAgentTool,
  copyAndAddAgentTool,
  deleteAiCampaignFunction,
  getCampaignToolByCampaign,
} from "../../../api/agentTool.api";
import { AgentToolRow } from "../../../api/agentTool.type";
import { createCompanyTool } from "../../../api/companyTool.api";
import { createFileStoreTool } from "../../../api/fileStore.api";
import { AddFileStoreToTool, FileStoreRow } from "../../../api/fileStore.type";
import { ToolCreate, ToolRow } from "../../../api/tool.type";
import { useAgentContext } from "../../agent/hooks/AgentContext";
import { useAlertContext } from "../../shared/alert/alertContext";
import { useToolContext } from "../../tools/hooks/ToolContext";
import { toolIcons } from "../config/ToolIcons";

export const AgentToolContextWrapper = (props: any) => {
  const { setAlertProps, setGlobalLoading } = useAlertContext();
  const queryClient = useQueryClient();

  const { activeAgent } = useAgentContext();
  const { handleGetTool } = useToolContext();

  const [newAgentType, setNewAgentType] = useState("");
  const [dialogTitle, setDialogTitle] = useState("");

  const [selectedTools, setSelectedTools] = useState<{
    [key: string]: boolean;
  }>({});

  const { mutate: getCampaignTools, data: agentTools } = useMutation(
    getCampaignToolByCampaign,
    {
      onSuccess: async (res) => {},
      onError(error: Error) {
        console.log(error.message);
      },
    },
  );

  const { mutate: createTool } = useMutation(createCompanyTool, {
    onSuccess: async (res) => {
      queryClient.invalidateQueries("agents");
      queryClient.invalidateQueries({ queryKey: ["agents", "clientAgents"] });
      getCampaignTools(activeAgent?.id as string);
      setAlertProps({
        message: "Agent created",
        color: "green",
      });
    },
    onError(error: Error) {
      setAlertProps({
        message: error.message,
        color: "red",
      });
    },
  });

  const { mutate: copyAndAddTool, isLoading: addToolLoading } = useMutation(
    copyAndAddAgentTool,
    {
      onSuccess: async (res) => {
        queryClient.invalidateQueries("agents");

        queryClient.invalidateQueries({ queryKey: ["agents", "clientAgents"] });
        getCampaignTools(activeAgent?.id as string);
        setAlertProps({
          message: "Tool added",
          color: "green",
        });
      },
      onError(error: Error) {
        setAlertProps({
          message: error.message,
          color: "red",
        });
      },
    },
  );

  const { mutate: addToolWithoutCopying, isLoading: addTool } = useMutation(
    addAgentTool,
    {
      onSuccess: async (res) => {
        queryClient.invalidateQueries("agents");

        queryClient.invalidateQueries({ queryKey: ["agents", "clientAgents"] });
        getCampaignTools(activeAgent?.id as string);
        setAlertProps({
          message: "Tool added",
          color: "green",
        });
      },
      onError(error: Error) {
        setAlertProps({
          message: error.message,
          color: "red",
        });
      },
    },
  );

  const { mutate: addFile } = useMutation(createFileStoreTool, {
    onSuccess: async (res) => {
      queryClient.invalidateQueries({ queryKey: ["agents", "clientAgents"] });
      getCampaignTools(activeAgent?.id as string);
      handleGetTool();
      setAlertProps({
        message: "Tool created",
        color: "green",
      });
    },
    onError(error: Error) {
      console.log(error.message);
      setAlertProps({
        message: error.message,
        color: "red",
      });
    },
    onSettled() {
      setGlobalLoading(false);
    },
  });

  const { mutate: removeAgent } = useMutation(deleteAiCampaignFunction, {
    onSuccess: async (res) => {
      queryClient.invalidateQueries({ queryKey: ["agents", "clientAgents"] });

      setAlertProps({
        message: "Tool removed",
        color: "green",
      });
      getCampaignTools(activeAgent?.id as string);
    },
    onError(error: Error) {
      console.log(error.message);
      setAlertProps({
        message: error.message,
        color: "red",
      });
    },
  });

  useEffect(() => {
    if (activeAgent) {
      getCampaignTools(activeAgent.id as string);
    }
  }, [activeAgent]);

  useEffect(() => {
    if (agentTools && toolIcons) {
      setSelectedTools(
        toolIcons?.reduce(
          (acc, agent) => {
            if (!agent) return acc;
            if (
              agentTools?.find(
                (a) =>
                  a.tool?.slug === agent?.slug || a.tool?.type === agent?.slug,
              )
            ) {
              acc[agent?.name] = true;
            } else {
              acc[agent?.name] = false;
            }
            return acc;
          },
          {} as { [key: string]: boolean },
        ),
      );
    }
  }, [agentTools]);

  useEffect(() => {
    setGlobalLoading(addToolLoading);
  }, [addToolLoading]);

  const value = {
    createTool,
    copyAndAddTool,
    removeAgent,
    newAgentType,
    setNewAgentType,
    dialogTitle,
    setDialogTitle,
    addFile,
    agentTools,
    loading: addToolLoading || addTool,
    selectedTools,
    addToolWithoutCopying,
  };

  return (
    <AgentToolContext.Provider value={value}>
      {props.children}
    </AgentToolContext.Provider>
  );
};

export const AgentToolContext = createContext({
  createTool: {} as UseMutateFunction<ToolRow, Error, ToolCreate, unknown>,
  copyAndAddTool: {} as UseMutateFunction<
    AgentToolRow[],
    Error,
    {
      agentId: string;
      toolId: string;
    },
    unknown
  >,
  removeAgent: {} as UseMutateFunction<
    AgentToolRow[],
    Error,
    {
      agentId: string;
      toolId: string;
    },
    unknown
  >,

  newAgentType: "",
  setNewAgentType: {} as React.Dispatch<React.SetStateAction<string>>,
  dialogTitle: "",
  setDialogTitle: {} as React.Dispatch<React.SetStateAction<string>>,
  addFile: {} as UseMutateFunction<
    FileStoreRow,
    Error,
    AddFileStoreToTool,
    unknown
  >,

  agentTools: [] as AgentToolRow[] | undefined,
  loading: false,
  selectedTools: {} as { [key: string]: boolean },
  addToolWithoutCopying: {} as UseMutateFunction<
    AgentToolRow[],
    Error,
    {
      agentId: string;
      toolId: string;
    },
    unknown
  >,
});

export const useAgentToolContext = () => useContext(AgentToolContext);
