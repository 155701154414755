import { FaceSmileIcon } from "@heroicons/react/24/outline";
import { parseCentsToDollars } from "../../utils/currencyManipulation";
import { formatTimeStampToHumanReadableShortDateTime } from "../../utils/parseTimeStamp";
import { useCompanyContext } from "../fabrk/hooks/CompanyContext";
import { useLoadPayments } from "./hooks/useLoadPayments";

function PaymentHistoryCard() {
  const { payments } = useLoadPayments();

  const { company } = useCompanyContext();

  return (
    <div className="flex max-w-4xl flex-col gap-y-4 rounded-lg border-2 border-skin-hover bg-skin-fill p-8 shadow">
      <dt className="text-base leading-7 text-skin-base/80">Payment History</dt>
      <dd className="text-xl font-semibold tracking-tight text-skin-base sm:text-2xl">
        {payments?.length === 0 ? "No payment history" : ""}
      </dd>

      <ul className="flex w-full flex-col gap-1">
        <li className="grid grid-cols-4 gap-2 rounded-lg bg-skin-accentSecondary px-2 text-skin-inverse shadow-md">
          <span className="font-semibold">Date</span>
          <span className="font-semibold">Source</span>
          <span className="font-semibold">Amount</span>
          <span className="font-semibold">Status</span>
        </li>
        <li className="grid grid-cols-4 gap-2 px-2">
          <span>
            {formatTimeStampToHumanReadableShortDateTime(company?.created_at)}
          </span>
          <span>Fabrk AI Free Credits</span>
          <span>$25.00</span>
          <span>
            <FaceSmileIcon className="h-6 w-6 rounded-lg" />
          </span>
        </li>
        {payments?.map((payment, index) => {
          return (
            <li
              key={payment.id}
              className={`grid grid-cols-4 gap-2 ${
                index % 2 === 0 ? "rounded-lg bg-skin-hover/80 px-2" : "px-2"
              }`}
            >
              <span>
                {formatTimeStampToHumanReadableShortDateTime(
                  payment.created_at,
                )}
              </span>
              <span className="capitalize">
                {payment.payment_method_type} {payment.last4}
              </span>
              <span>{parseCentsToDollars(payment.amount)}</span>
              <span className="capitalize">{payment.status}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default PaymentHistoryCard;
