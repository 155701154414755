import { ViewClientContextWrapper } from "../../components/client/hooks/ViewClientContext";
import ViewClient from "../../components/client/ViewClient";
import LayoutContainer from "../../components/layout/LayoutContainer";
import { mixpanelTrackEvent } from "../../utils/mixpanel";

function ViewClientContainer() {
  mixpanelTrackEvent("View Client");
  return (
    <ViewClientContextWrapper>
      <LayoutContainer>
        <ViewClient />
      </LayoutContainer>
    </ViewClientContextWrapper>
  );
}

export default ViewClientContainer;
