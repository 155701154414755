import {
  IconButton,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Typography,
} from "@material-tailwind/react";
import { Fragment } from "react";

import { ToolRow } from "../../api/tool.type";
import { formatTimeStampToHumanReadableShortDateTime } from "../../utils/parseTimeStamp";

import { Link, useNavigate } from "react-router-dom";
import { snakeCaseToTitleCase } from "../../utils/stringManipulation";
import { getToolIcon } from "../fabrk/config/ToolIcons";
import { EditIcon, EllipsisVertical } from "../shared/icons/icons";
import {
  TableListContainer,
  TableRowBase,
} from "../shared/table/TableComponents";

function ToolTableList({
  tool,
  handleContentClick,
  filterFunction,
}: {
  tool: Partial<ToolRow>[] | undefined;
  handleContentClick: (id: string) => void;
  filterFunction?: (tool: Partial<ToolRow>, index: number) => boolean;
}) {
  const navigate = useNavigate();

  function mapToolToRow(tool: Partial<ToolRow>): {
    id: string;
    name: string;
    createdAt: string;
    type: string;
    imageUrl: string;
    toolIcon: React.JSX.Element;
  } {
    const values = {
      id: tool.id as string,
      name: tool?.name ? tool?.name : "N/A",
      createdAt: tool.created_at
        ? formatTimeStampToHumanReadableShortDateTime(tool.created_at)
        : "N/A",

      type: tool.type ? tool.type : "N/A",
      imageUrl: tool.image_path ? tool.image_path : "",
      toolIcon: getToolIcon({
        slug: tool.slug,
        name: tool.name,
        type: tool.type,
      }),
    };

    return values;
  }
  return (
    <Fragment>
      {tool &&
        tool.length > 0 &&
        tool
          .filter((item, index) => filterFunction?.(item, index) ?? true)
          .map((item) => mapToolToRow(item))
          .map(({ id, name, createdAt, type, imageUrl, toolIcon }, index) => {
            const isLast = index === tool.length - 1;
            const classes = isLast
              ? "p-4"
              : "p-4 border-b border-skin-hover/50";

            const link = type === "agent" ? `/agent/${id}` : `/tool/${id}`;

            return (
              <TableListContainer key={id}>
                <TableRowBase>
                  <div className="w-fit">
                    {imageUrl ? (
                      <img
                        alt="Tool icon"
                        src={imageUrl}
                        className="ml-1 h-6 w-6 rounded-full"
                      />
                    ) : (
                      <div className="rounded-full bg-skin-hover p-1">
                        {toolIcon}{" "}
                      </div>
                    )}
                  </div>
                </TableRowBase>
                <TableRowBase>
                  <div className="flex flex-col">
                    <Link to={link}>
                      <Typography
                        variant="small"
                        className="font-normal text-skin-base"
                      >
                        {name}
                      </Typography>
                    </Link>
                  </div>
                </TableRowBase>
                <TableRowBase>
                  <Typography
                    variant="small"
                    className="font-normal text-skin-base"
                  >
                    {createdAt}
                  </Typography>
                </TableRowBase>

                <TableRowBase>
                  <Typography
                    variant="small"
                    className="font-normal capitalize text-skin-base"
                  >
                    {snakeCaseToTitleCase(type)}
                  </Typography>
                </TableRowBase>

                <td className={classes}>
                  <IconButton
                    onClick={() => handleContentClick(id)}
                    variant="text"
                    className="text-skin-base"
                  >
                    <EditIcon className="h-4 w-4" />
                  </IconButton>
                </td>
                <td className={classes}>
                  <Menu placement="top">
                    <MenuHandler>
                      <IconButton variant="text" className="text-skin-base">
                        <EllipsisVertical className="h-4 w-4" />
                      </IconButton>
                    </MenuHandler>
                    <MenuList>
                      <MenuItem
                        onClick={() => {
                          navigate(`/tool/${id}`);
                        }}
                      >
                        Page
                      </MenuItem>
                      {/* <MenuItem
                        onClick={() => {
                          console.log("Duplicate");
                        }}
                      >
                        Duplicate
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          console.log("Pause/Activate");
                        }}
                      >
                        Pause
                      </MenuItem>
                      <MenuItem disabled={true}>Delete</MenuItem> */}
                    </MenuList>
                  </Menu>
                </td>
              </TableListContainer>
            );
          })}
    </Fragment>
  );
}

export default ToolTableList;
