export enum QuestionTypes {
  TEXT = "text",
  SELECT = "select",
  MULTI_SELECT = "multi_select",
  DATE = "date",
  NUMBER = "number",
  BOOLEAN = "boolean",
}

export const availableQuestionTypes = [
  QuestionTypes.TEXT,
  QuestionTypes.BOOLEAN,
  QuestionTypes.NUMBER,
];

export type SchemaBase = {
  type: QuestionTypes;
  id: string;
  question: string;
  required: boolean;
  options?: string[];
};

export type ExtractionSchemaRow = {
  id: string;
  created_at: string;
  updated_at: string;
  name: string;
  schema: SchemaBase[];
};

export type CreateExtractionSchema = {
  id?: string;
  name: string;
  toolId: string;
  agentId: string;
  schema: SchemaBase[];
};

export type ExtractionSchemaAgentRow = {
  id: string;
  agent_id: string;
  extraction_schema_id: string;
  created_at: string;
  updated_at: string;
  extraction_schema: ExtractionSchemaRow;
};
