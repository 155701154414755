/* eslint-disable react-hooks/exhaustive-deps */
import AgentVisibilityList from "../../../agent/view/visibility/AgentVisibilityList";

function VisibilitySettingsDialog({
  setDialogOpen,
}: {
  setDialogOpen: (open: boolean) => void;
}) {
  return (
    <div className="flex w-full flex-col gap-4 p-5">
      <AgentVisibilityList handleSubmit={() => setDialogOpen(false)} />
    </div>
  );
}

export default VisibilitySettingsDialog;
