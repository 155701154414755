import { Typography } from "@material-tailwind/react";
import React from "react";

function RecursiveKeyValue({ data }: { data: Record<string, any> }) {
  if (!data) {
    return null;
  }

  return (
    <div>
      {Object.keys(data).map((key) => {
        const value = data[key];
        const isObject = typeof value === "object" && value !== null;

        return (
          <div key={key} className="flex flex-col gap-2">
            <div className="flex gap-2">
              <Typography
                variant="small"
                className="font-semibold capitalize text-skin-base"
              >
                {key}:
              </Typography>
              {!isObject && (
                <Typography
                  variant="small"
                  className="font-normal text-skin-base"
                >
                  {String(value)}
                </Typography>
              )}
            </div>
            {isObject && <RecursiveKeyValue data={value} />}
          </div>
        );
      })}
    </div>
  );
}

export default RecursiveKeyValue;
