/* eslint-disable react-hooks/exhaustive-deps */
import { Tooltip } from "@material-tailwind/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToolRow } from "../../api/tool.type";
import { getToolIcon } from "../fabrk/config/ToolIcons";
import SubmitButton from "../shared/buttons/SubmitButton";
import { CreateToolGridItemContainer } from "../shared/containers/Containers";
import { useToolContext } from "./hooks/ToolContext";

function CreateToolGridItem({ toolItem }: { toolItem: ToolRow }) {
  const navigate = useNavigate();

  const { handleForkTool, tool } = useToolContext();

  const { name, id, description, slug, type } = toolItem;

  useEffect(() => {
    if (tool) {
      navigate(`/tool/${tool.id}`);
    }
  }, [tool]);

  return (
    <CreateToolGridItemContainer key={id}>
      <div className="flex h-full w-full flex-col justify-between gap-4">
        <div className="flex flex-col gap-12">
          <div className="flex flex-row items-center gap-12">
            <div className="rounded-full bg-skin-fill p-1">
              {getToolIcon({
                name,
                slug,
                type,
              })}
            </div>
            <h3 className="text-2xl font-semibold leading-6 text-skin-base group-hover:text-skin-hover">
              <span />
              {name}
            </h3>
          </div>
          <p className="text-md leading-6 text-skin-base group-hover:text-skin-hover">
            <span />
            {description}
          </p>
        </div>
        <div>
          <Tooltip
            placement="bottom"
            content="Create a new tool using this template"
            className="bg-skin-hover text-skin-base"
          >
            <div>
              <SubmitButton onClick={() => handleForkTool(id)}>
                Create Tool
              </SubmitButton>
            </div>
          </Tooltip>
        </div>
      </div>
    </CreateToolGridItemContainer>
  );
}

export default CreateToolGridItem;
