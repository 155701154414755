import ViewAgent from "../../components/agent/view/ViewAgent";
import LayoutContainer from "../../components/layout/LayoutContainer";
import { mixpanelTrackEvent } from "../../utils/mixpanel";

function ViewAgentContainer() {
  mixpanelTrackEvent("View Agent");
  return (
    <LayoutContainer>
      <ViewAgent />
    </LayoutContainer>
  );
}

export default ViewAgentContainer;
