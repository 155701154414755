import { Typography } from "@material-tailwind/react";
import { ExtractionResponseRow } from "../../../api/extractionResponse.type";
import { formatTimeStampToHumanReadableShortDateTime } from "../../../utils/parseTimeStamp";
import { snakeCaseToTitleCase } from "../../../utils/stringManipulation";
import {
  TableListContainer,
  TableRowBase,
} from "../../shared/table/TableComponents";

export function ExtractionResponsesViewRow({
  item,
}: {
  item: ExtractionResponseRow;
}) {
  const {
    id,
    created_at,
    question_id,
    question_name,
    question_type,
    question_answer,
    client_id,
  } = item;

  return (
    <TableListContainer key={id}>
      <TableRowBase>
        <Typography variant="small" className="font-normal text-skin-base">
          {question_id}
        </Typography>
      </TableRowBase>

      <TableRowBase>
        <Typography variant="small" className="font-normal text-skin-base">
          {formatTimeStampToHumanReadableShortDateTime(created_at)}
        </Typography>
      </TableRowBase>

      <TableRowBase>
        <Typography
          variant="small"
          className="max-w-14 truncate font-normal capitalize text-skin-base"
        >
          {client_id}
        </Typography>
      </TableRowBase>

      <TableRowBase>
        <Typography
          variant="small"
          className="font-normal capitalize text-skin-base"
        >
          {snakeCaseToTitleCase(question_type)}
        </Typography>
      </TableRowBase>

      <TableRowBase>
        <Typography
          variant="small"
          className="font-normal capitalize text-skin-base"
        >
          {question_name}
        </Typography>
      </TableRowBase>

      <TableRowBase>
        <Typography
          variant="small"
          className="font-normal capitalize text-skin-base"
        >
          {question_answer}
        </Typography>
      </TableRowBase>
    </TableListContainer>
  );
}
