import { PencilIcon } from "@heroicons/react/24/outline";
import { useMemo, useState } from "react";
import { useCompanyContext } from "../../components/fabrk/hooks/CompanyContext";
import IconButton from "../../components/shared/buttons/IconButton";
import SubmitButton from "../../components/shared/buttons/SubmitButton";
import { InputBase } from "../../components/shared/forms/input/InputBase";
import {
  DataValueBase,
  HeadingBase,
  LabelBase,
} from "../../components/shared/typography/TypographyBase";

function ChatConfigContainer() {
  const { companyConfig, updateConfig } = useCompanyContext();

  const [isEditing, setIsEditing] = useState(false);

  const [borderColor, setBorderColor] = useState("");
  const [font, setFont] = useState("");

  useMemo(() => {
    if (companyConfig) {
      if (companyConfig.color_one) {
        setBorderColor(companyConfig.color_one);
      }
      if (companyConfig.google_font) {
        setFont(companyConfig.google_font);
      }
    }
  }, [companyConfig]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    updateConfig({
      id: companyConfig?.id as string,
      values: {
        ...(borderColor && { color_one: borderColor }),
        ...(font && { google_font: font }),
      },
    });

    setIsEditing(false);
  };

  return (
    <div className="flex flex-col gap-8">
      <div className="flex w-full max-w-lg flex-row justify-between gap-8 rounded-lg border-2 p-2">
        <HeadingBase>Chat Config</HeadingBase>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="flex min-h-64 max-w-lg flex-col gap-8 rounded-lg border-2 p-2">
          <div className="flex flex-row justify-between border-b-2 py-2">
            <HeadingBase>Project Overview</HeadingBase>
            <IconButton
              onClick={(e?: React.MouseEvent<HTMLButtonElement>) => {
                e?.preventDefault();

                setIsEditing(!isEditing);
              }}
            >
              <PencilIcon className="h-4 w-4" />
            </IconButton>
          </div>

          <div className="flex flex-col gap-4">
            <div>
              <LabelBase>Border Color</LabelBase>
              {isEditing ? (
                <InputBase value={borderColor} setValue={setBorderColor} />
              ) : (
                <DataValueBase>{companyConfig?.color_one}</DataValueBase>
              )}
            </div>

            <div>
              <LabelBase>Font</LabelBase>
              {isEditing ? (
                <InputBase value={font} setValue={setFont} />
              ) : (
                <DataValueBase>{companyConfig?.google_font}</DataValueBase>
              )}
            </div>
          </div>
          {isEditing && (
            <div className="flex justify-end">
              <div>
                <SubmitButton type="submit" onClick={handleSubmit} />
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
}

export default ChatConfigContainer;
