import React from "react";
import { useAlertContext } from "../alert/alertContext";

const LoadingSpinnerWithBackdrop: React.FC = () => {
  const { loadingText, globalLoading } = useAlertContext();
  return (
    <>
      {globalLoading && (
        <div className="fixed inset-0 z-50 flex flex-col items-center justify-center gap-8 bg-gray-500 bg-opacity-50">
          {loadingText && <p className="text-skin-base">{loadingText}</p>}
          <div className="h-16 w-16 animate-spin rounded-full border-b-4 border-t-4 border-skin-accent"></div>
        </div>
      )}
    </>
  );
};

export default LoadingSpinnerWithBackdrop;
