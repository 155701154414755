import {
  MagnifyingGlassIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import DiscoverAgentListItem from "../../components/agent/discover/DiscoverAgentListItem";
import { useLoadPublicAgents } from "../../components/agent/hooks/usePublicAgents";
import LoadingSpinner from "../../components/shared/forms/LoadingSpinner";
import { PlusIcon } from "../../components/shared/icons/icons";
import LayoutContainer from "../../components/layout/LayoutContainer";

import { DiscoverGrid } from "../../components/shared/containers/Containers";
import { useAgentContext } from "../../components/agent/hooks/AgentContext";
import { mixpanelTrackEvent } from "../../utils/mixpanel";

function DiscoverAgentsContainer() {
  mixpanelTrackEvent("View Discover Agents");
  const { publicAgents, isLoading } = useLoadPublicAgents();
  const { handleCreateNewAgentWithDefaults } = useAgentContext();

  return (
    <LayoutContainer>
      <div className="h-full min-h-screen bg-skin-fill py-10 sm:py-24">
        <div className="mx-auto flex max-w-7xl justify-between px-6">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="flex flex-row items-center gap-4 text-3xl font-bold tracking-tight text-skin-base sm:text-4xl">
              <UserGroupIcon className="h-8 w-8" />
              Public Agents
            </h2>

            <p className="mt-2 text-lg leading-8 text-skin-base">
              List of public agents that you can explore and use
            </p>
          </div>
          <div className="mt-10 flex items-center gap-x-6">
            <button
              className="flex flex-row items-center gap-2 rounded-md bg-skin-accent px-3.5 py-2.5 text-sm font-semibold text-skin-inverse shadow-sm hover:bg-skin-accentSecondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
              onClick={() => handleCreateNewAgentWithDefaults()}
            >
              <PlusIcon className="h-5 w-5" />
              Create Agent
            </button>
            <a
              href="/discover/tools"
              className="flex flex-row items-center gap-2 rounded-md px-3.5 py-2.5 text-sm font-semibold leading-6 text-skin-base hover:bg-skin-hover"
            >
              <MagnifyingGlassIcon className="h-5 w-5" />
              Discover Tools
            </a>
          </div>
        </div>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <DiscoverGrid>
            {publicAgents?.map((c, index) => (
              <div key={index}>
                <DiscoverAgentListItem c={c} />
              </div>
            ))}
          </DiscoverGrid>
        )}
      </div>
    </LayoutContainer>
  );
}

export default DiscoverAgentsContainer;
