import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { MenuItem } from "@material-tailwind/react";
import { useState } from "react";

import DropdownMenu from "../menu/DropdownMenu";
import { FilterValue, TableHeadItem } from "./TableWithSearch";
import clsx from "clsx";

export function ColumnFilter({
  head,
  handleFilterValue,
}: {
  head: TableHeadItem;
  handleFilterValue?: (value: FilterValue) => void;
}) {
  const [filterValue, setFilterValue] = useState<string | null>(null);

  return (
    <DropdownMenu
      className="absolute -left-10 top-10 z-50 mt-2 h-60 w-56 origin-top-right overflow-auto rounded-md border-2 border-skin-hover bg-skin-hover/80 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
      MenuButtonChild={
        <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
      }
    >
      {head.filterValues &&
        head.filterValues.length > 0 &&
        head.filterValues.map((value, idx) => (
          <MenuItem key={idx}>
            <button
              key={idx}
              onClick={() => {
                setFilterValue(value);
                if (handleFilterValue) {
                  handleFilterValue({ value: value, column: head.label });
                }
              }}
              className={clsx(
                filterValue === value
                  ? "w-full rounded-lg bg-skin-accentSecondary px-4 py-2 text-skin-inverse"
                  : "group flex w-full items-center rounded-lg bg-skin-fill px-4 py-2 text-sm text-skin-base hover:bg-skin-accentSecondary hover:text-skin-inverse data-[focus]:bg-skin-hover data-[focus]:text-skin-base",
              )}
            >
              {value}
            </button>
          </MenuItem>
        ))}
    </DropdownMenu>
  );
}

export default ColumnFilter;
