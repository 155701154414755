import { Route, Routes } from "react-router-dom";
import "./App.css";
import ViewAgentContainer from "./pages/agent/ViewAgentContainer";
import AgentsContainer from "./pages/agents/AgentsContainer";
import AgentChat from "./pages/chat/AgentChat";
import AgentChatWithThread from "./pages/chat/AgentChatWithThread";
import CheckoutContainer from "./pages/checkout/CheckoutContainer";
import CheckoutReturnContainer from "./pages/checkout/CheckoutReturnContainer";
import ViewClientContainer from "./pages/client/ViewClientContainer";
import DesignContainer from "./pages/design/DesignContainer";
import DiscoverAgentsContainer from "./pages/discover/DiscoverAgentsContainer";
import DiscoverToolsContainer from "./pages/discover/DiscoverToolsContainer";
import HomeContainer from "./pages/home/HomeContainer";
import ViewProjectContainer from "./pages/project/ViewProjectDetails";
import Login from "./pages/signup/Login";
import SignupSuccessContainer from "./pages/signup/SignupSuccessContainer";
import CreateToolContainer from "./pages/tool/CreateToolContainer";
import ViewToolContainer from "./pages/tool/ViewToolContainer";
import ToolsContainer from "./pages/tools/ToolsContainer";
import ViewUserContainer from "./pages/user/ViewUserDetails";

function App() {
  return (
    <Routes>
      <Route path="*" element={<AgentsContainer />} />
      <Route path="/discover/agents" element={<DiscoverAgentsContainer />} />
      <Route path="/discover/tools" element={<DiscoverToolsContainer />} />
      <Route path="/agents" element={<AgentsContainer />} />
      {/* <Route path="/clients" element={<AgentsContainer />} /> */}
      <Route path="/client/:clientId" element={<ViewClientContainer />} />

      <Route path="/tool/create" element={<CreateToolContainer />} />
      <Route path="/tools" element={<ToolsContainer />} />
      <Route path="/tool/:id" element={<ViewToolContainer />} />

      <Route path="/user/:id" element={<ViewUserContainer />} />
      <Route path="/reset-password" element={<ViewUserContainer />} />

      <Route path="/project/:id" element={<ViewProjectContainer />} />
      <Route path="/project" element={<ViewProjectContainer />} />

      <Route path="/login" element={<Login viewType="Log In" />} />
      <Route path="/signup" element={<Login viewType="Sign Up" />} />

      <Route path="/signup/success" element={<SignupSuccessContainer />} />

      <Route path="/agent/:agentId" element={<ViewAgentContainer />} />

      <Route path="/t" element={<HomeContainer />} />
      <Route path="/t/*" element={<HomeContainer />} />

      <Route path="/a/:agentId/t/:threadId" element={<AgentChatWithThread />} />
      <Route path="/a/:agentId" element={<AgentChat />} />

      <Route path="/design" element={<DesignContainer />} />
      <Route path="/checkout" element={<CheckoutContainer />} />
      <Route path="/checkout/return" element={<CheckoutReturnContainer />} />
    </Routes>
  );
}
export default App;
