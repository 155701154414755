import ExtractionParametersForm from "./ExtractionParametersForm";
import { useToolContext } from "./hooks/ToolContext";
import StandardParametersForm from "./StandardParametersForm";

function ParametersContainer() {
  const { tool } = useToolContext();

  return (
    <div>
      {tool?.type === "extraction" ? (
        <ExtractionParametersForm />
      ) : (
        <StandardParametersForm />
      )}
    </div>
  );
}

export default ParametersContainer;
