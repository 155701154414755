/* eslint-disable react-hooks/exhaustive-deps */
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { Button } from "@material-tailwind/react";
import { useEffect } from "react";
import { AgentType, AgentTypes } from "../../../api/agent.type";
import { FabrkTextArea } from "../../shared/forms/FabrkTextArea";
import GenericSelect from "../../shared/forms/GenericSelect";
import { FabrkInput } from "../../shared/forms/input/InputBase";
import { LabelBase } from "../../shared/typography/TypographyBase";
import { useAgentContext } from "../hooks/AgentContext";

type AgentNameFormValues = {
  name: string;
  goal: string;
  type?: AgentType;
  prompt: string;
  slug: string;
};

function EditAgentName({ setIsEdit }: { setIsEdit: (value: boolean) => void }) {
  const { activeAgent, updateAgent, updatePrompt, checkSlug, slugSuggestions } =
    useAgentContext();

  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      name: activeAgent?.name || "",
      goal: activeAgent?.goal || "",
      type: activeAgent?.type,
      prompt: activeAgent?.system_prompt?.text || "",
      slug: activeAgent?.slug || "",
    },
  });

  const onSubmit: SubmitHandler<AgentNameFormValues> = (
    data: AgentNameFormValues,
  ) => {
    const values = {
      ...(data.name && { name: data.name }),
      ...(data.goal && { goal: data.goal }),
      ...(data.type && { type: data.type }),
      ...(data.slug && { slug: data.slug }),
      id: activeAgent?.id as string,
    };
    if (data.prompt) {
      updatePrompt({
        id: activeAgent?.system_prompt?.id as string,
        text: data.prompt,
      });
    }
    updateAgent(values);
    setIsEdit(false);
    reset();
  };

  useEffect(() => {
    const debouncedCheckSlug = setTimeout(() => {
      const newSlug = watch("slug");
      if (newSlug !== activeAgent?.slug) {
        checkSlug(watch("slug"));
      }
    }, 1500);

    return () => {
      clearTimeout(debouncedCheckSlug);
    };
  }, [watch("slug")]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-5 p-2">
          <div>
            <LabelBase>Name</LabelBase>
            <FabrkInput
              register={register}
              errors={errors}
              label="Name"
              name="name"
              required={true}
            />
          </div>
          <div className="flex flex-col gap-2">
            <div>
              <LabelBase>Slug</LabelBase>

              <FabrkInput
                register={register}
                errors={errors}
                label="Slugs"
                name="slug"
                required={false}
              />
            </div>

            {slugSuggestions?.suggestions && (
              <div>
                <p>
                  Slug {slugSuggestions?.original} is taken. Here are some
                  available options:
                </p>

                <GenericSelect
                  values={
                    slugSuggestions?.suggestions?.map((slug) => {
                      return { label: slug, value: slug };
                    }) || []
                  }
                  setSelected={(value) => setValue("slug", value)}
                  selected={watch("slug")}
                />
              </div>
            )}
          </div>
          <div className="mb-4">
            <LabelBase>Type</LabelBase>
            <Controller
              render={({ field }) => {
                return (
                  <div>
                    <GenericSelect
                      values={AgentTypes}
                      setSelected={(value) => field.onChange(value)}
                      selected={field.value}
                    />
                  </div>
                );
              }}
              name="type"
              control={control}
            />
          </div>
          <div>
            <LabelBase>Explanation</LabelBase>
            <FabrkInput
              register={register}
              errors={errors}
              label="Explanation"
              name="goal"
              required={false}
            />
          </div>
          <div>
            <LabelBase>Context</LabelBase>
            <FabrkTextArea
              register={register}
              errors={errors}
              label="Instructions"
              name="prompt"
              required={false}
            />
          </div>
        </div>
        <div className="mt-6 flex justify-end gap-2">
          <Button
            size="sm"
            variant="outlined"
            color="gray"
            onClick={() => setIsEdit(false)}
          >
            Cancel
          </Button>
          <Button
            size="sm"
            color="green"
            fullWidth={false}
            type="submit"
            disabled={!isDirty || slugSuggestions?.suggestions !== null}
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
}

export default EditAgentName;
