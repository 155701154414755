import { ApiEndpoints } from "./apiEndpoints";
import { fetchData, setHeaderOptions } from "./apiHelpers";

export function getSession({ nango_id }: { nango_id?: string }): Promise<any> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.nango + "/token";

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify({ nango_id }),
  });

  return fetchData<any>(url, request);
}

export async function postSaveConnectionId({
  connectionId,
  agentToolId,
}: {
  connectionId: string;
  agentToolId?: string;
}): Promise<void> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.nango + "/save-connection-id";

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify({ connectionId, agentToolId }),
  });

  return fetchData<void>(url, request);
}
