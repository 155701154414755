import SubmitButton from "../../../shared/buttons/SubmitButton";
import { useVisibilityContext } from "../../hooks/VisibilityContext";
import { DeployVisibility } from "./DeployVisibility";
import { ProjectVisibility } from "./ProjectVisibility";
import { PublicVisibility } from "./PublicVisibility";

function AgentVisibilityList({ handleSubmit }: { handleSubmit?: () => void }) {
  const { visibilityValues, handleUpdateVisibility } = useVisibilityContext();

  return (
    <div className="flex flex-col gap-4">
      <div className="max-w-xs">
        <SubmitButton
          type="submit"
          onClick={() => {
            handleUpdateVisibility();
            if (handleSubmit) {
              handleSubmit();
            }
          }}
        >
          Save
        </SubmitButton>
      </div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
        <ProjectVisibility values={visibilityValues} />
        <PublicVisibility values={visibilityValues} />
        <DeployVisibility values={visibilityValues} />
      </div>
    </div>
  );
}

export default AgentVisibilityList;
