import { ApiEndpoints } from "./apiEndpoints";
import { fetchData, setHeaderOptions } from "./apiHelpers";
import { CreateScheduleRow, ScheduleRow } from "./schedule.type";

export function getSchedulesByAgent(agentId: string): Promise<ScheduleRow[]> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.schedule + "/agent/" + agentId;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData<ScheduleRow[]>(url, request);
}

export function createOrUpdateSchedulesByAgent({
  agentId,
  schedules,
}: {
  agentId: string;
  schedules: CreateScheduleRow[];
}): Promise<ScheduleRow[]> {
  const url =
    process.env.REACT_APP_API_URL + ApiEndpoints.schedule + "/agent/" + agentId;

  const request = setHeaderOptions({
    method: "POST",
    body: JSON.stringify({ schedules }),
  });

  return fetchData<ScheduleRow[]>(url, request);
}

export function enableSchedulesForAgent(
  agentId: string,
): Promise<ScheduleRow[]> {
  const url = process.env.REACT_APP_API_URL + ApiEndpoints.smsUsage;

  const request = setHeaderOptions({
    method: "GET",
  });

  return fetchData(url, request);
}
