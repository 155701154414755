import { AgentType } from "../../../api/agent.type";

export const agentTypeIcons = {
  [AgentType.personas]: "👤",
  [AgentType.businessIntelligence]: "📊",
  [AgentType.commerce]: "🛒",
  [AgentType.communication]: "💬",
  [AgentType.contentAndFiles]: "📁",
  [AgentType.humanResources]: "👥",
  [AgentType.itOperations]: "💻",
  [AgentType.lifestyleAndEntertainment]: "🎉",
  [AgentType.marketing]: "📈",
  [AgentType.productivity]: "🗂️",
  [AgentType.salesAndCRM]: "💼",
  [AgentType.support]: "🛠️",
  [AgentType.websiteAndAppBuilding]: "🌐",
  [AgentType.evaluation]: "📊",
};
