/* eslint-disable react-hooks/exhaustive-deps */
import {
  SpeedDial,
  SpeedDialContent,
  SpeedDialHandler,
} from "@material-tailwind/react";

export function SpeedDialForChat({
  children,
  handler,
}: {
  children: React.ReactNode;
  handler: React.ReactNode;
}) {
  return (
    <SpeedDial>
      <SpeedDialHandler>
        {/* <IconButton size="lg" color="gray" className="rounded-full">
          <PlusIcon className="h-5 w-5 transition-transform group-hover:rotate-45" />
        </IconButton> */}
        {handler}
      </SpeedDialHandler>
      <SpeedDialContent>
        {children}
        {/* <SpeedDialAction>
          <Typography color="gray">New Chat</Typography>
        </SpeedDialAction>
        <SpeedDialAction>
          <Typography color="gray">New Group</Typography>
        </SpeedDialAction>
        <SpeedDialAction>
          <Typography color="gray">Join Chat</Typography>
        </SpeedDialAction> */}
      </SpeedDialContent>
    </SpeedDial>
  );
}
