/* eslint-disable react-hooks/exhaustive-deps */
import clsx from "clsx";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuthenticateTool from "../auth/AuthenticateTool";
import { RequiresAuthBadge } from "../shared/chip/RequiresAuthBadge";
import { useToolContext } from "./hooks/ToolContext";

type ConnectionDetails = {
  agentName: string;
  agentToolId: string;
  type: string;
  id: string;
  status: string;
};

export default function ConnectionDetailsTable() {
  const { tool } = useToolContext();

  const [details, setDetails] = useState<ConnectionDetails[]>([]);

  useEffect(() => {
    if (tool && tool.agent_tool && tool.agent_tool.length > 0) {
      const detailsMap = tool?.agent_tool?.map((agentTool) => {
        return {
          agentName: agentTool.agent?.name as string,
          agentToolId: agentTool.id as string,
          type: tool?.connection_type as string,
          id: agentTool.connection_id as string,
          status: agentTool.connection_id ? "Connected" : "Not Connected",
        };
      });

      setDetails(detailsMap || []);
    }
  }, [tool]);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-skin-muted/40">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="w-1/4 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-skin-base/80 sm:pl-0"
                  >
                    Type
                  </th>
                  <th
                    scope="col"
                    className="w-1/4 px-3 py-3.5 text-left text-sm font-semibold text-skin-base/80"
                  >
                    Agent
                  </th>
                  <th
                    scope="col"
                    className="w-1/4 px-3 py-3.5 text-left text-sm font-semibold text-skin-base/80"
                  >
                    Connection ID
                  </th>
                  <th
                    scope="col"
                    className="w-1/4 px-3 py-3.5 text-left text-sm font-semibold text-skin-base/80"
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-skin-muted/20">
                {details.map((connection, index) => {
                  const textColor =
                    connection.status === "Connected"
                      ? "text-green-400"
                      : "text-red-400";
                  const foundAgentTool = tool?.agent_tool?.find(
                    (t) => t.id === connection.agentToolId,
                  );
                  return (
                    <tr key={index}>
                      <td className="flex w-1/4 flex-row items-center gap-2 whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-skin-base sm:pl-0">
                        {connection.type}
                      </td>
                      <td className="w-1/4 whitespace-nowrap px-3 py-4 text-sm text-skin-base/60">
                        {connection.agentName}
                      </td>
                      <td className="w-1/4 whitespace-nowrap px-3 py-4 text-sm text-skin-base/60">
                        {connection.id}
                      </td>
                      <td
                        className={clsx(
                          "w-1/4 whitespace-nowrap px-3 py-4 text-sm",
                          textColor,
                        )}
                      >
                        {connection.status === "Connected" ? (
                          "Connected"
                        ) : (
                          <RequiresAuthBadge />
                        )}
                      </td>
                      {connection.status === "Not Connected" &&
                        connection.type !== "credentials" && (
                          <td>
                            <AuthenticateTool
                              nangoId={tool?.nango_id}
                              agentToolId={connection?.agentToolId}
                            />
                          </td>
                        )}
                      {connection.type === "credentials" && (
                        <td className="w-1/4 whitespace-nowrap px-3 py-4 text-sm text-skin-base/60">
                          <Link
                            to={`/agent/${foundAgentTool?.agent_id}#credentials`}
                            className="text-skin-accent hover:underline"
                          >
                            View Credentials
                          </Link>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
