import { MenuItem } from "@headlessui/react";
import {
  BookOpenIcon,
  Cog6ToothIcon,
  Cog8ToothIcon,
  MoonIcon,
  SunIcon,
} from "@heroicons/react/24/outline";
import { useCompanyContext } from "../fabrk/hooks/CompanyContext";
import { useDarkModeContext } from "../fabrk/layout/hooks/useDarkMode";
import { FormDivider } from "../shared/dividers/FormDivider";
import DropdownMenu from "../shared/menu/DropdownMenu";

export default function SettingsMenu() {
  const { toggleDarkMode } = useDarkModeContext();

  const { company } = useCompanyContext();

  return (
    <DropdownMenu
      className="absolute -top-32 left-0 z-50 mt-2 w-44 origin-top-right overflow-visible rounded-md border-2 border-skin-hover bg-skin-fill shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
      MenuButtonChild={
        <div className="justify-left flex w-fit gap-4 rounded-md bg-skin-fill px-3 py-2 text-left text-sm font-semibold text-skin-base hover:bg-skin-hover">
          <Cog6ToothIcon
            aria-hidden="true"
            className="h-6 w-6 shrink-0 text-skin-base group-hover:bg-skin-accent"
          />
        </div>
      }
    >
      <div className="flex flex-col gap-1 p-1">
        <div>
          {company?.name && (
            <MenuItem>
              <div className="flex flex-row items-center gap-2 px-4 py-2 data-[focus]:bg-skin-hover data-[focus]:text-skin-base">
                <Cog8ToothIcon className="h-5 w-5 text-skin-base/80" />
                <a href={`/project`} className="text-sm text-skin-base/80">
                  Settings
                </a>
              </div>
            </MenuItem>
          )}
          <MenuItem>
            <div className="flex flex-row items-center gap-2 px-4 py-2 data-[focus]:bg-skin-hover data-[focus]:text-skin-base">
              <BookOpenIcon className="h-5 w-5 text-skin-base/80" />
              <a
                href="https://docs.fabrk.ai/get-started/overview"
                className="text-sm text-skin-base/80"
              >
                Docs
              </a>
            </div>
          </MenuItem>
        </div>
        <FormDivider />
        <div className="flex flex-row items-center justify-center gap-4 px-4">
          <button
            onClick={toggleDarkMode}
            className="items-center rounded-lg bg-skin-fill p-1 text-sm text-skin-base hover:bg-skin-hover"
          >
            <MoonIcon className="h-5 w-5 text-skin-base group-hover:bg-skin-hover" />
          </button>
          <button
            onClick={toggleDarkMode}
            className="items-center rounded-lg bg-skin-fill p-1 text-sm text-skin-base hover:bg-skin-hover"
          >
            <SunIcon
              aria-hidden="true"
              className="h-5 w-5 text-skin-base group-hover:bg-skin-hover"
            />
          </button>
        </div>
      </div>
    </DropdownMenu>
  );
}
