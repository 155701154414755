import { useState } from "react";
import { useCompanyContext } from "../fabrk/hooks/CompanyContext";
import IconButton from "../shared/buttons/IconButton";
import SubmitButton from "../shared/buttons/SubmitButton";
import GenericDialog from "../shared/dialog/GenericDialog";
import { InputBase } from "../shared/forms/input/InputBase";
import { PlusIcon } from "../shared/icons/icons";
import { HeadingBase, LabelBase } from "../shared/typography/TypographyBase";

function TeamContainer() {
  const { company, inviteUser } = useCompanyContext();
  const [open, setMenuOpen] = useState(false);

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  function handleSubmit() {
    inviteUser({ email, firstName, lastName });
    setMenuOpen(false);
  }

  return (
    <div className="flex min-h-64 max-w-lg flex-col gap-8 rounded-lg border-2 p-2">
      <div className="flex flex-row justify-between border-b-2 py-2">
        <HeadingBase>Team Members</HeadingBase>
        <IconButton
          onClick={(e?: React.MouseEvent<HTMLButtonElement>) => {
            e?.preventDefault();
            setMenuOpen(true);
          }}
        >
          <PlusIcon className="h-4 w-4" />
        </IconButton>
      </div>
      <div>
        {company?.users_companies &&
          company?.users_companies.map((uc) => {
            return (
              <div
                key={uc.id}
                className="flex flex-row items-baseline gap-4 py-2"
              >
                <div>{uc?.users?.email}</div>
                <div className="text-sm">
                  {!uc?.users?.welcome_email_sent && "Pending Invite"}
                </div>
              </div>
            );
          })}
      </div>

      <GenericDialog open={open} setOpen={setMenuOpen} title="Add funds">
        <div className="flex max-w-lg flex-col gap-4">
          <div>
            Invite team members to your project. They will receive an invite
            email.
          </div>

          <div className="flex flex-row gap-4">
            <div>
              <LabelBase>First Name</LabelBase>
              <InputBase value={firstName} setValue={setFirstName} />
            </div>
            <div>
              <LabelBase>Last Name</LabelBase>
              <InputBase value={lastName} setValue={setLastName} />
            </div>
          </div>

          <div>
            <LabelBase>Email</LabelBase>
            <InputBase value={email} setValue={setEmail} />
          </div>

          <SubmitButton onClick={handleSubmit}>Add Team Member</SubmitButton>
        </div>
      </GenericDialog>
    </div>
  );
}

export default TeamContainer;
