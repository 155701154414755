import { PlusIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "@material-tailwind/react";
import { useAgentContext } from "../../agent/hooks/AgentContext";

function NewAgentButton() {
  const { handleCreateNewAgentWithDefaults } = useAgentContext();
  return (
    <Tooltip
      placement="bottom"
      content="Create a new agent"
      className="bg-skin-hover text-skin-base"
    >
      <button onClick={() => handleCreateNewAgentWithDefaults()}>
        <PlusIcon className="h-8 w-8 rounded-md bg-skin-hover p-1 text-skin-base hover:bg-skin-muted hover:text-skin-inverse" />
      </button>
    </Tooltip>
  );
}

export default NewAgentButton;
