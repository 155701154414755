/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import {
  Control,
  FieldArrayWithId,
  FieldErrors,
  useFieldArray,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  useForm,
  UseFormReset,
} from "react-hook-form";
import { UseMutateFunction, useMutation } from "react-query";
import {
  createOrUpdateSchedulesByAgent,
  enableSchedulesForAgent,
  getSchedulesByAgent,
} from "../../api/schedule.api";
import { CreateScheduleRow, ScheduleRow } from "../../api/schedule.type";
import { isValidCron } from "../../utils/parseTimeStamp";
import { useAgentContext } from "../agent/hooks/AgentContext";
import { useAlertContext } from "../shared/alert/alertContext";

export type ScheduleForm = {
  id: string;
  schedules: CreateScheduleRow[];
};

export const ScheduleContextWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { activeAgent } = useAgentContext();
  const { setAlertProps, setGlobalLoading } = useAlertContext();

  const [isEdit, setIsEdit] = useState(true);

  const { mutate: fetchSchedules, data: schedules } =
    useMutation(getSchedulesByAgent);

  useEffect(() => {
    if (activeAgent) {
      fetchSchedules(activeAgent.id);
    }
  }, [activeAgent]);

  const { mutate: createOrUpdateSchedule, isLoading } = useMutation(
    createOrUpdateSchedulesByAgent,
    {
      onSuccess() {
        fetchSchedules(activeAgent?.id as string);
        setAlertProps({
          message: "Schedules added",
          color: "green",
        });
      },
      onError(error: Error) {
        setAlertProps({
          message: error.message,
          color: "red",
        });
      },
    },
  );

  const { mutate: enable } = useMutation(enableSchedulesForAgent);

  const defaultValues = schedules?.map((item) => {
    return {
      id: item.id as string,
      created_at: item.created_at as string,
      name: item.sms_content.name as string | "",
      content: item.sms_content?.content as string | "",
      jobId: item.job_id as string | "",
      cronSchedule: item.cron_schedule as string | "",
    };
  });

  const {
    control,
    reset,
    getValues,
    formState: { isValid, errors },
    setError,
  } = useForm({
    defaultValues: {
      id: "1",
      schedules: defaultValues,
    } as ScheduleForm,
  });

  const { remove, fields, append } = useFieldArray({
    control,
    name: "schedules",
  });

  function handleReset() {
    reset({
      schedules: defaultValues,
    });
    setIsEdit(false);
  }

  async function handleCreateSchedules() {
    const { schedules } = getValues();

    if (schedules)
      for (let i = 0; i < schedules.length; i++) {
        const schedule = schedules[i];

        const cronValid = isValidCron(schedule.cronSchedule);

        if (!cronValid) {
          setError(`schedules.${i}.cronSchedule`, {
            type: "manual",
            message: "Must be * * * * * format",
          });
          return;
        }

        if (!schedule.name || !schedule.content || !schedule.cronSchedule) {
          setError(`schedules.${i}.name`, {
            type: "manual",
            message: "Name is required",
          });
          setError(`schedules.${i}.content`, {
            type: "manual",
            message: "Content is required",
          });
          setError(`schedules.${i}.cronSchedule`, {
            type: "manual",
            message: "Cron Schedule is required",
          });
          return;
        }
      }

    if (isValid) {
      createOrUpdateSchedule({
        agentId: activeAgent?.id as string,
        schedules,
      });
    }

    reset({
      schedules: defaultValues,
    });
  }

  useEffect(() => {
    if (schedules) {
      const updatedValues = {
        id: "1",
        schedules: defaultValues,
      };
      reset(updatedValues);
      setIsEdit(false);
    }
  }, [activeAgent, schedules]);

  useEffect(() => {
    setGlobalLoading(isLoading);
  }, [isLoading]);

  const value = {
    schedules,
    enable,
    remove,
    control,
    isEdit,
    setIsEdit,
    fields,
    append,
    reset,
    handleCreateSchedules,
    handleReset,
    errors,
  };

  return (
    <ScheduleContext.Provider value={value}>
      {children}
    </ScheduleContext.Provider>
  );
};

export const ScheduleContext = createContext({
  schedules: [] as ScheduleRow[] | undefined,
  enable: {} as UseMutateFunction<ScheduleRow[], unknown, string, unknown>,
  remove: {} as UseFieldArrayRemove,
  control: {} as Control<
    {
      id: string;
      schedules: CreateScheduleRow[];
    },
    any
  >,
  isEdit: false,
  setIsEdit: {} as React.Dispatch<React.SetStateAction<boolean>>,
  fields: [] as FieldArrayWithId<ScheduleForm, "schedules", "id">[],
  append: {} as UseFieldArrayAppend<ScheduleForm, "schedules">,
  reset: {} as UseFormReset<ScheduleForm>,
  handleCreateSchedules: {} as () => void,
  handleReset: {} as () => void,
  errors: {} as FieldErrors<ScheduleForm>,
});

export const useScheduleContext = () => useContext(ScheduleContext);
